import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Select,
  InputNumber,
  Checkbox,
  Upload,
  Image,
  message,
  Tooltip,
} from "antd";
import "antd/dist/antd.css";
import Service from "./../../../extra/getAPI";
import Helper from "./../../../extra/helper";
import IXoa from "./../../../assets/images/salesManager/trash-02.png";
import IXoa2 from "./../../../assets/images/salesManager/ixoatrang.svg";
import Itaogoi from "./../../../assets/images/salesManager/itaogoi2.png";
import Ithem2 from "./../../../assets/images/salesManager/them2.png";
import Ifile from "./../../../assets/images/salesManager/ifile.png";
import IDeleteFile from "./../../../assets/images/salesManager/ixoafile.png";
import Iupload from "./../../../assets/images/salesManager/iUpload.png";
import Imo from "./../../../assets/images/salesManager/imo.png";
import ISub from "./../../../assets/images/salesManager/subicon.png";
import DeleteBaner from "./../../../assets/images/salesManager/deleteBaner.png";
import Nhanban from "./../../../assets/images/salesManager/nhanban.png";
import TextEdit from "./../../../extra/ckEditor4";
import MDAddWaitingTime from "./../../../components/modal/san-pham/suc-khoe-vang/mdAddEditWaitingTime";
import MDOutstandingBenefits from "./../../../components/modal/san-pham/suc-khoe-vang/mdAddEditBenefits";
import MDConfirmCreate from "./../../../components/modal/san-pham/suc-khoe-vang/mdConfirmCreateProduct";
import MDCensorship from "./../../../components/modal/san-pham/suc-khoe-vang/mdCensorship";
import MDAddLinkDocument from "./../../../components/modal/san-pham/suc-khoe-vang/mdAddLinkDocument";
import { EditOutlined, MenuOutlined } from "@ant-design/icons";
import { GlobalState } from "./index";
import { AuthContext } from "./../../../context/AuthProvider";

const TaoGoiSanPham = () => {
  const {
    question,
    manipulateArrayQuestion,
    additionalTerms,
    manipulateArrayAdditionalTerms,
    handleDataChangeMainBenefits,
    mainBenefits,
    waitingTimes,
    handleModifyWaitingTime,
    uploadG2,
    documentaion,
    setdocumentaion,
    Back,
    product,
    dataUpdate,
    setWaitingTimes,
    setQuestion,
    setMainBenefits,
    setAdditionalTerms,
    getProducts,
    loadingIndex,
    arrOutstandingBenefits,
    handleModifyBenefits,
    setArrOutstandingBenefits,
    actionDetail,
  } = useContext(GlobalState);
  const { age, getAge, checkAccountGC, setIndexPage } = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { Option } = Select;
  const { Dragger } = Upload;
  const [openMainBenefits, setopenMainBenefits] = useState(0);
  const [openAddWaitingTime, setopenAddWaitingTime] = useState(false);
  const [openCensorship, setopenCensorship] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAddLinkDocument, setOpenAddLinkDocument] = useState(false);
  const [openOutstandingBenefits, setopenOutstandingBenefits] = useState(false);
  const [formData, setFormData] = useState({});
  const [keyNameFile, setKeyNameFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataCreate, setDataCreate] = useState({});
  const [newProduct, setNewProduct] = useState([]);
  const [count, setCount] = useState([]);
  const targetQLChinh = useRef(null);
  const targetQLBoSung = useRef(null);
  // Lấy tên sản phẩm
  const [childProduct, setChildProduct] = useState("");
  const [disable, setDisable] = useState();
  // Xem sản phẩm thì sẽ disable tat ca cac trường
  const view = actionDetail === "xem" ? true : false;
  // Dat dung để update cho thời gian chờ và quyền lợi nổi  bật
  const [
    dataUpdateTimeAndAdditionalTerms,
    setDataUpdateTimeAndAdditionalTerms,
  ] = useState();
  // Ẩn hiện các trường dữ liệu
  const [display, setDisplay] = useState("show"); // show là hiện or hide là ẩn
  const draggedItem = useRef(null);
  const draggedGroup = useRef(null);
  const importInputRef = useRef();

  const category = [
    {
      id: 1,
      value: "noi_tru",
      title: "Nội trú",
    },
    {
      id: 2,
      value: "ngoai_tru",
      title: "Ngoại trú",
    },
    {
      id: 3,
      value: "thai_san",
      title: "Thai sản",
    },
    {
      id: 11,
      value: "tu_vong_thuong_tat_benh",
      title: "Tử vong, thương tật vĩnh viễn do bệnh",
    },
    {
      id: 4,
      value: "tu_vong_thuong_tat_tai_nan",
      title: "Tử vong, thương tật vĩnh viễn do tai nạn",
    },
    {
      id: 5,
      value: "tu_vong_thuong_tat_benh_tat_thai_san",
      title: "Tử vong, thương tật vĩnh viễn do bệnh tật, thai sản",
    },
    {
      id: 6,
      value: "rang",
      title: "Răng",
    },
    {
      id: 7,
      value: "tro_cap",
      title: "Trợ cấp",
    },
    {
      id: 8,
      value: "bao_hiem_sinh_mạng",
      title: "Bảo hiểm sinh mạng",
    },
    {
      id: 9,
      value: "bao_hiem_giao_duc",
      title: "Bảo hiểm giáo dục",
    },
    {
      id: 10,
      value: "bao_hiem_ung_thu",
      title: "Bảo hiểm ung thư",
    },
  ];

  const regions = [
    {
      id: 1,
      code: "viet_nam",
      name: "Việt Nam",
    },
    {
      id: 2,
      code: "dong_nam_a",
      name: "Đông Nam Á",
    },
    {
      id: 3,
      code: "chau_a",
      name: "Châu Á",
    },
    {
      id: 4,
      code: "toan_cau",
      name: "Toàn cầu",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setIndexPage(0);
    getAge();
    getProducts();
    if (dataUpdate?.uid) {
      countOrder(dataUpdate?.uid);
    }
  }, []);

  useEffect(() => {
    // Không cho sửa một số thông tin khi cập nhật
    if (checkAccountGC || actionDetail === "xem") {
      setDisable(true);
    } else {
      setDisable(
        (+dataUpdate.status === 2 ||
          +dataUpdate.status === 3 ||
          +dataUpdate.status === 4 ||
          +dataUpdate.status === 5) &&
          count > 0
          ? true
          : false
      );
    }
  }, [count]);

  // Kiểm tra xem có đơn hành không
  const countOrder = (uid) => {
    Service.apicall(
      "GET",
      `/api/v1/products-hub/health/portal/package/order-count?packageId=${uid}`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCount(res?.data?.result);
        } else {
          setCount(0);
        }
      })
      .catch((err) => {
        setCount(0);
      });
  };

  // Check nếu tạo sản phẩm mới thì ẩn hiện một số trường thông tin
  useEffect(() => {
    if (formData?.productByProviderId) {
      if (formData?.productByProviderId?.includes("#") || dataUpdate?.uid) {
        setDisplay("show");
      } else {
        setDisplay("hide");
      }
    }
  }, [formData?.productByProviderId]);

  // set data khi cập nhật
  useEffect(() => {
    if (dataUpdate?.uid) {
      form.setFieldsValue({
        // ...dataUpdate?.prominent_benefits,
        excluded_region: dataUpdate?.excluded_region,
        regions: dataUpdate?.regions?.code,
        title: dataUpdate?.title,
        productByProviderId:
          +dataUpdate?.productByProviderId +
          "-" +
          product.find((item) => item.id === +dataUpdate?.productByProviderId)
            ?.product_title,
        hospital_guarantee: dataUpdate?.hospital_guarantee
          ? Object?.keys(dataUpdate?.hospital_guarantee)?.filter(
            (key) => dataUpdate?.hospital_guarantee?.[key]
          )
          : "",
        policyNumber: dataUpdate?.rule_insurance?.policyNumber,
        compensation_unit: dataUpdate?.compensation_unit?.[0]?.name,
        definition: dataUpdate?.definition,
        excluded_terms: dataUpdate?.excluded_terms,
        insurer_product_code: dataUpdate?.insurer_product_code,
        insurer_package_code: dataUpdate?.insurer_package_code
      });
      setWaitingTimes(dataUpdate?.waiting_time);
      setQuestion(dataUpdate?.assessment_question);
      // setMainBenefits(dataUpdate?.benefits);
      setAdditionalTerms(dataUpdate?.extra_terms);
      setFormData({
        definition: dataUpdate?.definition,
        reason_rejection: dataUpdate?.reason_rejection,
      });
      // Quyền lợi nổi bật
      setArrOutstandingBenefits(
        dataUpdate?.prominent_benefits.length > 0
          ? dataUpdate?.prominent_benefits
          : arrOutstandingBenefits
      );
      setdocumentaion({
        excludedHospitals: dataUpdate?.documentaion?.excludedHospitals?.[0],
        hospitalsGuarantee: dataUpdate?.documentaion?.hospitalsGuarantee?.[0],
        brochure: dataUpdate?.documentaion?.brochure?.[0],
        other: dataUpdate?.documentaion?.other?.[0]?.url,
        bannerUrl: dataUpdate?.images?.bannerUrl?.[0],
        rule_insurance: dataUpdate?.rule_insurance?.url,
      });
      setChildProduct(
        product.find((item) => item.id === +dataUpdate?.productByProviderId)
          ?.product_title
      );
    }
  }, [dataUpdate]);

  useEffect(() => {
    const dataAdditionalTerms = {};
    form.setFieldsValue({
      extra_terms: additionalTerms
        ?.filter((term) => term.checked)
        ?.map((term) => term.id),
    });
    additionalTerms?.forEach((item, index) => {
      dataAdditionalTerms[`dieukhoankhac${index}`] = item.content;
    });
    form.setFieldsValue(dataAdditionalTerms);
  }, [additionalTerms]);

  useEffect(() => {
    // console.log('Cau hoi tham dinh', question)
    const dataQuestion = {};
    question?.forEach((item, index) => {
      dataQuestion[`assessment_question${index}`] = item.content;
    });
    form.setFieldsValue(dataQuestion);
  }, [question]);

  useEffect(() => {
    // console.log("thoigiancho", waitingTimes);
    const dataWaitingTimes = {};
    waitingTimes?.forEach((item, index) => {
      dataWaitingTimes[item.code] = item.days;
    });
    form.setFieldsValue(dataWaitingTimes);
  }, [waitingTimes]);

  useEffect(() => {
    // console.log("quyenloinoibat", arrOutstandingBenefits);
    const dataOutstandingBenefits = {};
    arrOutstandingBenefits.forEach((item, index) => {
      dataOutstandingBenefits[item.code] = item.value;
    });
    form.setFieldsValue(dataOutstandingBenefits);
  }, [arrOutstandingBenefits]);

  useEffect(() => {
    // console.log("bangquyenloi", mainBenefits);
    const formattedData = {};
    mainBenefits.forEach((item, index) => {
      formattedData[`category${index}`] = item.category;
      formattedData[`title${index}`] = item.title;
      formattedData[`category_fees${index}`] = item.category_fees;
      formattedData[`from_age${index}`] =
        +item?.details[0]?.under_1_age === 364
          ? item?.details[0]?.under_1_age
          : item?.details[0]?.from_age;
      formattedData[`to_age${index}`] =
        item?.details[0]?.to_age === 0 ? 364 : item?.details[0]?.to_age;
      formattedData[`total_benefits${index}`] = +item.total_benefits;
      formattedData[`description${index}`] = item.description;
      item.details.forEach((ageItem, ageIndex) => {
        formattedData[`gender${index}${ageIndex}`] = ageItem.gender;
        formattedData[`fees${index}${ageIndex}`] = ageItem.fees;
        formattedData[`from_age${index}${ageIndex}`] =
          ageItem.under_1_age === 364 ? ageItem.under_1_age : ageItem.from_age;
        formattedData[`to_age${index}${ageIndex}`] =
          ageItem.to_age === 0 ? 364 : ageItem.to_age;
        formattedData[`total_benefits_from${index}${ageIndex}`] =
          ageItem.total_benefits_from;
        formattedData[`total_benefits_to${index}${ageIndex}`] =
          ageItem.total_benefits_to;
        formattedData[`rate${index}${ageIndex}`] = ageItem.rate;
      });
    });
    form.setFieldsValue(formattedData);
  }, [mainBenefits]);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        let data = {
          productByProviderId: values?.productName
            ? null
            : +values?.productByProviderId.split("-")[0],
          productName: values?.productName ? values?.productName : null,
          insurer_package_code:values?.insurer_package_code,
          title: values?.title,
          regions: {
            code: values?.regions,
            name: regions.find((item) => item.code === values?.regions)?.name,
          },
          excluded_region: values?.excluded_region,
          hospital_guarantee: {
            inpatient: values?.hospital_guarantee?.includes("inpatient")
              ? true
              : false,
            outpatient: values?.hospital_guarantee?.includes("outpatient")
              ? true
              : false,
            tooth: values?.hospital_guarantee?.includes("tooth") ? true : false,
            meternity: values?.hospital_guarantee?.includes("meternity")
              ? true
              : false,
            accident: values?.hospital_guarantee?.includes("accident")
              ? true
              : false,
          },
          benefits: val?.mainBenefits,
          prominent_benefits: arrOutstandingBenefits,
          // prominent_benefits: {
          //   tu_vong_thuong_tat_tai_nan: values?.tu_vong_thuong_tat_tai_nan,
          //   tu_vong_thuong_tat_benh_thai_san:
          //     values?.tu_vong_thuong_tat_benh_thai_san,
          //   noi_tru_tren_nam: values?.noi_tru_tren_nam,
          //   vien_phi_tren_ngay: values?.vien_phi_tren_ngay,
          //   ngoai_tru_tren_nam: values?.ngoai_tru_tren_nam,
          //   gioi_han_tren_lan_kham: values?.gioi_han_tren_lan_kham,
          //   khac: values?.khac,
          // },
          status: val?.status,
        };
        if (display === "show") {
          data.waiting_time = val?.waitingTimes;
          data.insurer_product_code = values?.insurer_product_code;
          data.documentaion = {
            excludedHospitals: [val?.documentaion?.excludedHospitals],
            hospitalsGuarantee: [val?.documentaion?.hospitalsGuarantee],
            brochure: [val?.documentaion?.brochure],
            other: [
              {
                url: val?.documentaion?.other,
              },
            ],
          };
          data.extra_terms = val?.additionalTerms;
          data.rule_insurance = {
            url: val?.documentaion?.rule_insurance,
            policyNumber: values?.policyNumber,
          };
          data.compensation_unit = [
            {
              id: 1,
              name: values?.compensation_unit,
            },
          ];
          data.definition = values?.definition;
          data.images = { bannerUrl: [val?.documentaion?.bannerUrl] };
          data.assessment_question = val?.question;
          data.excluded_terms = values?.excluded_terms;
        }
        if (!dataUpdate?.uid) {
          if (val?.status === 4) {
            setOpenConfirm(true);
            setDataCreate(data);
          } else {
            Create(data);
          }
        } else {
          if (val?.status === 4) {
            setOpenConfirm(true);
            setDataCreate(data);
          } else {
            Update(data, dataUpdate?.uid);
          }
        }
      })
      .catch((err) => {
        console.error("err:", err);
        message.error(
          "Vui lòng kiểm tra và bổ sung đầy đủ các trường thông tin!"
        );
        window.scrollTo(0, 0);
      });
  };

  const Create = (data) => {
    setLoading(true);
    Service.apicall("POST", `/api/v1/products-hub/health/portal/package`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          setLoading(false);
          setOpenConfirm(false);
          message.success("Thêm gói sản phẩm thành công!");
          Back(1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const Update = (data, uid) => {
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/package/${uid}`,
      data
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          setLoading(false);
          setOpenConfirm(false);
          message.success("Cập nhật thành công!");
          Back(1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handCancel = () => {
    setopenAddWaitingTime(false);
    setOpenConfirm(false);
    setopenCensorship(false);
    setopenOutstandingBenefits(false);
    setDataUpdateTimeAndAdditionalTerms();
    setOpenAddLinkDocument(false)
  };

  const handleImportFile = (files) => {
    message.warning("Tài liệu đang được xử lý. Vui lòng chờ trong giây lát.");
    if (files?.size > 10485760) {
      message.error("Dung lượng file quá lớn. Vui lòng kiểm tra lại!");
      return;
    }
    uploadG2(
      files,
      keyNameFile || "bannerUrl",
      "bh_suc_khoe",
      Helper.generateCodeInput(childProduct)
    );
  };

  const onChangForm = ({ data, name }) => {
    //definition,productByProviderId
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  const renderChecked = (arr, value) => {
    for (let index = 0; index < arr?.length; index++) {
      if (arr[index] === value) {
        return true;
      }
    }
  };

  // Chuyển trạng thái sang hoạt động tất cả các gói
  const Approve = (uid) => {
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/package/${uid}/approve`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Kích hoạt thành công!");
          Back(1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  //Từ chối duyệt sản phẩm
  const Reject = (content) => {
    setLoading(true);
    const data = {
      reason: content,
    };
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/package/${dataUpdate?.uid}/reject`,
      data
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Từ chối thành công!");
          Back(1);
          handCancel();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Sự kiện kéo thả quyền lợi
  const onDragStart = (e, groupIndex, itemIndex = null) => {
    if (view) {
      return;
    }
    if (itemIndex !== null) {
      draggedItem.current = {
        groupIndex,
        itemIndex,
        item: mainBenefits[groupIndex].details[itemIndex],
      };
    } else {
      draggedGroup.current = { groupIndex, group: mainBenefits[groupIndex] };
    }
    e.dataTransfer.effectAllowed = "move";
  };

  const onDragOver = (e, groupIndex, itemIndex = null) => {
    if (view) {
      return;
    }
    e.preventDefault();
  };

  const onDrop = (e, dropGroupIndex, dropItemIndex = null) => {
    if (view) {
      return;
    }
    e.preventDefault();
    if (draggedItem.current) {
      const {
        groupIndex: draggedGroupIndex,
        itemIndex: draggedItemIndex,
        item: draggedItemData,
      } = draggedItem.current;
      let updatedBenefits = mainBenefits.map((group, gIdx) => {
        if (gIdx === draggedGroupIndex) {
          let newItems = group.details.filter(
            (_, iIdx) => iIdx !== draggedItemIndex
          );
          return { ...group, details: newItems };
        }
        return group;
      });

      updatedBenefits = updatedBenefits.map((group, gIdx) => {
        if (gIdx === dropGroupIndex) {
          let newItems = [...group.details];
          if (dropItemIndex !== null) {
            newItems.splice(dropItemIndex, 0, draggedItemData);
          } else {
            newItems.push(draggedItemData);
          }
          return { ...group, details: newItems };
        }
        return group;
      });

      setMainBenefits(updatedBenefits);
      draggedItem.current = null;
    } else if (draggedGroup.current) {
      const { groupIndex: draggedGroupIndex, group: draggedGroupData } =
        draggedGroup.current;
      let updatedBenefits = mainBenefits.filter(
        (_, gIdx) => gIdx !== draggedGroupIndex
      );
      updatedBenefits.splice(dropGroupIndex, 0, draggedGroupData);

      setMainBenefits(updatedBenefits);
      draggedGroup.current = null;
    }
  };
  // Kết thúc sự kiện kéo thả quyền lợi

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // checkAdditionalBenefitsAge();
  }, []);

  // Kiểm tra tuổi quyền lợi bổ sung nằm trong quyền lợi chính
  const checkAdditionalBenefitsAge = (data) => {
    const primaryDetails = mainBenefits
      .filter(item => item.kind === "primary")
      .reduce((acc, item) => {
        const detailsWithTitles = item.details.map(detail => ({ ...detail, title: item.title }));
        return acc.concat(detailsWithTitles);
      }, []);

    const additionalBenefits = mainBenefits
      .filter(item => item.kind === "additional")
      .reduce((acc, item) => {
        const detailsWithTitles = item.details.map(detail => ({ ...detail, title: item.title }));
        return acc.concat(detailsWithTitles);
      }, []);

    let allValid = true;

    if (primaryDetails.length > 0 && additionalBenefits.length > 0) {
      additionalBenefits.forEach((additional, index) => {
        let fromAgeValid = false;
        let toAgeValid = false;

        // Check from_age validity
        for (const primary of primaryDetails) {
          if (additional.from_age >= primary.from_age && additional.from_age <= primary.to_age) {
            fromAgeValid = true;
            break;
          }
        }

        if (fromAgeValid) {
          // Check to_age validity
          for (const primary of primaryDetails) {
            if (additional.to_age >= primary.from_age && additional.to_age <= primary.to_age) {
              toAgeValid = true;
              break;
            }
          }
        }

        if (!fromAgeValid || !toAgeValid) {
          message.error(`${additional.title} có tuổi từ ${additional.from_age} đến ${additional.to_age} không nằm trong khoảng tuổi của quyền lợi chính!`);
          allValid = false;
        }
      });

      if (allValid) {
        // console.log('All additional benefits are in the range of primary details');
        onFinish(data);
      }
    } else {
      onFinish(data);
    }
  }

  return (
    <>
      {
        openAddLinkDocument &&
        <MDAddLinkDocument keyNameFile={keyNameFile} open={true} handleCancel={handCancel} />
      }
      {openAddWaitingTime && (
        <MDAddWaitingTime
          data={dataUpdateTimeAndAdditionalTerms}
          open={true}
          handleCancel={handCancel}
        />
      )}
      {openOutstandingBenefits && (
        <MDOutstandingBenefits
          data={dataUpdateTimeAndAdditionalTerms}
          open={true}
          handleCancel={handCancel}
        />
      )}
      {openCensorship && (
        <MDCensorship
          loading={loading}
          reject={Reject}
          open={true}
          handleCancel={handCancel}
        />
      )}
      {openConfirm && (
        <MDConfirmCreate
          create={() =>
            dataUpdate?.uid
              ? Update(dataCreate, dataUpdate?.uid)
              : Create(dataCreate)
          }
          open={true}
          handleCancel={handCancel}
          loading={loading}
        />
      )}
      <Form
        layout={"vertical"}
        ref={formRef}
        scrollToFirstError
        form={form}
        onFinish={onFinish}
      >
        <div style={{ marginBottom: 10, fontWeight: 600, fontSize: 20 }}>
          Thông tin gói sản phẩm
        </div>
        <Row gutter={[15, 20]}>
          <Col xl={18} xs={24}>
            <Row style={{ overflow: "hidden" }}>
              {/* Thông tin căn bản */}
              <div
                style={{
                  background: "#ffffff",
                  width: "100%",
                  padding: 20,
                  borderRadius: 2,
                  color: "#4B4B4B",
                }}
              >
                <Row gutter={[10]}>
                  <Col xs={24}>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginBottom: 10,
                      }}
                    >
                      Thông tin cơ bản
                    </div>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      className="username"
                      name="productByProviderId"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Nhập tên sản phẩm
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <Select
                        disabled={dataUpdate?.uid ? true : false}
                        showSearch={true}
                        optionFilterProp="children"
                        onSearch={(e) => {
                          if (e.length > 0) {
                            setNewProduct([{ title: e }]);
                          }
                        }}
                        className="custom-select2"
                        placeholder={<span>Tên sản phẩm</span>}
                        onChange={(e) => {
                          onChangForm({ data: e, name: "productByProviderId" });
                          if (e.includes("#")) {
                            form.setFieldsValue({
                              productName: newProduct[0].title,
                            });
                            setChildProduct(newProduct[0].title);
                          } else {
                            form.setFieldsValue({ productName: null });
                            setChildProduct(
                              product.find((item) => item.id === e)
                                ?.product_title
                            );
                          }
                        }}
                      >
                        {newProduct?.map(
                          (item, index) =>
                            product.filter(
                              (o) => o.product_title === newProduct[0].title
                            ).length === 0 && (
                              <Option
                                key={index}
                                value={
                                  Helper.generateCodeInput(item?.title) + "#"
                                }
                              >
                                {item?.title}
                              </Option>
                            )
                        )}
                        {product?.map((item, index) => (
                          <Option
                            key={index}
                            value={item?.id + "-" + item.product_title}
                          >
                            {item?.product_title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {/* Tên sản phẩm mới cần thêm vào */}
                    <Form.Item
                      style={{ marginBottom: 10, display: "none" }}
                      name="productName"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Nhập tên gói" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Nhập tên gói</span>
                      }
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập thông tin.",
                        },
                      ]}
                    >
                      <Input allowClear={true} disabled={disable} placeholder="Nhập tên gói" />
                    </Form.Item>
                  </Col>
                  {
                    display === "show" &&
                    <Col xs={24} xl={12}>
                      <Form.Item
                        className="username"
                        style={{ marginBottom: 10 }}
                        label={
                          <span style={{ fontWeight: 400 }}>Mã sản phẩm: <span style={{color:'red'}}>Liên hệ với IT nhà bảo hiểm để lấy thông tin</span></span>
                        }
                        name="insurer_product_code"
                        rules={[
                          {
                            required: false,
                            message: "Vui lòng nhập thông tin.",
                          },
                        ]}
                      >
                        <Input allowClear={true} disabled={disable} placeholder="Mã sản phẩm" />
                      </Form.Item>
                    </Col>
                  }
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Mã gói sản phẩm: <span style={{color:'red'}}>Liên hệ với IT nhà bảo hiểm để lấy thông tin</span></span>
                      }
                      name="insurer_package_code"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng nhập thông tin.",
                        },
                      ]}
                    >
                      <Input allowClear={true} disabled={disable} placeholder="Mã gói sản phẩm" />
                    </Form.Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      className="username"
                      name="regions"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Phạm vi bảo hiểm
                        </span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <Select
                        allowClear={true}
                        className="custom-select2"
                        placeholder={<span>Chọn phạm vi bảo hiểm</span>}
                        disabled={view}
                      >
                        {regions.map((item) => (
                          <Option key={item.id} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Khu vực loại trừ
                        </span>
                      }
                      name="excluded_region"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng nhập thông tin.",
                        },
                      ]}
                    >
                      <Input
                        disabled={view}
                        placeholder="Nhập khu vực loại trừ"
                        allowClear={true}
                      // style={{padding:'0px 10px', borderRadius: 7}}
                      />
                    </Form.Item>
                  </Col>
                  {/* {display === "show" && ( */}
                  <Col xs={24} xl={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Bảo lãnh viện phí
                        </span>
                      }
                      name="hospital_guarantee"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <Checkbox.Group disabled={view} style={{ width: "100%" }}>
                        <Row gutter={[20]}>
                          <Col xl={6} xs={12}>
                            <Checkbox value={"inpatient"}>Nội trú</Checkbox>
                          </Col>
                          <Col xl={6} xs={12}>
                            <Checkbox value={"outpatient"}>Ngoại trú</Checkbox>
                          </Col>
                          <Col xl={6} xs={12}>
                            <Checkbox value={"tooth"}>Răng</Checkbox>
                          </Col>
                          <Col xl={6} xs={12}>
                            <Checkbox value={"meternity"}>Thai sản</Checkbox>
                          </Col>
                          <Col xl={6} xs={12}>
                            <Checkbox value={"accident"}>Tai nạn</Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                  {/* )} */}
                  {display === "show" && (
                    <>
                      <Col xs={24} xl={24}>
                        <Form.Item
                          className="username"
                          style={{ marginBottom: 10 }}
                          label={
                            <span style={{ fontWeight: 400 }}>
                              Nhập số hiệu quy tắc BH áp dụng
                            </span>
                          }
                          name="policyNumber"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập thông tin.",
                            },
                          ]}
                        >
                          <Input
                            disabled={view}
                            allowClear
                            placeholder="Nhập số hiệu quy tắc BH áp dụng"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} xl={24}>
                        <Form.Item
                          className="username"
                          style={{ marginBottom: 10 }}
                          label={
                            <span style={{ fontWeight: 400 }}>
                              Đơn vị xử lý bồi thường
                            </span>
                          }
                          name="compensation_unit"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập thông tin.",
                            },
                          ]}
                        >
                          <Input
                            allowClear
                            disabled={view}
                            placeholder="Nhập đơn vị xứ lý bồi thường"
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
              {/* Câu hỏi thẩm định */}
              {display === "show" && (
                <div
                  style={{
                    background: "#ffffff",
                    width: "100%",
                    padding: 20,
                    borderRadius: 2,
                    color: "#4B4B4B",
                    marginTop: 20,
                  }}
                >
                  <Row gutter={[60, 0]}>
                    <Col xs={24}>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                        }}
                      >
                        Câu hỏi thẩm định
                      </div>
                      <div
                        style={{
                          fontSize: 13,
                          fontStyle: "italic",
                          marginBottom: 10,
                        }}
                      >
                        Tích chọn “cho phép thẩm định" được hiểu rằng nếu Khách
                        hàng có một trong những loại bệnh đó sẽ được NBH thẩm
                        định trước khi cấp đơn. Nếu không tích chọn, khi khách
                        hàng có một trong những bệnh trên hệ thống sẽ mặc định
                        không cấp đơn bảo hiểm.
                      </div>
                    </Col>
                    <Col xl={24} xs={24}>
                      <Row gutter={[10]}>
                        {question?.map((data, index) => (
                          <Col key={data?.id} xs={24} xl={24}>
                            <div style={{ marginBottom: 5 }}>
                              Câu {index + 1}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                              }}
                            >
                              <Form.Item
                                className="username iputTextArea"
                                style={{ marginBottom: 10, width: "100%" }}
                                name={"assessment_question" + index}
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng nhập thông tin.",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  allowClear
                                  disabled={view}
                                  onChange={(e) =>
                                    manipulateArrayQuestion("edit", index, {
                                      ...data,
                                      content: e.target.value,
                                    })
                                  }
                                  style={{
                                    resize: "none",
                                    padding: "10px 10px",
                                    borderRadius: 7,
                                  }}
                                  rows={1}
                                  autoSize
                                  placeholder="Nhập câu hỏi"
                                />
                              </Form.Item>
                              {!disable && (
                                <img
                                  onClick={() =>
                                    manipulateArrayQuestion("delete", index)
                                  }
                                  src={IXoa}
                                  style={{
                                    width: 17,
                                    marginLeft: 10,
                                    marginTop: 15,
                                    cursor: "pointer",
                                  }}
                                />
                              )}
                            </div>
                            <div style={{ margin: "-3px 0px 20px 0px" }}>
                              <Checkbox
                                disabled={view}
                                checked={data.required}
                                onChange={(e) =>
                                  manipulateArrayQuestion("edit", index, {
                                    ...data,
                                    required: e.target.checked,
                                  })
                                }
                                value={1}
                              >
                                <i style={{ fontSize: 13, color: "#4B4B4B" }}>
                                  Cho phép thẩm định
                                </i>
                              </Checkbox>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    {!view && (
                      <Col xl={24} xs={24}>
                        <div
                          onClick={() =>
                            manipulateArrayQuestion("add", null, {
                              id: +question.length + 1,
                              content: "",
                              required: false,
                            })
                          }
                          style={{ ...css.themtuoi, marginTop: 10 }}
                        >
                          <img
                            src={Itaogoi}
                            style={{ width: 18, marginRight: 7 }}
                          />
                          Thêm câu hỏi thẩm định
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              )}
              {/* Quyền lợi chính */}
              <div
                ref={targetQLChinh}
                style={{
                  background: "#ffffff",
                  width: "100%",
                  padding: 20,
                  borderRadius: 2,
                  color: "#4B4B4B",
                  marginTop: 20,
                }}
              >
                <Row gutter={[60, 0]}>
                  <Col xs={24}>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginBottom: 10,
                      }}
                    >
                      Tạo bảng phí quyền lợi chính
                    </div>
                  </Col>
                  <Col xl={24} xs={24}>
                    {mainBenefits?.map((data, index) => (
                      <div
                        key={index + 1}
                        style={{
                          border: "1px solid #6685B6",
                          marginTop: index > 0 ? 10 : 0,
                          display: data.kind === "primary" ? "" : "none",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <div
                            draggable
                            onDragStart={(e) => onDragStart(e, index)}
                            onDragEnd={() => (draggedGroup.current = null)}
                            onMouseDown={() => setopenMainBenefits(1000)}
                            onDragOver={(e) => onDragOver(e, index)}
                            onDrop={(e) => onDrop(e, index)}
                            style={{
                              padding: 10,
                              background: "#6685B6",
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              zIndex: 1,
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Tooltip
                                overlayStyle={{ fontSize: 12 }}
                                mouseEnterDelay={1.5}
                                title={"Kéo thả để sắp xếp"}
                              >
                                <MenuOutlined
                                  style={{ marginRight: 10, cursor: "move" }}
                                />
                              </Tooltip>
                              <b style={{ textTransform: "capitalize" }}>
                                {data?.title ? data?.title : "Quyền lợi chính"}
                              </b>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: disable ? 15 : 10,
                              right: 10,
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                onClick={() => {
                                  setopenMainBenefits(1000);
                                  handleDataChangeMainBenefits(
                                    "addBenefit",
                                    null,
                                    null,
                                    {
                                      ...data,
                                      uid: '',
                                      title: data?.title
                                        ? data?.title + "(Nhân bản)"
                                        : "Quyền lợi chính (Nhân bản)",
                                      details: data.details.map(
                                        (detail) => ({
                                          ...detail,
                                          uid: '',
                                          benefits_uid: ''
                                        })
                                      ),
                                    }
                                  );
                                  handleScroll(targetQLChinh);
                                }}
                                src={Nhanban}
                                width={18}
                                style={{
                                  marginRight: 8,
                                  cursor: "pointer",
                                  display: disable ? "none" : "",
                                }}
                              />
                              <b
                                style={{
                                  display:
                                    mainBenefits?.filter(
                                      (item) => item?.kind === "primary"
                                    ).length > 1 && !disable
                                      ? ""
                                      : "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleDataChangeMainBenefits(
                                    "removeBenefit",
                                    index
                                  );
                                  form.resetFields([
                                    "category" + index,
                                    "title" + index,
                                    "category_fees" + index,
                                    "feetotal_benefitss" + index,
                                    "from_age" + index,
                                    "to_age" + index,
                                  ]);
                                }}
                              >
                                <img
                                  src={IXoa2}
                                  style={{ width: 20, marginTop: -3, marginRight: 10 }}
                                />
                              </b>
                              <img
                                onClick={() => setopenMainBenefits(index)}
                                src={Imo}
                                width={12}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            padding: 10,
                            display:
                              openMainBenefits === index ||
                                openMainBenefits === ""
                                ? ""
                                : "none",
                          }}
                        >
                          <Row gutter={[10]}>
                            <Col
                              xl={12}
                              xs={24}
                              style={{ position: "relative" }}
                            >
                              <Form.Item
                                className="username"
                                name={"category" + index}
                                style={{ marginBottom: 10 }}
                                label={
                                  <span style={{ fontWeight: 400 }}>
                                    Loại bảo hiểm
                                  </span>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng chọn thông tin",
                                  },
                                ]}
                              >
                                <Select
                                  // allowClear={true}
                                  disabled={view}
                                  mode="tags"
                                  maxTagCount={"responsive"}
                                  onChange={(e) =>
                                    handleDataChangeMainBenefits(
                                      "updateBenefit",
                                      index,
                                      null,
                                      {
                                        ...data,
                                        category: e,
                                      }
                                    )
                                  }
                                  className="custom-multi-select2"
                                  placeholder={<span>Chọn loại bảo hiểm</span>}
                                >
                                  {category.map((dataCategory) => (
                                    <Option
                                      className={"option_category"}
                                      key={dataCategory.id}
                                      value={dataCategory.value}
                                    >
                                      <Checkbox
                                        className="check_category"
                                        checked={renderChecked(
                                          data?.category,
                                          dataCategory.value
                                        )}
                                        style={{ marginRight: 8 }}
                                      />
                                      <Tooltip
                                        overlayStyle={{ fontSize: 12 }}
                                        mouseEnterDelay={1.5}
                                        title={dataCategory.title}
                                      >
                                        {dataCategory.title}
                                      </Tooltip>
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <img
                                src={ISub}
                                style={{
                                  position: "absolute",
                                  top: 48,
                                  right: 13,
                                  width: 11,
                                  opacity: 0.5,
                                }}
                              />
                            </Col>
                            <Col xs={24} xl={12}>
                              <Form.Item
                                className="username"
                                style={{ marginBottom: 10 }}
                                label={
                                  <span style={{ fontWeight: 400 }}>
                                    Tên quyền lợi
                                  </span>
                                }
                                name={"title" + index}
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng nhập thông tin.",
                                  },
                                ]}
                              >
                                <Input
                                  allowClear
                                  disabled={view}
                                  onChange={(e) =>
                                    handleDataChangeMainBenefits(
                                      "updateBenefit",
                                      index,
                                      null,
                                      {
                                        ...data,
                                        title: e.target.value,
                                      }
                                    )
                                  }
                                  placeholder="Nhập tên quyền lợi"
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={24} xs={24}>
                              <Form.Item
                                className="username"
                                name={"category_fees" + index}
                                style={{ marginBottom: 10 }}
                                label={
                                  <span style={{ fontWeight: 400 }}>
                                    Phí theo loại
                                  </span>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng chọn thông tin",
                                  },
                                ]}
                              >
                                <Select
                                  // allowClear={true}
                                  className="custom-select2"
                                  disabled={disable}
                                  onChange={(e) => {
                                    if (e === "tuoi_gioi_tinh") {
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          category_fees: e,
                                          details: [{ gender: null }],
                                        }
                                      );
                                    }
                                    if (e === "phan_tram") {
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          category_fees: e,
                                          total_benefits: null,
                                          details: [
                                            { total_benefits_from: null },
                                          ],
                                        }
                                      );
                                    }
                                    if (e === "tuoi") {
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          category_fees: e,
                                          details: [{ from_age: null }],
                                        }
                                      );
                                    }
                                  }}
                                  placeholder={<span>Chọn loại bảo hiểm</span>}
                                >
                                  <Option value={"tuoi_gioi_tinh"}>
                                    Phí theo tuổi và giới tính
                                  </Option>
                                  <Option value={"phan_tram"}>
                                    Phí theo tỉ lệ %
                                  </Option>
                                  <Option value={"tuoi"}>Phí theo tuổi</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            {data.category_fees === "phan_tram" && (
                              <Col xl={12} xs={24}>
                                <Form.Item
                                  className="username"
                                  name={"from_age" + index}
                                  style={{ marginBottom: 10 }}
                                  label={
                                    <span style={{ fontWeight: 400 }}>
                                      Độ tuổi từ
                                    </span>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Vui lòng chọn thông tin",
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={disable}
                                    showSearch={true}
                                    onChange={(e) =>
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          details: data.details.map(
                                            (detail) => ({
                                              ...detail,
                                              from_age: +e === 364 ? 0 : e,
                                              under_1_age:
                                                e === 0
                                                  ? 15
                                                  : e === 364
                                                    ? 364
                                                    : null,
                                            })
                                          ),
                                        }
                                      )
                                    }
                                    allowClear={true}
                                    className="custom-select2"
                                    placeholder={<span>Chọn tuổi từ</span>}
                                  >
                                    {age?.map((item) => (
                                      <Option
                                        key={item?.uid}
                                        value={item?.value}
                                      >
                                        {item?.title}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                            {data.category_fees === "phan_tram" && (
                              <Col xl={12} xs={24}>
                                <Form.Item
                                  className="username"
                                  name={"to_age" + index}
                                  style={{ marginBottom: 10 }}
                                  label={
                                    <span style={{ fontWeight: 400 }}>
                                      Độ tuổi đến
                                    </span>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Vui lòng chọn thông tin",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch={true}
                                    disabled={disable}
                                    onChange={(e) =>
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          details: data.details.map(
                                            (detail) => ({
                                              ...detail,
                                              to_age: +e === 364 ? 0 : e,
                                            })
                                          ),
                                        }
                                      )
                                    }
                                    allowClear={true}
                                    className="custom-select2"
                                    placeholder={<span>Chọn tuổi đến</span>}
                                  >
                                    {age?.map((item, i) => (
                                      <Option
                                        key={item?.uid}
                                        value={item?.value}
                                        style={{ display: i > 0 ? "" : "none" }}
                                      >
                                        {item?.title}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                            {/* phí theo độ tuổi */}
                            {(data.category_fees === "tuoi_gioi_tinh" ||
                              data.category_fees === "tuoi") && (
                                <Col
                                  style={{ marginTop: 10, overflowX: "auto" }}
                                  xs={24}
                                  xl={24}
                                >
                                  <table
                                    style={{
                                      whiteSpace: "nowrap",
                                      minWidth: "100%",
                                    }}
                                    className="table-cuctom"
                                  >
                                    <tr>
                                      <th>STT</th>
                                      {data.category_fees ===
                                        "tuoi_gioi_tinh" && (
                                          <th style={{ minWidth: 114 }}>
                                            Giới tính
                                          </th>
                                        )}
                                      <th style={{ minWidth: 316 }}>
                                        Phân loại tuổi
                                      </th>
                                      <th style={{ minWidth: 114 }}>Phí</th>
                                      <th>Thao tác</th>
                                    </tr>
                                    {data.details.map((detail, detailIndex) => (
                                      <tr
                                        style={{ cursor: "move" }}
                                        onDragOver={(e) =>
                                          onDragOver(e, index, detailIndex)
                                        }
                                        onDrop={(e) =>
                                          onDrop(e, index, detailIndex)
                                        }
                                        draggable
                                        onDragStart={(e) =>
                                          onDragStart(e, index, detailIndex)
                                        }
                                        onDragEnd={() =>
                                          (draggedItem.current = null)
                                        }
                                      >
                                        <td>{detailIndex + 1}</td>
                                        {data.category_fees ===
                                          "tuoi_gioi_tinh" && (
                                            <td>
                                              <Form.Item
                                                className="username"
                                                name={
                                                  "gender" + index + detailIndex
                                                }
                                                style={{ margin: 0 }}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Vui lòng chọn thông tin",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  disabled={disable}
                                                  onChange={(e) => {
                                                    handleDataChangeMainBenefits(
                                                      "updateDetail",
                                                      index,
                                                      detailIndex,
                                                      {
                                                        ...detail,
                                                        gender: e,
                                                      }
                                                    );
                                                  }}
                                                  className="custom-select2"
                                                  style={{ textAlign: "left" }}
                                                  placeholder={
                                                    <span>Chọn giới tính</span>
                                                  }
                                                >
                                                  <Option value={1}>Nam</Option>
                                                  <Option value={2}>Nữ</Option>
                                                </Select>
                                              </Form.Item>
                                            </td>
                                          )}
                                        <td>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-around",
                                            }}
                                          >
                                            <div style={{ marginRight: 5 }}>
                                              Từ
                                            </div>
                                            <Form.Item
                                              className="username"
                                              name={
                                                "from_age" + index + detailIndex
                                              }
                                              style={{
                                                margin: 0,
                                                width: "100%",
                                              }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Vui lòng chọn thông tin",
                                                },
                                              ]}
                                            >
                                              <Select
                                                // allowClear={true}
                                                showSearch
                                                disabled={disable}
                                                onChange={(e) =>
                                                  handleDataChangeMainBenefits(
                                                    "updateDetail",
                                                    index,
                                                    detailIndex,
                                                    {
                                                      ...detail,
                                                      from_age:
                                                        +e === 364 ? 0 : e,
                                                      under_1_age:
                                                        e === 0
                                                          ? 15
                                                          : e === 364
                                                            ? 364
                                                            : null,
                                                    }
                                                  )
                                                }
                                                className="custom-select2"
                                                style={{ textAlign: "left" }}
                                                placeholder={
                                                  <span>Chọn tuổi</span>
                                                }
                                              >
                                                {age?.map((item) => (
                                                  <Option
                                                    key={item?.uid}
                                                    value={item?.value}
                                                  >
                                                    {item?.title}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                            <div style={{ margin: "0px 5px" }}>
                                              Đến
                                            </div>
                                            <Form.Item
                                              className="username"
                                              name={
                                                "to_age" + index + detailIndex
                                              }
                                              style={{
                                                margin: 0,
                                                width: "100%",
                                              }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Vui lòng chọn thông tin",
                                                },
                                              ]}
                                            >
                                              <Select
                                                // allowClear={true}
                                                showSearch
                                                disabled={disable}
                                                onChange={(e) =>
                                                  handleDataChangeMainBenefits(
                                                    "updateDetail",
                                                    index,
                                                    detailIndex,
                                                    {
                                                      ...detail,
                                                      to_age: +e === 364 ? 0 : e,
                                                    }
                                                  )
                                                }
                                                className="custom-select2"
                                                style={{ textAlign: "left" }}
                                                placeholder={
                                                  <span>Chọn tuổi</span>
                                                }
                                              >
                                                {age?.map((item, i) => (
                                                  <Option
                                                    key={item?.uid}
                                                    value={item?.value}
                                                    style={{
                                                      display:
                                                        i > 0 ? "" : "none",
                                                    }}
                                                  >
                                                    {item?.title}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </div>
                                        </td>
                                        <td style={{ position: "relative" }}>
                                          <Form.Item
                                            className="username"
                                            name={"fees" + index + detailIndex}
                                            style={{
                                              marginBottom: 10,
                                              marginTop: 10,
                                              minWidth: 150,
                                            }}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Vui lòng nhập thông tin",
                                              },
                                            ]}
                                          >
                                            <InputNumber
                                              disabled={disable}
                                              onChange={(e) =>
                                                handleDataChangeMainBenefits(
                                                  "updateDetail",
                                                  index,
                                                  detailIndex,
                                                  {
                                                    ...detail,
                                                    fees: e,
                                                  }
                                                )
                                              }
                                              formatter={(value) =>
                                                `${value}`.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  "."
                                                )
                                              }
                                              parser={(value) =>
                                                value.replace(/(\.*)/g, "")
                                              }
                                              // style={css.inputNumber}
                                              min={1}
                                              style={{
                                                width: "100%",
                                                borderRadius: 6,
                                                padding: "4px 25px 4px 0px",
                                                position: "relative",
                                              }}
                                              placeholder="Nhập phí"
                                            />
                                          </Form.Item>
                                          <div style={{ ...css.suffix, top: 23 }}>
                                            VNĐ
                                          </div>
                                        </td>
                                        <td>
                                          <img
                                            onClick={() => {
                                              if (
                                                data.details.length === 1 ||
                                                disable
                                              ) {
                                                return;
                                              }
                                              handleDataChangeMainBenefits(
                                                "removeDetail",
                                                index,
                                                detailIndex
                                              );
                                              form.resetFields([
                                                "gender" + index + detailIndex,
                                                "from_age" + index + detailIndex,
                                                "to_age" + index + detailIndex,
                                                "fees" + index + detailIndex,
                                              ]);
                                            }}
                                            src={IXoa}
                                            style={{
                                              width: 17,
                                              opacity:
                                                data.details.length > 1 &&
                                                  !disable
                                                  ? 1
                                                  : 0.5,
                                              cursor: "pointer",
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ))}

                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          display: disable ? "none" : "",
                                        }}
                                        colSpan={5}
                                      >
                                        <div
                                          onClick={() =>
                                            handleDataChangeMainBenefits(
                                              "addDetail",
                                              index,
                                              null,
                                              {
                                                gender: null,
                                              }
                                            )
                                          }
                                          style={css.themtuoi}
                                        >
                                          <img
                                            src={Itaogoi}
                                            style={{
                                              width: 18,
                                              marginRight: 7,
                                            }}
                                          />
                                          Thêm độ tuổi
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </Col>
                              )}
                            {/* phí theo tỉ lệ phần trăm */}
                            {data.category_fees === "phan_tram" && (
                              <Col
                                style={{ marginTop: 10, overflowX: "auto" }}
                                xs={24}
                                xl={24}
                              >
                                <table
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100%",
                                  }}
                                  className="table-cuctom"
                                >
                                  <tr>
                                    <th>STT</th>
                                    <th>Tổng quyền lợi</th>
                                    <th>Tỉ lệ phí</th>
                                    <th>Thao tác</th>
                                  </tr>
                                  {data.details.map((details, detailIndex) => (
                                    <tr
                                      style={{ cursor: "move" }}
                                      onDragOver={(e) =>
                                        onDragOver(e, index, detailIndex)
                                      }
                                      onDrop={(e) =>
                                        onDrop(e, index, detailIndex)
                                      }
                                      draggable
                                      onDragStart={(e) =>
                                        onDragStart(e, index, detailIndex)
                                      }
                                      onDragEnd={() =>
                                        (draggedItem.current = null)
                                      }
                                    >
                                      <td>{detailIndex + 1}</td>
                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                          }}
                                        >
                                          <div style={{ marginRight: 5 }}>
                                            Từ
                                          </div>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                            }}
                                          >
                                            <Form.Item
                                              className="username"
                                              name={
                                                "total_benefits_from" +
                                                index +
                                                detailIndex
                                              }
                                              style={{
                                                marginBottom: 10,
                                                marginTop: 10,
                                                minWidth: 200,
                                              }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Vui lòng nhập thông tin",
                                                },
                                              ]}
                                            >
                                              <InputNumber
                                                disabled={disable}
                                                onChange={(e) => {
                                                  handleDataChangeMainBenefits(
                                                    "updateDetail",
                                                    index,
                                                    detailIndex,
                                                    {
                                                      ...details,
                                                      total_benefits_from: e,
                                                      from_age:
                                                        +data.details[0]
                                                          .from_age === 364
                                                          ? 0
                                                          : data.details[0]
                                                            .from_age,
                                                      to_age:
                                                        data.details[0].to_age,
                                                    }
                                                  );
                                                }}
                                                formatter={(value) =>
                                                  `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    "."
                                                  )
                                                }
                                                parser={(value) =>
                                                  value.replace(/(\.*)/g, "")
                                                }
                                                // style={css.inputNumber}
                                                min={1}
                                                style={{
                                                  width: "100%",
                                                  borderRadius: 6,
                                                  padding: "4px 25px 4px 0px",
                                                  position: "relative",
                                                }}
                                                placeholder="Nhập quyền lợi"
                                              />
                                            </Form.Item>
                                            <div
                                              style={{ ...css.suffix, top: 16 }}
                                            >
                                              VNĐ
                                            </div>
                                          </div>
                                          <div style={{ margin: "0px 5px" }}>
                                            Đến
                                          </div>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                            }}
                                          >
                                            <Form.Item
                                              className="username"
                                              name={
                                                "total_benefits_to" +
                                                index +
                                                detailIndex
                                              }
                                              style={{
                                                marginBottom: 10,
                                                marginTop: 10,
                                                minWidth: 200,
                                              }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Vui lòng nhập thông tin",
                                                },
                                              ]}
                                            >
                                              <InputNumber
                                                disabled={disable}
                                                onChange={(e) =>
                                                  handleDataChangeMainBenefits(
                                                    "updateDetail",
                                                    index,
                                                    detailIndex,
                                                    {
                                                      ...details,
                                                      total_benefits_to: e,
                                                    }
                                                  )
                                                }
                                                formatter={(value) =>
                                                  `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    "."
                                                  )
                                                }
                                                parser={(value) =>
                                                  value.replace(/(\.*)/g, "")
                                                }
                                                // style={css.inputNumber}
                                                min={1}
                                                style={{
                                                  width: "100%",
                                                  borderRadius: 6,
                                                  padding: "4px 25px 4px 0px",
                                                  position: "relative",
                                                }}
                                                placeholder="Nhập quyền lợi"
                                              />
                                            </Form.Item>
                                            <div
                                              style={{ ...css.suffix, top: 16 }}
                                            >
                                              VNĐ
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td style={{ position: "relative" }}>
                                        <Form.Item
                                          className="username"
                                          name={"rate" + index + detailIndex}
                                          style={{
                                            marginBottom: 10,
                                            marginTop: 10,
                                            minWidth: 150,
                                          }}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Vui lòng nhập thông tin",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            disabled={disable}
                                            onChange={(e) =>
                                              handleDataChangeMainBenefits(
                                                "updateDetail",
                                                index,
                                                detailIndex,
                                                {
                                                  ...details,
                                                  rate: e,
                                                }
                                              )
                                            }
                                            formatter={(value) =>
                                              `${value}`
                                                .replace(/\./, ",")
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  "."
                                                )
                                            }
                                            parser={(value) =>
                                              parseFloat(
                                                `${value}`
                                                  .replace(/,/, "#")
                                                  .replace(/\./g, "")
                                                  .replace(/#/, ".")
                                              )
                                            }
                                            // style={css.inputNumber}
                                            // min={1}
                                            style={{
                                              width: "100%",
                                              borderRadius: 6,
                                              padding: "4px 25px 4px 0px",
                                              position: "relative",
                                            }}
                                            placeholder="Nhập tỉ lệ"
                                          />
                                        </Form.Item>
                                        <div style={{ ...css.suffix, top: 23 }}>
                                          %
                                        </div>
                                      </td>
                                      <td>
                                        <img
                                          onClick={() => {
                                            if (
                                              data.details.length === 1 ||
                                              disable
                                            ) {
                                              return;
                                            }
                                            handleDataChangeMainBenefits(
                                              "removeDetail",
                                              index,
                                              detailIndex
                                            );
                                            form.resetFields([
                                              "total_benefits_from" +
                                              detailIndex,
                                              "total_benefits_to" + detailIndex,
                                              "rate" + detailIndex,
                                            ]);
                                          }}
                                          src={IXoa}
                                          style={{
                                            width: 17,
                                            opacity:
                                              data.details.length > 1 &&
                                                !disable
                                                ? 1
                                                : 0.5,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  ))}

                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        display: disable ? "none" : "",
                                      }}
                                      colSpan={5}
                                    >
                                      <div
                                        onClick={() =>
                                          handleDataChangeMainBenefits(
                                            "addDetail",
                                            index,
                                            null,
                                            {
                                              rage: null,
                                            }
                                          )
                                        }
                                        style={css.themtuoi}
                                      >
                                        <img
                                          src={Itaogoi}
                                          style={{ width: 18, marginRight: 7 }}
                                        />
                                        Thêm mức quyền lợi
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </Col>
                            )}
                            {/* Tổng quyềnl lợi */}
                            {(data.category_fees === "tuoi_gioi_tinh" ||
                              data.category_fees === "tuoi") && (
                                <Col xl={24} xs={24}>
                                  <div style={{ position: "relative" }}>
                                    <Form.Item
                                      className="username"
                                      name={"total_benefits" + index}
                                      style={{ marginBottom: 10, marginTop: 10 }}
                                      label={
                                        <span style={{ fontWeight: 400 }}>
                                          Tổng quyền lợi
                                        </span>
                                      }
                                      rules={[
                                        {
                                          required: true,
                                          message: "Vui lòng nhập thông tin",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled={disable}
                                        onChange={(e) =>
                                          handleDataChangeMainBenefits(
                                            "updateBenefit",
                                            index,
                                            null,
                                            {
                                              ...data,
                                              total_benefits: e,
                                            }
                                          )
                                        }
                                        formatter={(value) =>
                                          `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            "."
                                          )
                                        }
                                        parser={(value) =>
                                          value.replace(/(\.*)/g, "")
                                        }
                                        // style={css.inputNumber}
                                        // min={1}
                                        // max={data.details.reduce(
                                        //   (acc, curr) => acc + curr.fees,
                                        //   0
                                        // )}
                                        style={{
                                          width: "100%",
                                          borderRadius: 6,
                                          padding: "4px 0px",
                                          position: "relative",
                                        }}
                                        placeholder="Nhập tổng quyền lợi"
                                      />
                                    </Form.Item>
                                    <div style={{ ...css.suffix, top: 35 }}>
                                      VNĐ
                                    </div>
                                  </div>
                                </Col>
                              )}
                            <Col className="customEdittor" xl={24}>
                              <div style={{ margin: "10px 0px 5px 0px" }}>
                                Mô tả chi tiết
                              </div>
                              <TextEdit
                                setValue={(e) => {
                                  handleDataChangeMainBenefits(
                                    "updateBenefit",
                                    index,
                                    null,
                                    {
                                      ...data,
                                      description: e,
                                    }
                                  );
                                }}
                                defaultData={
                                  data?.description
                                }
                                disable={view}
                              />
                              <Form.Item
                                name={"description" + index}
                                style={{ margin: "-30px 0px 0px 0px" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng nhập thông tin",
                                  },
                                ]}
                              >
                                <Input style={{ display: "none" }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                    <div>
                      <div
                        onClick={() => {
                          handleDataChangeMainBenefits(
                            "addBenefit",
                            null,
                            null,
                            {
                              kind: "primary",
                              status: 1,
                              details: [
                                {
                                  gender: null,
                                },
                              ],
                            }
                          );
                          setopenMainBenefits(mainBenefits.length);
                        }}
                        style={{
                          ...css.themtuoi,
                          marginTop: 10,
                          color: "#fff",
                          background: "#0B7CE4",
                          display: disable ? "none" : "",
                        }}
                      >
                        <img
                          src={Ithem2}
                          style={{ width: 18, marginRight: 7 }}
                        />
                        Thêm quyền lợi
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* Quyền lợi bổ sung */}
              <div
                ref={targetQLBoSung}
                style={{
                  background: "#ffffff",
                  width: "100%",
                  padding: 20,
                  borderRadius: 2,
                  color: "#4B4B4B",
                  marginTop: 20,
                }}
              >
                <Row gutter={[60, 0]}>
                  <Col xs={24}>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                      }}
                    >
                      Tạo bảng phí quyền lợi bổ sung
                    </div>
                  </Col>
                  <Col xl={24} xs={24}>
                    {mainBenefits?.map((data, index) => (
                      <div
                        key={index + 1}
                        style={{
                          border: "1px solid #6685B6",
                          marginTop: index > 0 ? 10 : 0,
                          display: data.kind === "additional" ? "" : "none",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <div
                            draggable
                            onDragStart={(e) => onDragStart(e, index)}
                            onDragEnd={() => (draggedGroup.current = null)}
                            onMouseDown={() => setopenMainBenefits(1000)}
                            onDragOver={(e) => onDragOver(e, index)}
                            onDrop={(e) => onDrop(e, index)}
                            style={{
                              padding: 10,
                              background: "#6685B6",
                              color: "#fff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Tooltip
                                overlayStyle={{ fontSize: 12 }}
                                mouseEnterDelay={1.5}
                                title={"Kéo thả để sắp xếp"}
                              >
                                <MenuOutlined
                                  style={{ marginRight: 10, cursor: "move" }}
                                />
                              </Tooltip>
                              <b style={{ textTransform: "capitalize" }}>
                                {data?.title
                                  ? data?.title
                                  : "Quyền lợi bổ sung"}
                              </b>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: disable ? 15 : 10,
                              right: 10,
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                onClick={() => {
                                  handleDataChangeMainBenefits(
                                    "addBenefit",
                                    null,
                                    null,
                                    {
                                      ...data,
                                      uid: '',
                                      title: data?.title
                                        ? data?.title + "(Nhân bản)"
                                        : "Quyền lợi bổ sung (Nhân bản)",
                                      details: data.details.map(
                                        (detail) => ({
                                          ...detail,
                                          uid: '',
                                          benefits_uid: ''
                                        })
                                      ),
                                    }
                                  );
                                  setopenMainBenefits(1000);
                                  handleScroll(targetQLBoSung);
                                }}
                                src={Nhanban}
                                width={18}
                                style={{
                                  marginRight: 8,
                                  cursor: "pointer",
                                  display: disable ? "none" : "",
                                }}
                              />
                              <b
                                style={{
                                  cursor: "pointer",
                                  display: disable ? "none" : "",
                                }}
                                onClick={() => {
                                  handleDataChangeMainBenefits(
                                    "removeBenefit",
                                    index
                                  );
                                  form.resetFields([
                                    "category" + index,
                                    "title" + index,
                                    "category_fees" + index,
                                    "feetotal_benefitss" + index,
                                    "from_age" + index,
                                    "to_age" + index,
                                  ]);
                                }}
                              >
                                <img
                                  src={IXoa2}
                                  style={{ width: 20, marginTop: -3, marginRight: 10 }}
                                />
                              </b>
                              <img
                                onClick={() => setopenMainBenefits(index)}
                                src={Imo}
                                width={12}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            padding: 10,
                            display:
                              openMainBenefits === index ||
                                openMainBenefits === ""
                                ? ""
                                : "none",
                          }}
                        >
                          <Row gutter={[10]}>
                            <Col
                              xl={12}
                              xs={24}
                              style={{ position: "relative" }}
                            >
                              <Form.Item
                                className="username"
                                name={"category" + index}
                                style={{ marginBottom: 10 }}
                                label={
                                  <span style={{ fontWeight: 400 }}>
                                    Loại bảo hiểm
                                  </span>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng chọn thông tin",
                                  },
                                ]}
                              >
                                <Select
                                  // allowClear={true}
                                  disabled={view}
                                  mode="tags"
                                  maxTagCount={"responsive"}
                                  onChange={(e) =>
                                    handleDataChangeMainBenefits(
                                      "updateBenefit",
                                      index,
                                      null,
                                      {
                                        ...data,
                                        category: e,
                                      }
                                    )
                                  }
                                  className="custom-multi-select2"
                                  placeholder={<span>Chọn loại bảo hiểm</span>}
                                >
                                  {category.map((dataCategory) => (
                                    <Option
                                      className={"option_category"}
                                      key={dataCategory.id}
                                      value={dataCategory.value}
                                    >
                                      <Checkbox
                                        className="check_category"
                                        checked={renderChecked(
                                          data?.category,
                                          dataCategory.value
                                        )}
                                        style={{ marginRight: 8 }}
                                      />
                                      <Tooltip
                                        overlayStyle={{ fontSize: 12 }}
                                        mouseEnterDelay={1.5}
                                        title={dataCategory.title}
                                      >
                                        {dataCategory.title}
                                      </Tooltip>
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <img
                                src={ISub}
                                style={{
                                  position: "absolute",
                                  top: 48,
                                  right: 13,
                                  width: 11,
                                  opacity: 0.5,
                                }}
                              />
                            </Col>
                            <Col xs={24} xl={12}>
                              <Form.Item
                                className="username"
                                style={{ marginBottom: 10 }}
                                label={
                                  <span style={{ fontWeight: 400 }}>
                                    Tên quyền lợi
                                  </span>
                                }
                                name={"title" + index}
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng nhập thông tin.",
                                  },
                                ]}
                              >
                                <Input
                                  allowClear
                                  disabled={view}
                                  onChange={(e) =>
                                    handleDataChangeMainBenefits(
                                      "updateBenefit",
                                      index,
                                      null,
                                      {
                                        ...data,
                                        title: e.target.value,
                                      }
                                    )
                                  }
                                  placeholder="Nhập tên quyền lợi"
                                />
                              </Form.Item>
                            </Col>
                            <Col xl={24} xs={24}>
                              <Form.Item
                                className="username"
                                name={"category_fees" + index}
                                style={{ marginBottom: 10 }}
                                label={
                                  <span style={{ fontWeight: 400 }}>
                                    Phí theo loại
                                  </span>
                                }
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng chọn thông tin",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={disable}
                                  // allowClear={true}
                                  className="custom-select2"
                                  onChange={(e) => {
                                    if (e === "tuoi_gioi_tinh") {
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          category_fees: e,
                                          details: [{ gender: null }],
                                        }
                                      );
                                    }
                                    if (e === "phan_tram") {
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          category_fees: e,
                                          total_benefits: null,
                                          details: [
                                            { total_benefits_from: null },
                                          ],
                                        }
                                      );
                                    }
                                    if (e === "tuoi") {
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          category_fees: e,
                                          details: [{ from_age: null }],
                                        }
                                      );
                                    }
                                  }}
                                  placeholder={<span>Chọn loại bảo hiểm</span>}
                                >
                                  <Option value={"tuoi_gioi_tinh"}>
                                    Phí theo tuổi và giới tính
                                  </Option>
                                  <Option value={"phan_tram"}>
                                    Phí theo tỉ lệ %
                                  </Option>
                                  <Option value={"tuoi"}>Phí theo tuổi</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            {data.category_fees === "phan_tram" && (
                              <Col xl={12} xs={24}>
                                <Form.Item
                                  className="username"
                                  name={"from_age" + index}
                                  style={{ marginBottom: 10 }}
                                  label={
                                    <span style={{ fontWeight: 400 }}>
                                      Độ tuổi từ
                                    </span>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Vui lòng chọn thông tin",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    disabled={disable}
                                    onChange={(e) =>
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          details: data.details.map(
                                            (detail) => ({
                                              ...detail,
                                              from_age: +e === 364 ? 0 : +e,
                                              under_1_age:
                                                e === 0
                                                  ? 15
                                                  : e === 364
                                                    ? 364
                                                    : null,
                                            })
                                          ),
                                        }
                                      )
                                    }
                                    allowClear={true}
                                    className="custom-select2"
                                    placeholder={<span>Chọn tuổi từ</span>}
                                  >
                                    {age?.map((item) => (
                                      <Option
                                        key={item?.uid}
                                        value={item?.value}
                                      >
                                        {item?.title}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                            {data.category_fees === "phan_tram" && (
                              <Col xl={12} xs={24}>
                                <Form.Item
                                  className="username"
                                  name={"to_age" + index}
                                  style={{ marginBottom: 10 }}
                                  label={
                                    <span style={{ fontWeight: 400 }}>
                                      Độ tuổi đến
                                    </span>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Vui lòng chọn thông tin",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    disabled={disable}
                                    onChange={(e) =>
                                      handleDataChangeMainBenefits(
                                        "updateBenefit",
                                        index,
                                        null,
                                        {
                                          ...data,
                                          details: data.details.map(
                                            (detail) => ({
                                              ...detail,
                                              to_age: +e === 364 ? 0 : e,
                                            })
                                          ),
                                        }
                                      )
                                    }
                                    allowClear={true}
                                    className="custom-select2"
                                    placeholder={<span>Chọn tuổi đến</span>}
                                  >
                                    {age?.map((item, i) => (
                                      <Option
                                        key={item?.uid}
                                        value={item?.value}
                                        style={{ display: i > 0 ? "" : "none" }}
                                      >
                                        {item?.title}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                            {/* phí theo độ tuổi */}
                            {(data.category_fees === "tuoi_gioi_tinh" ||
                              data.category_fees === "tuoi") && (
                                <Col
                                  style={{ marginTop: 10, overflowX: "auto" }}
                                  xs={24}
                                  xl={24}
                                >
                                  <table
                                    style={{
                                      whiteSpace: "nowrap",
                                      minWidth: "100%",
                                    }}
                                    className="table-cuctom"
                                  >
                                    <tr>
                                      <th>STT</th>
                                      {data.category_fees ===
                                        "tuoi_gioi_tinh" && (
                                          <th style={{ minWidth: 114 }}>
                                            Giới tính
                                          </th>
                                        )}
                                      <th style={{ minWidth: 316 }}>
                                        Phân loại tuổi
                                      </th>
                                      <th style={{ minWidth: 114 }}>Phí</th>
                                      <th>Thao tác</th>
                                    </tr>
                                    {data.details.map((detail, detailIndex) => (
                                      <tr
                                        style={{ cursor: "move" }}
                                        onDragOver={(e) =>
                                          onDragOver(e, index, detailIndex)
                                        }
                                        onDrop={(e) =>
                                          onDrop(e, index, detailIndex)
                                        }
                                        draggable
                                        onDragStart={(e) =>
                                          onDragStart(e, index, detailIndex)
                                        }
                                        onDragEnd={() =>
                                          (draggedItem.current = null)
                                        }
                                      >
                                        <td>{detailIndex + 1}</td>
                                        {data.category_fees ===
                                          "tuoi_gioi_tinh" && (
                                            <td>
                                              <Form.Item
                                                className="username"
                                                name={
                                                  "gender" + index + detailIndex
                                                }
                                                style={{ margin: 0 }}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Vui lòng chọn thông tin",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  disabled={disable}
                                                  onChange={(e) => {
                                                    handleDataChangeMainBenefits(
                                                      "updateDetail",
                                                      index,
                                                      detailIndex,
                                                      {
                                                        ...detail,
                                                        gender: e,
                                                      }
                                                    );
                                                  }}
                                                  className="custom-select2"
                                                  style={{ textAlign: "left" }}
                                                  placeholder={
                                                    <span>Chọn giới tính</span>
                                                  }
                                                >
                                                  <Option value={1}>Nam</Option>
                                                  <Option value={2}>Nữ</Option>
                                                </Select>
                                              </Form.Item>
                                            </td>
                                          )}
                                        <td>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-around",
                                            }}
                                          >
                                            <div style={{ marginRight: 5 }}>
                                              Từ
                                            </div>
                                            <Form.Item
                                              className="username"
                                              name={
                                                "from_age" + index + detailIndex
                                              }
                                              style={{ margin: 0, width: "100%" }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Vui lòng chọn thông tin",
                                                },
                                              ]}
                                            >
                                              <Select
                                                // allowClear={true}
                                                showSearch
                                                disabled={disable}
                                                onChange={(e) =>
                                                  handleDataChangeMainBenefits(
                                                    "updateDetail",
                                                    index,
                                                    detailIndex,
                                                    {
                                                      ...detail,
                                                      from_age:
                                                        +e === 364 ? 0 : +e,
                                                      under_1_age:
                                                        e === 0
                                                          ? 15
                                                          : e === 364
                                                            ? 364
                                                            : null,
                                                    }
                                                  )
                                                }
                                                className="custom-select2"
                                                style={{ textAlign: "left" }}
                                                placeholder={
                                                  <span>Chọn tuổi</span>
                                                }
                                              >
                                                {age?.map((item) => (
                                                  <Option
                                                    key={item?.uid}
                                                    value={item?.value}
                                                  >
                                                    {item?.title}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                            <div style={{ margin: "0px 5px" }}>
                                              Đến
                                            </div>
                                            <Form.Item
                                              className="username"
                                              name={
                                                "to_age" + index + detailIndex
                                              }
                                              style={{ margin: 0, width: "100%" }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Vui lòng chọn thông tin",
                                                },
                                              ]}
                                            >
                                              <Select
                                                showSearch
                                                disabled={disable}
                                                onChange={(e) =>
                                                  handleDataChangeMainBenefits(
                                                    "updateDetail",
                                                    index,
                                                    detailIndex,
                                                    {
                                                      ...detail,
                                                      to_age: +e === 364 ? 0 : e,
                                                    }
                                                  )
                                                }
                                                className="custom-select2"
                                                style={{ textAlign: "left" }}
                                                placeholder={
                                                  <span>Chọn tuổi</span>
                                                }
                                              >
                                                {age?.map((item, i) => (
                                                  <Option
                                                    key={item?.uid}
                                                    value={item?.value}
                                                    style={{
                                                      display:
                                                        i > 0 ? "" : "none",
                                                    }}
                                                  >
                                                    {item?.title}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </div>
                                        </td>
                                        <td style={{ position: "relative" }}>
                                          <Form.Item
                                            className="username"
                                            name={"fees" + index + detailIndex}
                                            style={{
                                              marginBottom: 10,
                                              marginTop: 10,
                                              minWidth: 150,
                                            }}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Vui lòng nhập thông tin",
                                              },
                                            ]}
                                          >
                                            <InputNumber
                                              disabled={disable}
                                              onChange={(e) =>
                                                handleDataChangeMainBenefits(
                                                  "updateDetail",
                                                  index,
                                                  detailIndex,
                                                  {
                                                    ...detail,
                                                    fees: e,
                                                  }
                                                )
                                              }
                                              formatter={(value) =>
                                                `${value}`.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  "."
                                                )
                                              }
                                              parser={(value) =>
                                                value.replace(/(\.*)/g, "")
                                              }
                                              // style={css.inputNumber}
                                              min={1}
                                              style={{
                                                width: "100%",
                                                borderRadius: 6,
                                                padding: "4px 25px 4px 0px",
                                                position: "relative",
                                              }}
                                              placeholder="Nhập phí"
                                            />
                                          </Form.Item>
                                          <div style={{ ...css.suffix, top: 23 }}>
                                            VNĐ
                                          </div>
                                        </td>
                                        <td>
                                          <img
                                            onClick={() => {
                                              if (
                                                data.details.length === 1 ||
                                                disable
                                              ) {
                                                return;
                                              }
                                              handleDataChangeMainBenefits(
                                                "removeDetail",
                                                index,
                                                detailIndex
                                              );
                                              form.resetFields([
                                                "gender" + index + detailIndex,
                                                "from_age" + index + detailIndex,
                                                "to_age" + index + detailIndex,
                                                "fees" + index + detailIndex,
                                              ]);
                                            }}
                                            src={IXoa}
                                            style={{
                                              width: 17,
                                              opacity:
                                                data.details.length > 1 &&
                                                  !disable
                                                  ? 1
                                                  : 0.5,
                                              cursor: "pointer",
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ))}

                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          display: disable ? "none" : "",
                                        }}
                                        colSpan={5}
                                      >
                                        <div
                                          onClick={() =>
                                            handleDataChangeMainBenefits(
                                              "addDetail",
                                              index,
                                              null,
                                              {
                                                gender: null,
                                              }
                                            )
                                          }
                                          style={css.themtuoi}
                                        >
                                          <img
                                            src={Itaogoi}
                                            style={{ width: 18, marginRight: 7 }}
                                          />
                                          Thêm độ tuổi
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </Col>
                              )}
                            {/* phí theo tỉ lệ phần trăm */}
                            {data.category_fees === "phan_tram" && (
                              <Col
                                style={{ marginTop: 10, overflowX: "auto" }}
                                xs={24}
                                xl={24}
                              >
                                <table
                                  style={{
                                    whiteSpace: "nowrap",
                                    minWidth: "100%",
                                  }}
                                  className="table-cuctom"
                                >
                                  <tr>
                                    <th>STT</th>
                                    <th>Tổng quyền lợi</th>
                                    <th>Tỉ lệ phí</th>
                                    <th>Thao tác</th>
                                  </tr>
                                  {data.details.map((details, detailIndex) => (
                                    <tr
                                      style={{ cursor: "move" }}
                                      onDragOver={(e) =>
                                        onDragOver(e, index, detailIndex)
                                      }
                                      onDrop={(e) =>
                                        onDrop(e, index, detailIndex)
                                      }
                                      draggable
                                      onDragStart={(e) =>
                                        onDragStart(e, index, detailIndex)
                                      }
                                      onDragEnd={() =>
                                        (draggedItem.current = null)
                                      }
                                    >
                                      <td>{detailIndex + 1}</td>
                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                          }}
                                        >
                                          <div style={{ marginRight: 5 }}>
                                            Từ
                                          </div>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                            }}
                                          >
                                            <Form.Item
                                              className="username"
                                              name={
                                                "total_benefits_from" +
                                                index +
                                                detailIndex
                                              }
                                              style={{
                                                marginBottom: 10,
                                                marginTop: 10,
                                                minWidth: 200,
                                              }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Vui lòng nhập thông tin",
                                                },
                                              ]}
                                            >
                                              <InputNumber
                                                disabled={disable}
                                                onChange={(e) => {
                                                  handleDataChangeMainBenefits(
                                                    "updateDetail",
                                                    index,
                                                    detailIndex,
                                                    {
                                                      ...details,
                                                      total_benefits_from: e,
                                                      from_age:
                                                        +data.details[0]
                                                          .from_age === 364
                                                          ? 0
                                                          : +data.details[0]
                                                            .from_age,
                                                      to_age:
                                                        data.details[0].to_age,
                                                    }
                                                  );
                                                }}
                                                formatter={(value) =>
                                                  `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    "."
                                                  )
                                                }
                                                parser={(value) =>
                                                  value.replace(/(\.*)/g, "")
                                                }
                                                // style={css.inputNumber}
                                                min={1}
                                                style={{
                                                  width: "100%",
                                                  borderRadius: 6,
                                                  padding: "4px 25px 4px 0px",
                                                  position: "relative",
                                                }}
                                                placeholder="Nhập quyền lợi"
                                              />
                                            </Form.Item>
                                            <div
                                              style={{ ...css.suffix, top: 16 }}
                                            >
                                              VNĐ
                                            </div>
                                          </div>
                                          <div style={{ margin: "0px 5px" }}>
                                            Đến
                                          </div>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                            }}
                                          >
                                            <Form.Item
                                              className="username"
                                              name={
                                                "total_benefits_to" +
                                                index +
                                                detailIndex
                                              }
                                              style={{
                                                marginBottom: 10,
                                                marginTop: 10,
                                                minWidth: 200,
                                              }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Vui lòng nhập thông tin",
                                                },
                                              ]}
                                            >
                                              <InputNumber
                                                disabled={disable}
                                                onChange={(e) =>
                                                  handleDataChangeMainBenefits(
                                                    "updateDetail",
                                                    index,
                                                    detailIndex,
                                                    {
                                                      ...details,
                                                      total_benefits_to: e,
                                                    }
                                                  )
                                                }
                                                formatter={(value) =>
                                                  `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    "."
                                                  )
                                                }
                                                parser={(value) =>
                                                  value.replace(/(\.*)/g, "")
                                                }
                                                // style={css.inputNumber}
                                                min={1}
                                                style={{
                                                  width: "100%",
                                                  borderRadius: 6,
                                                  padding: "4px 25px 4px 0px",
                                                  position: "relative",
                                                }}
                                                placeholder="Nhập quyền lợi"
                                              />
                                            </Form.Item>
                                            <div
                                              style={{ ...css.suffix, top: 16 }}
                                            >
                                              VNĐ
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td style={{ position: "relative" }}>
                                        <Form.Item
                                          className="username"
                                          name={"rate" + index + detailIndex}
                                          style={{
                                            marginBottom: 10,
                                            marginTop: 10,
                                            minWidth: 150,
                                          }}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Vui lòng nhập thông tin",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            disabled={disable}
                                            onChange={(e) =>
                                              handleDataChangeMainBenefits(
                                                "updateDetail",
                                                index,
                                                detailIndex,
                                                {
                                                  ...details,
                                                  rate: e,
                                                }
                                              )
                                            }
                                            formatter={(value) =>
                                              `${value}`
                                                .replace(/\./, ",")
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  "."
                                                )
                                            }
                                            parser={(value) =>
                                              parseFloat(
                                                `${value}`
                                                  .replace(/,/, "#")
                                                  .replace(/\./g, "")
                                                  .replace(/#/, ".")
                                              )
                                            }
                                            // style={css.inputNumber}
                                            // min={1}
                                            style={{
                                              width: "100%",
                                              borderRadius: 6,
                                              padding: "4px 25px 4px 0px",
                                              position: "relative",
                                            }}
                                            placeholder="Nhập tỉ lệ"
                                          />
                                        </Form.Item>
                                        <div style={{ ...css.suffix, top: 23 }}>
                                          %
                                        </div>
                                      </td>
                                      <td>
                                        <img
                                          onClick={() => {
                                            if (
                                              data.details.length === 1 ||
                                              disable
                                            ) {
                                              return;
                                            }
                                            handleDataChangeMainBenefits(
                                              "removeDetail",
                                              index,
                                              detailIndex
                                            );
                                            form.resetFields([
                                              "total_benefits_from" +
                                              detailIndex,
                                              "total_benefits_to" + detailIndex,
                                              "rate" + detailIndex,
                                            ]);
                                          }}
                                          src={IXoa}
                                          style={{
                                            width: 17,
                                            opacity:
                                              data.details.length > 1 &&
                                                !disable
                                                ? 1
                                                : 0.5,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  ))}

                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "left",
                                        display: disable ? "none" : "",
                                      }}
                                      colSpan={5}
                                    >
                                      <div
                                        onClick={() =>
                                          handleDataChangeMainBenefits(
                                            "addDetail",
                                            index,
                                            null,
                                            {
                                              rage: null,
                                            }
                                          )
                                        }
                                        style={css.themtuoi}
                                      >
                                        <img
                                          src={Itaogoi}
                                          style={{ width: 18, marginRight: 7 }}
                                        />
                                        Thêm mức quyền lợi
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </Col>
                            )}
                            {/* Tổng quyềnl lợi */}
                            {(data.category_fees === "tuoi_gioi_tinh" ||
                              data.category_fees === "tuoi") && (
                                <Col xl={24} xs={24}>
                                  <div style={{ position: "relative" }}>
                                    <Form.Item
                                      className="username"
                                      name={"total_benefits" + index}
                                      style={{ marginBottom: 10, marginTop: 10 }}
                                      label={
                                        <span style={{ fontWeight: 400 }}>
                                          Tổng quyền lợi
                                        </span>
                                      }
                                      rules={[
                                        {
                                          required: true,
                                          message: "Vui lòng nhập thông tin",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled={disable}
                                        onChange={(e) =>
                                          handleDataChangeMainBenefits(
                                            "updateBenefit",
                                            index,
                                            null,
                                            {
                                              ...data,
                                              total_benefits: e,
                                            }
                                          )
                                        }
                                        formatter={(value) =>
                                          `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            "."
                                          )
                                        }
                                        parser={(value) =>
                                          value.replace(/(\.*)/g, "")
                                        }
                                        // style={css.inputNumber}
                                        // min={1}
                                        // max={data.details.reduce(
                                        //   (acc, curr) => acc + curr.fees,
                                        //   0
                                        // )}
                                        style={{
                                          width: "100%",
                                          borderRadius: 6,
                                          padding: "4px 0px",
                                          position: "relative",
                                        }}
                                        placeholder="Nhập tổng quyền lợi"
                                      />
                                    </Form.Item>
                                    <div style={{ ...css.suffix, top: 35 }}>
                                      VNĐ
                                    </div>
                                  </div>
                                </Col>
                              )}
                            <Col className="customEdittor" xl={24}>
                              <div style={{ margin: "10px 0px 5px 0px" }}>
                                Mô tả chi tiết
                              </div>
                              <TextEdit
                                setValue={(e) => {
                                  handleDataChangeMainBenefits(
                                    "updateBenefit",
                                    index,
                                    null,
                                    {
                                      ...data,
                                      description: e,
                                    }
                                  );
                                }}
                                defaultData={
                                  data?.description
                                }
                                disable={view}
                              />
                              <Form.Item
                                name={"description" + index}
                                style={{ margin: "-30px 0px 0px 0px" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng nhập thông tin",
                                  },
                                ]}
                              >
                                <Input style={{ display: "none" }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                    <div>
                      <div
                        onClick={() => {
                          handleDataChangeMainBenefits(
                            "addBenefit",
                            null,
                            null,
                            {
                              kind: "additional",
                              status: 1,
                              details: [
                                {
                                  gender: null,
                                },
                              ],
                            }
                          );
                          setopenMainBenefits(mainBenefits.length);
                        }}
                        style={{
                          ...css.themtuoi,
                          marginTop: 10,
                          color: "#fff",
                          background: "#0B7CE4",
                          display: disable ? "none" : "",
                        }}
                      >
                        <img
                          src={Ithem2}
                          style={{ width: 18, marginRight: 7 }}
                        />
                        Thêm quyền lợi
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* Các định nghĩa */}
              {display === "show" && (
                <div
                  style={{
                    background: "#ffffff",
                    width: "100%",
                    padding: 20,
                    borderRadius: 2,
                    color: "#4B4B4B",
                    marginTop: 20,
                  }}
                >
                  <Row gutter={[60, 0]}>
                    <Col xs={24}>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                      >
                        Các định nghĩa
                      </div>
                    </Col>
                    <Col xl={24} xs={24}>
                      <TextEdit
                        setValue={(e) => {
                          onChangForm({ data: e, name: "definition" });
                          form.setFieldsValue({ definition: e });
                        }}
                        defaultData={
                          dataUpdate?.definition
                        }
                        disable={view}
                      />
                      <Form.Item
                        name="definition"
                        style={{ margin: "-30px 0px 0px 0px" }}
                        rules={[
                          {
                            required: false,
                            message: "Vui lòng nhập thông tin",
                          },
                        ]}
                      >
                        <Input style={{ display: "none" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}
              {/* Điều khoản bổ sung */}
              {display === "show" && (
                <div
                  style={{
                    background: "#ffffff",
                    width: "100%",
                    padding: 20,
                    borderRadius: 2,
                    color: "#4B4B4B",
                    marginTop: 20,
                  }}
                >
                  <Row gutter={[60, 0]}>
                    <Col xs={24}>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                      >
                        Điều khoản bổ sung
                      </div>
                    </Col>
                    <Col xl={24} xs={24}>
                      <Row gutter={[10]}>
                        <Col xs={24} xl={24}>
                          <Form.Item
                            className="username"
                            style={{ margin: 0 }}
                            name="extra_terms"
                            rules={[
                              {
                                required: false,
                                message: "Vui lòng chọn thông tin",
                              },
                            ]}
                          >
                            <Checkbox.Group
                              disabled={view}
                              style={{ width: "100%" }}
                            >
                              <Row gutter={[10, 10]}>
                                {additionalTerms?.map((data, index) => (
                                  <Col
                                    key={data.id}
                                    style={{
                                      display: data.id < 6 ? "flex" : "none",
                                      alignItems: "flex-start",
                                      justifyContent: "flex-start",
                                    }}
                                    xl={24}
                                    xs={24}
                                  >
                                    <div style={{ marginRight: 5 }}>
                                      {index + 1}
                                    </div>
                                    <Checkbox
                                      onChange={(e) =>
                                        manipulateArrayAdditionalTerms(
                                          "edit",
                                          index,
                                          {
                                            ...data,
                                            checked: e.target.checked,
                                          }
                                        )
                                      }
                                      value={data.id}
                                    >
                                      {data.content}
                                    </Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            </Checkbox.Group>
                          </Form.Item>
                          <div>
                            {additionalTerms?.map((data, index) => (
                              <div style={{ marginTop: 15 }}>
                                <div
                                  style={{
                                    display: data.id > 5 ? "flex" : "none",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    width: "100%",
                                  }}
                                  xl={24}
                                  xs={24}
                                >
                                  <div style={{ marginRight: 5 }}>
                                    {index + 1}
                                  </div>
                                  <Checkbox
                                    checked={data.checked}
                                    onChange={(e) => {
                                      manipulateArrayAdditionalTerms(
                                        "edit",
                                        index,
                                        {
                                          ...data,
                                          checked: e.target.checked,
                                        }
                                      );
                                    }}
                                  ></Checkbox>
                                  <Form.Item
                                    className="username iputTextArea"
                                    style={{ margin: "0px 7px", width: "100%" }}
                                    name={"dieukhoankhac" + index}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Nhập điều khoản khác",
                                      },
                                    ]}
                                  >
                                    <Input.TextArea
                                      allowClear
                                      onChange={(e) =>
                                        manipulateArrayAdditionalTerms(
                                          "edit",
                                          index,
                                          {
                                            ...data,
                                            content: e.target.value,
                                          }
                                        )
                                      }
                                      style={{
                                        resize: "none",
                                        padding: "10px 10px",
                                        borderRadius: 7,
                                      }}
                                      rows={1}
                                      autoSize
                                      placeholder="Nhập điều khoản khác"
                                    />
                                  </Form.Item>
                                  <img
                                    onClick={() => {
                                      // if (+data?.id < additionalTerms.length) {
                                      //   return;
                                      // }
                                      manipulateArrayAdditionalTerms(
                                        "delete",
                                        index
                                      );
                                    }}
                                    src={IXoa}
                                    style={{
                                      width: 17,
                                      opacity: 1,
                                      cursor: "pointer",
                                      // opacity:
                                      //   additionalTerms.length === data?.id
                                      //     ? 1
                                      //     : 0.5,
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          {!view && (
                            <div
                              onClick={() =>
                                manipulateArrayAdditionalTerms("add", null, {
                                  id:
                                    additionalTerms[+additionalTerms.length - 1]
                                      .id + 1,
                                  content: "",
                                })
                              }
                              style={{
                                padding: "7px 11px",
                                background: "#fff",
                                width: "fit-content",
                                color: "#3961A0",
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                border: "1px solid #3961A0",
                                marginTop: 15,
                              }}
                            >
                              <img
                                src={Itaogoi}
                                style={{
                                  width: 18,
                                  marginRight: 11,
                                  fontWeight: 500,
                                }}
                              />
                              Thêm điều khoản khác
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
              {display === "show" && (
                <div
                  style={{
                    background: "#ffffff",
                    width: "100%",
                    padding: 20,
                    borderRadius: 2,
                    color: "#4B4B4B",
                    marginTop: 20,
                  }}
                >
                  <Row gutter={[60, 0]}>
                    <Col xs={24}>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          marginBottom: 10,
                        }}
                      >
                        Điều khoản loại trừ
                      </div>
                    </Col>
                    <Col xl={24} xs={24}>
                      <TextEdit
                        setValue={(e) => {
                          onChangForm({ data: e, name: "excluded_terms" });
                          form.setFieldsValue({ excluded_terms: e });
                        }}
                        defaultData={
                          dataUpdate?.excluded_terms
                        }
                        disable={view}
                      />
                      <Form.Item
                        name="excluded_terms"
                        style={{ margin: "-30px 0px 0px 0px" }}
                        rules={[
                          {
                            required: false,
                            message: "Vui lòng nhập thông tin",
                          },
                        ]}
                      >
                        <Input style={{ display: "none" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}
            </Row>
          </Col>
          <Col xl={6} xs={24}>
            <Row>
              <Col xs={24}>
                {/* hình banner */}
                {display === "show" && (
                  <div
                    style={{
                      background: "#ffffff",
                      width: "100%",
                      padding: 20,
                      borderRadius: 2,
                      color: "#4B4B4B",
                      marginBottom: 15,
                    }}
                  >
                    <Row gutter={[60, 0]}>
                      <Col xs={24}>
                        <div
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginBottom: 10,
                          }}
                        >
                          Hình ảnh banner
                        </div>
                      </Col>
                      <Col xs={24}>
                        {!documentaion.bannerUrl?.[0] && (
                          <Dragger
                            listType="picture"
                            multiple={false}
                            customRequest={() => { }} // Ngăn chặn gọi API tự động
                            accept="image/png, image/jpeg"
                            showUploadList={false}
                            style={{ minHeight: 141 }}
                            onChange={(e) => {
                              if (view) { return }
                              setTimeout(() => {
                                handleImportFile(e.file.originFileObj);
                              }, 300);
                            }}
                          >
                            <p>
                              <img src={Iupload} style={{ width: 24 }} />
                            </p>
                            <p>
                              <b className="ant-upload-text">
                                Kéo thả file vào đây hoặc
                              </b>
                            </p>
                            <p>
                              <b
                                style={{
                                  color: "#0B7CE4",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Tải file lên
                              </b>
                            </p>
                          </Dragger>
                        )}
                        {documentaion.bannerUrl && (
                          <div style={{ position: "relative" }}>
                            <Image src={documentaion.bannerUrl?.[0]} />
                            <img
                              onClick={() =>
                                setdocumentaion({
                                  ...documentaion,
                                  bannerUrl: null,
                                })
                              }
                              src={DeleteBaner}
                              width={25}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                display: !view ? "" : "none",
                              }}
                            />
                          </div>
                        )}
                      </Col>
                      <Col xl={24} xs={24}>
                        <div style={{ fontSize: 13, marginTop: 10 }}>
                          <i>Các file định dạng: Jpeg, png, jpg</i>
                        </div>
                        <div style={{ fontSize: 13 }}>
                          <i>Dung lượng file tối đa 3M</i>
                        </div>
                        <div style={{ fontSize: 13 }}>
                          <i>Kích thước chuẩn 860x500px</i>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {/* Quyền lợi nổi bật */}
                <div
                  style={{
                    background: "#ffffff",
                    width: "100%",
                    padding: 20,
                    borderRadius: 2,
                    color: "#4B4B4B",
                  }}
                >
                  <Row gutter={[60, 0]}>
                    <Col xs={24}>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                        }}
                      >
                        Quyền lợi nổi bật
                      </div>
                      <div style={{ fontSize: 13 }}>
                        <i>Thông tin hiển thị ngoài trang tìm kiếm</i>
                      </div>
                    </Col>
                    <Col xl={24} xs={24}>
                      <Row gutter={[10]}>
                        {arrOutstandingBenefits.map((item, i) => (
                          <Col key={item.id} xs={24} xl={24}>
                            <div style={{ margin: "5px 0px 5px 0px" }}>
                              {item.label}{" "}
                              {!checkAccountGC && (
                                <EditOutlined
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (view) { return }
                                    setopenOutstandingBenefits(true);
                                    setDataUpdateTimeAndAdditionalTerms({
                                      ...item,
                                      idEdit: i,
                                    });
                                  }}
                                />
                              )}
                            </div>
                            {i <= 5 && (
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Form.Item
                                    className="username"
                                    name={item.code}
                                    style={{ marginBottom: 10, width: "100%" }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Vui lòng nhập thông tin",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      disabled={view}
                                      onChange={(e) =>
                                        handleModifyBenefits("edit", i, {
                                          ...item,
                                          value: e,
                                        })
                                      }
                                      formatter={(value) =>
                                        `${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          "."
                                        )
                                      }
                                      parser={(value) =>
                                        value.replace(/(\.*)/g, "")
                                      }
                                      style={{
                                        width: "100%",
                                        borderRadius: 6,
                                        padding: "4px 0px",
                                        position: "relative",
                                      }}
                                      placeholder="0"
                                    />
                                  </Form.Item>
                                </div>
                                <div
                                  style={{
                                    ...css.suffix,
                                    top: 5,
                                  }}
                                >
                                  VNĐ
                                </div>
                              </div>
                            )}
                            {i > 5 && (
                              <Form.Item
                                className="username iputTextArea"
                                style={{ marginBottom: 10 }}
                                name={item.code}
                                rules={[
                                  {
                                    required: false,
                                    message: "Vui lòng nhập thông tin.",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  disabled={view}
                                  allowClear
                                  rows={1}
                                  autoSize
                                  placeholder="Nhập ưu điểm khác"
                                  onChange={(e) =>
                                    handleModifyBenefits("edit", i, {
                                      ...item,
                                      value: e.target.value,
                                    })
                                  }
                                  style={{
                                    resize: "none",
                                    padding: "8px 10px",
                                    borderRadius: 7,
                                  }}
                                />
                              </Form.Item>
                            )}
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </div>
                {/* thời gian chờ */}
                {display === "show" && (
                  <div
                    style={{
                      background: "#ffffff",
                      width: "100%",
                      padding: 20,
                      borderRadius: 2,
                      color: "#4B4B4B",
                      marginTop: 15,
                    }}
                  >
                    <Row gutter={[60, 0]}>
                      <Col xs={24}>
                        <div
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                          }}
                        >
                          Thời gian chờ
                        </div>
                      </Col>
                      <Col xl={24} xs={24}>
                        <Row gutter={[10]}>
                          {waitingTimes?.map((item, index) => (
                            <Col key={item.id} xs={24} xl={24}>
                              <div style={{ margin: "5px 0px 5px 0px" }}>
                                {item.label}{" "}
                                {!checkAccountGC && (
                                  <EditOutlined
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (view) { return }
                                      setopenAddWaitingTime(true);
                                      setDataUpdateTimeAndAdditionalTerms({
                                        ...item,
                                        idEdit: index,
                                      });
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Form.Item
                                    className="username"
                                    name={item.code}
                                    style={{ marginBottom: 10, width: "100%" }}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Vui lòng nhập thông tin",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      disabled={view}
                                      onChange={(e) =>
                                        handleModifyWaitingTime("edit", index, {
                                          ...item,
                                          days: e,
                                        })
                                      }
                                      formatter={(value) =>
                                        `${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          "."
                                        )
                                      }
                                      parser={(value) =>
                                        value.replace(/(\.*)/g, "")
                                      }
                                      // style={css.inputNumber}
                                      // min={1}
                                      style={{
                                        width: "100%",
                                        borderRadius: 6,
                                        padding: "4px 0px",
                                        position: "relative",
                                      }}
                                      placeholder="0"
                                    />
                                  </Form.Item>
                                  {!checkAccountGC && (
                                    <img
                                      onClick={() => {
                                        if (view) { return }
                                        handleModifyWaitingTime("delete", index)
                                      }
                                      }
                                      src={IXoa}
                                      width={17}
                                      height={17}
                                      style={{
                                        marginTop: -6,
                                        marginLeft: 5,
                                        cursor: "pointer",
                                        display:
                                          waitingTimes.length > 1 ? "" : "none",
                                      }}
                                    />
                                  )}
                                </div>
                                <div
                                  style={{
                                    ...css.suffix,
                                    top: 5,
                                    marginRight:
                                      waitingTimes.length > 1
                                        ? disable
                                          ? 20
                                          : 20
                                        : "",
                                  }}
                                >
                                  Ngày
                                </div>
                              </div>
                            </Col>
                          ))}
                          {
                            !view &&
                            <Col xs={24}>
                              <div
                                onClick={() => setopenAddWaitingTime(true)}
                                style={{ ...css.themtuoi, margin: "10px 0px" }}
                              >
                                <img
                                  src={Itaogoi}
                                  style={{ width: 18, marginRight: 7 }}
                                />
                                Thêm khác
                              </div>
                            </Col>
                          }
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
                {/* Tài liệu sản phẩm */}
                {display === "show" && (
                  <div
                    style={{
                      background: "#ffffff",
                      width: "100%",
                      padding: 20,
                      borderRadius: 2,
                      color: "#4B4B4B",
                      marginTop: 15,
                    }}
                  >
                    <Row gutter={[60, 0]}>
                      <Col xs={24}>
                        <div
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                          }}
                        >
                          Tài liệu sản phẩm
                        </div>
                        <div style={{ fontSize: 13 }}>
                          <i>Các file định dạng: .PDF</i>
                        </div>
                        <div style={{ fontSize: 13 }}>
                          <i>Dung lượng file tối đa 10M</i>
                        </div>
                      </Col>
                      <Col xl={24} xs={24}>
                        {/* quy tắc bảo hiểm */}
                        <div style={{ marginTop: 10 }}>
                          <div>Quy tắc bảo hiểm</div>
                          <div>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("rule_insurance");
                                setTimeout(() => {
                                  importInputRef.current.click();
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.rule_insurance?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>Tải file</b>
                            </span>
                            <span style={{
                              display: documentaion?.rule_insurance?.[0]
                                ? "none"
                                : "",
                            }}> hoặc </span>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("rule_insurance");
                                setTimeout(() => {
                                  setOpenAddLinkDocument(true)
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.rule_insurance?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>nhập link</b>
                            </span>
                          </div>
                          {documentaion?.rule_insurance?.[0] && (
                            <div
                              style={{
                                padding: "10px 0px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #ECECEC",
                              }}
                            >
                              <div>
                                <img
                                  src={Ifile}
                                  style={{ width: 15, marginRight: 5 }}
                                />
                                <span style={{ color: "#0B7CE4" }}>
                                  <a
                                    target="_blank"
                                    href={documentaion?.rule_insurance?.[0]}
                                  >
                                    Quytacbaohiem.pdf
                                  </a>
                                </span>
                              </div>
                              <div>
                                <img
                                  onClick={() => {
                                    if (view) {
                                      return
                                    }
                                    setdocumentaion({
                                      ...documentaion,
                                      rule_insurance: null,
                                    })
                                  }}
                                  src={IDeleteFile}
                                  style={{ width: 15, cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Danh sách bệnh viện phòng khám loại trừ */}
                        <div style={{ marginTop: 10 }}>
                          <div>Danh sách bệnh viện, phòng khám loại trừ</div>
                          <div>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("excludedHospitals");
                                setTimeout(() => {
                                  importInputRef.current.click();
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.excludedHospitals?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>Tải file</b>
                            </span>
                            <span style={{
                              display: documentaion?.excludedHospitals?.[0]
                                ? "none"
                                : ""
                            }}> hoặc </span>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("excludedHospitals");
                                setTimeout(() => {
                                  setOpenAddLinkDocument(true)
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.excludedHospitals?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>nhập link</b>
                            </span>
                          </div>
                          {documentaion?.excludedHospitals?.[0] && (
                            <div
                              style={{
                                padding: "10px 0px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #ECECEC",
                              }}
                            >
                              <div>
                                <img
                                  src={Ifile}
                                  style={{ width: 15, marginRight: 5 }}
                                />
                                <span style={{ color: "#0B7CE4" }}>
                                  <a
                                    target="_blank"
                                    href={documentaion?.excludedHospitals?.[0]}
                                  >
                                    danhsachloaitru.pdf
                                  </a>
                                </span>
                              </div>
                              <div>
                                <img
                                  onClick={() => {
                                    if (view) {
                                      return
                                    }
                                    setdocumentaion({
                                      ...documentaion,
                                      excludedHospitals: null,
                                    })
                                  }
                                  }
                                  src={IDeleteFile}
                                  style={{ width: 15, cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Danh sách bệnh viện phòng khám bảo lãnh */}
                        <div style={{ marginTop: 10 }}>
                          <div>Danh sách bệnh viện, phòng khám bảo lãnh</div>
                          <div>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("hospitalsGuarantee");
                                setTimeout(() => {
                                  importInputRef.current.click();
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.hospitalsGuarantee?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>Tải file</b>
                            </span>
                            <span style={{
                              display: documentaion?.hospitalsGuarantee?.[0]
                                ? "none"
                                : ""
                            }}> hoặc </span>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("hospitalsGuarantee");
                                setTimeout(() => {
                                  setOpenAddLinkDocument(true)
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.hospitalsGuarantee?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>nhập link</b>
                            </span>
                          </div>
                          {documentaion?.hospitalsGuarantee?.[0] && (
                            <div
                              style={{
                                padding: "10px 0px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #ECECEC",
                              }}
                            >
                              <div>
                                <img
                                  src={Ifile}
                                  style={{ width: 15, marginRight: 5 }}
                                />
                                <span style={{ color: "#0B7CE4" }}>
                                  <a
                                    target="_blank"
                                    href={documentaion?.hospitalsGuarantee?.[0]}
                                  >
                                    danhsachbaolanh.pdf
                                  </a>
                                </span>
                              </div>
                              <div>
                                <img
                                  onClick={() => {
                                    if (view) {
                                      return
                                    }
                                    setdocumentaion({
                                      ...documentaion,
                                      hospitalsGuarantee: null,
                                    })
                                  }
                                  }
                                  src={IDeleteFile}
                                  style={{ width: 15, cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Brochure sản phẩm */}
                        <div style={{ marginTop: 10 }}>
                          <div>Brochure sản phẩm</div>
                          <div>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("brochure");
                                setTimeout(() => {
                                  importInputRef.current.click();
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.brochure?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>Tải file</b>
                            </span>
                            <span style={{
                              display: documentaion?.brochure?.[0]
                                ? "none"
                                : ""
                            }}> hoặc </span>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("brochure");
                                setTimeout(() => {
                                  setOpenAddLinkDocument(true)
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.brochure?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>nhập link</b>
                            </span>
                          </div>
                          {documentaion?.brochure?.[0] && (
                            <div
                              style={{
                                padding: "10px 0px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #ECECEC",
                              }}
                            >
                              <div>
                                <img
                                  src={Ifile}
                                  style={{ width: 15, marginRight: 5 }}
                                />
                                <span style={{ color: "#0B7CE4" }}>
                                  <a
                                    target="_blank"
                                    href={documentaion?.brochure?.[0]}
                                  >
                                    brochure.pdf
                                  </a>
                                </span>
                              </div>
                              <div>
                                <img
                                  onClick={() => {
                                    if (view) {
                                      return
                                    }
                                    setdocumentaion({
                                      ...documentaion,
                                      brochure: null,
                                    })
                                  }
                                  }
                                  src={IDeleteFile}
                                  style={{ width: 15, cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Các tài liệu khác */}
                        <div style={{ marginTop: 10 }}>
                          <div>Các tài liệu khác</div>
                          <div>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("other");
                                setTimeout(() => {
                                  importInputRef.current.click();
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.other?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>Tải file</b>
                            </span>
                            <span style={{
                              display: documentaion?.other?.[0]
                                ? "none"
                                : ""
                            }}> hoặc </span>
                            <span
                              onClick={() => {
                                if (view) {
                                  return
                                }
                                setKeyNameFile("other");
                                setTimeout(() => {
                                  setOpenAddLinkDocument(true)
                                }, 300);
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: "pointer",
                                display: documentaion?.other?.[0]
                                  ? "none"
                                  : "",
                              }}
                            >
                              <b>nhập link</b>
                            </span>
                          </div>
                          {documentaion?.other?.[0] && (
                            <div
                              style={{
                                padding: "10px 0px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #ECECEC",
                              }}
                            >
                              <div>
                                <img
                                  src={Ifile}
                                  style={{ width: 15, marginRight: 5 }}
                                />
                                <span style={{ color: "#0B7CE4" }}>
                                  <a
                                    target="_blank"
                                    href={documentaion?.other?.[0]}
                                  >
                                    tailieukhac.pdf
                                  </a>
                                </span>
                              </div>
                              <div>
                                <img
                                  onClick={() => {
                                    if (view) {
                                      return
                                    }
                                    setdocumentaion({
                                      ...documentaion,
                                      other: null,
                                    })
                                  }
                                  }
                                  src={IDeleteFile}
                                  style={{ width: 15, cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          style={{
            width: "100%",
            marginBottom: 20,
          }}
        >
          <Row gutter={[60, 0]}>
            <Col xs={24} xl={24}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <Button
                  onClick={() => Back(1)}
                  style={{
                    background: "#ffffff",
                    color: "#0B7CE4",
                    width: "90px",
                    textAlign: "center",
                    borderRadius: 6,
                    border: "1px solid #0B7CE4",
                    height: 40,
                  }}
                >
                  <b>Bỏ qua</b>
                </Button>
                <div style={{ display: checkAccountGC || view ? "none" : "" }}>
                  <Button
                    loading={loading || loadingIndex}
                    onClick={() => {
                      setopenMainBenefits("");
                      const data = {
                        status: 1,
                        waitingTimes: waitingTimes,
                        question: question,
                        documentaion: documentaion,
                        // điều khoản bổ sung
                        additionalTerms: additionalTerms,
                        // quyền lợi chính và phụ
                        mainBenefits: mainBenefits,
                        // Định nghĩa khác
                        definition: formData?.definition,
                      }
                      checkAdditionalBenefitsAge(data);
                      // onFinish();
                    }}
                    style={{
                      color: "#0B7CE4",
                      textAlign: "center",
                      borderRadius: 6,
                      border: "1px solid #0B7CE4",
                      height: 40,
                      marginRight: 10,
                      display: (+dataUpdate.status === 2) ? "none" : "",
                    }}
                  >
                    {/* public 0 là nháp 1 là hiển thi */}
                    <b>Lưu nháp</b>
                  </Button>
                  <Button
                    // htmlType="submit"
                    loading={loading || loadingIndex}
                    onClick={() => {
                      setopenMainBenefits("");
                      const data = {
                        status: 4,
                        waitingTimes: waitingTimes,
                        question: question,
                        documentaion: documentaion,
                        // điều khoản bổ sung
                        additionalTerms: additionalTerms,
                        // quyền lợi chính và phụ
                        mainBenefits: mainBenefits,
                        // Định nghĩa khác
                        definition: formData?.definition,
                      }
                      checkAdditionalBenefitsAge(data);
                    }}
                    style={{
                      background: "#0B7CE4",
                      color: "#ffffff",
                      textAlign: "center",
                      borderRadius: 6,
                      border: "1px solid #0B7CE4",
                      height: 40,
                    }}
                  >
                    <b>{(+dataUpdate.status === 2) ? "Cập nhật" : "Hiển thị"}</b>
                  </Button>
                </div>
                {/* Tài khoản GC thì hiện nút duyệt và từ chối */}
                <div
                  style={{
                    display:
                      checkAccountGC && dataUpdate?.status === 4 ? "" : "none",
                  }}
                >
                  <Button
                    loading={loading || loadingIndex}
                    onClick={() => {
                      setopenCensorship(true);
                    }}
                    style={{
                      color: "#0B7CE4",
                      textAlign: "center",
                      borderRadius: 6,
                      border: "1px solid #0B7CE4",
                      height: 40,
                      marginRight: 10,
                      display: +dataUpdate.status === 2 ? "none" : "",
                    }}
                  >
                    {/* public 0 là nháp 1 là hiển thi */}
                    <b>Từ chối</b>
                  </Button>
                  <Button
                    onClick={() => {
                      Approve(dataUpdate?.uid);
                    }}
                    loading={loading || loadingIndex}
                    style={{
                      background: "#0B7CE4",
                      color: "#ffffff",
                      textAlign: "center",
                      borderRadius: 6,
                      border: "1px solid #0B7CE4",
                      height: 40,
                    }}
                  >
                    <b>Duyệt</b>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      <input
        ref={importInputRef}
        type="file"
        accept="application/pdf"
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={(e) => handleImportFile(e.target.files[0])}
        style={{
          display: "none",
        }}
      />
      ;
    </>
  );
};

const css = {
  suffix: {
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    position: "absolute",
    right: 15,
    top: 37,
    cursor: "pointer",
    fontSize: 14,
    color: "#A0A0A0",
  },
  themtuoi: {
    padding: "7px 11px",
    border: "1px solid #3961A0",
    width: "fit-content",
    color: "#3961A0",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};
export default TaoGoiSanPham;
