import React, { useEffect, useState, useRef } from "react";
// import CKEditor from "ckeditor4-react";
import CKEditor from "ckeditor4-react-advanced";

export default function Editor(props) {
  const [dataDefault, setDataDefault] = useState("");
  const [dataDebound, setDataDebound] = useState("");
  const [data, setData] = useState(props?.defaultData);

  const handleEditorChange = (e) => {
    let data = e.editor.getData().replace(/border="1"/g, 'border="1"')
      .replace(/cellpadding="1"/g, 'cellpadding="5"')
      .replace(/cellspacing="1"/g, 'cellspacing="0"')
      // .replace(/<td>/g, '<td style="width: 50%">')
      .replace(/style="width:500px"/g, 'style="width:100%"')
    // props.setValue(e.editor.getData());
    // console.log('dataedit',data);
    setData(data);
    props.setValue(data);
    // console.log(e.editor)
  };

    // Debounced edit data
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        handleEditorChange(dataDebound);
      }, 1500);
      return () => clearTimeout(timeoutId);
    }, [dataDebound]);

  // Loại bỏ không cho paste table từ ngoài vào editer
  const onEditorInstanceReady = (editor) => {
    editor.on("paste", (evt) => {
      const data = evt.data.dataValue;
      const parsedData = new DOMParser().parseFromString(data, "text/html");
      const tables = parsedData.getElementsByTagName("table");
      // Kiểm tra và loại bỏ các bảng
      for (let i = tables.length - 1; i >= 0; i--) {
        tables[i].parentNode.removeChild(tables[i]);
      }
      // Cập nhật nội dung dán vào không có bảng
      evt.data.dataValue = parsedData.body.innerHTML;
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setData(props?.defaultData)
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [props?.defaultData]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (props?.defaultData) {
  //       // setDataDefault(props?.defaultData);
  //       setData(props?.defaultData)
  //     }
  //   }, 1000);
  // }, []);

  const toolbarConfig = [
    [
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "Bold",
      "Italic",
      "Underline",
      "NumberedList",
      "BulletedList",
      "Link",
      "Table",
    ], // Chỉ hiển thị nút chèn link và nút xóa link
  ];

  const editorConfig = {
    ignoreEmptyParagraph: true,
    fillEmptyBlocks: false,
    autoParagraph: false,
    disableVersionFetching: true,
    toolbar: toolbarConfig,
    disallowedContent: 'script',
    removePlugins: 'about',
    enterMode: 2, // sử dung div thay vì p
    contentsCss: "body {font-size: 14px; height: 170px, font-family:'Roboto', line-height: 1} p {line-height: 1.5;}",
  };

  return (
    <div className="customEdittor">
      <CKEditor
        onChange={(e)=>setDataDebound(e)}
        // onInstanceReady={(evt) => onEditorInstanceReady(evt.editor)}// Loại bỏ không cho paste table từ ngoài vào editer
        config={editorConfig}
        data={data}
        readOnly={props.disable}
      />
    </div>
  );
}
