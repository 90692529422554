import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form, Input, message } from "antd";
import { AuthContext } from "./../../../context/AuthProvider";
import Warning from "./../../../assets/images/salesManager/Warning.png";

export default function MDConfirm(props) {
  const { profile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const Send = () => {
    props.create();
  };

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom"
        visible={props?.open}
        centered={true}
        closable={false}
        // onCancel={props?.handleCancel}
        style={{ borderRadius: 10, maxWidth: "370px" }}
      >
        <div style={{ textAlign: "center", fontSize: 15 }}>
          <div>
            <img src={Warning} style={{ width: 60 }} />
          </div>
          <div style={{ margin: "10px 0px", fontSize: 15 }}>
            Khi kích hoạt khuyến mãi này, Quý khách không được phép chỉnh sửa
            hoặc ngừng trước thời gian kết thúc. Muốn kết thúc chương trình
            trước thời hạn. Quý khách buộc phải gửi công văn yêu cầu đến nhà
            phát hành Global Care yêu cầu kết thúc chương trình.
          </div>
          <div>
            <b>Bạn có chắc chắn muốn kích hoạt chương trình khuyến mãi?</b>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 15,
          }}
        >
          <>
            <Button
              onClick={() => props?.handleCancel()}
              type="primary"
              style={{
                background: "#fff",
                fontSize: 14,
                border: "1px solid #0B7CE4",
                fontWeight: 600,
                width: 80,
                color: "#0B7CE4",
                marginRight: 10,
              }}
            >
              Quay lại
            </Button>
            <Button
              loading={props?.loading}
              onClick={() => Send()}
              type="primary"
              style={{
                background: "#0B7CE4",
                fontSize: 14,
                border: "1px solid #0B7CE4",
                fontWeight: 600,
                minWidth: 80,
              }}
            >
              Đồng ý
            </Button>
          </>
        </div>
      </Modal>
    </>
  );
}
