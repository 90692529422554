import axios from "axios";
import * as config from "./config";
import { Base64 } from "js-base64";
import history from "../routes/history";
import "antd/dist/antd.css";
import { message } from "antd";

async function apicallNotRequireLogin(method, url, data) {
  let token = JSON.parse(localStorage.getItem("dataUser"))?.token;
  try {
    const res = await axios({
      method: method,
      url: `${config.server}${url}`,
      data: data,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      const errMess = error.response.data.message;
      message.error(
        {
          content: errMess,
          style: {
            width: "90%",
            margin: "25px auto",
          },
        },
        15
      );
      if (
        errMess === "Authorization token is invalid!" ||
        errMess === "Account not found or disabled!"
      ) {
        history.push("/sign-in");
      }
    }
    return error;
  }
}

async function apicall(method, url, data) {
  if (!localStorage.getItem("login")) {
    return;
  }
  let token = JSON.parse(localStorage.getItem("dataUser"))?.token;
  try {
    const res = await axios({
      method: method,
      url: `${config.server}${url}`,
      data: data,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      const errMess =
        error.response.data.message || "Có lỗi xảy ra vui lòng kiểm tra lại!";
      if (
        errMess === "Authorization token is invalid!" ||
        errMess === "Account not found or disabled!"
      ) {
        history.push("/sign-in");
        localStorage.clear();
        return;
      }
      message.error(
        {
          content: errMess,
        },
        15
      );
    }
    return error;
  }
}

async function apicall2(method, url, data) {
  if (!localStorage.getItem("login")) {
    return;
  }
  let token = JSON.parse(localStorage.getItem("dataUser"))?.token;
  const res = await axios({
    method: method,
    url: `${config.server}${url}`,
    data: data,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
}

async function apicall_upload(method, url, data) {
  if (!localStorage.getItem("login")) {
    return;
  }
  let token = JSON.parse(localStorage.getItem("dataUser"))?.token;
  try {
    const res = await axios({
      method: method,
      url: `${config.server}${url}`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      message.error(
        {
          content: error.response.data.message,
        },
        15
      );
    }
  }
}

async function apicall_upload_G2(method, url, data) {
  if (!localStorage.getItem("login")) {
    return;
  }
  let token = JSON.parse(localStorage.getItem("dataUser"))?.token;
  const res = await axios({
    method: method,
    url: `${config.serverG2}${url}`,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `bearer ${token}`,
    },
  });
  return res;
}

const variable = {
  apicall,
  apicall_upload,
  apicall_upload_G2,
  apicall2,
  apicallNotRequireLogin,
};
export default variable;
