import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Form,
  Input,
  Select,
  DatePicker,
  message,
  List,
  Dropdown,
  Typography
} from "antd";
import "antd/dist/antd.css";
import Service from "./../../../extra/getAPI";
import { AuthContext } from "./../../../context/AuthProvider";
import { GlobalState } from "./index";
import Helper from "../../../extra/helper";
import ThaotacA from "./../../../assets/images/salesManager/thaotacA.png";
import Itimkiem from "./../../../assets/images/itimkiem.png";
import Ikichhoat from "./../../../assets/images/salesManager/ikichhoat.png";
import IChinhsua from "./../../../assets/images/salesManager/chinh-sua.png";
import Inhanban from "./../../../assets/images/salesManager/nhan-ban.png";
import Ingunghoatdong from "./../../../assets/images/salesManager/ngunghoadong.png";
import Itaogoi from "./../../../assets/images/salesManager/itaogoi.png";
import Ixoa from "./../../../assets/images/salesManager/ixoa.png";
import IDothoanthanh from "./../../../assets/images/salesManager/dothoanthanh.png";
import INhap from "./../../../assets/images/salesManager/dotnhap.png";
import INgung from "./../../../assets/images/salesManager/dotngung.png";
import Icho from "./../../../assets/images/salesManager/dotcho.png";
import ITuchoi from "./../../../assets/images/salesManager/dothuy.png";
import Ixem from "./../../../assets/images/salesManager/ixem.png";
import MDConfirmActive from "./../../../components/modal/san-pham/suc-khoe-vang/mdConfirmActive";
import MDConfirmDelete from "./../../../components/modal/san-pham/suc-khoe-vang/mdConfirmDelete";
import MDConfirmDisable from "./../../../components/modal/san-pham/suc-khoe-vang/mdConfirmDisable";

function Danhsachgoi() {
  const { checkAccountGC, setIndexPage, roleManagement, provider } =
    useContext(AuthContext);
  const {
    setPage,
    setDataUpdate,
    setMainBenefits,
    Back,
    setActionDetail,
    getProducts,
  } = useContext(GlobalState);
  const { Paragraph, Text } = Typography;
  const [dataOrders, seDataOrders] = useState([]);
  const { Option } = Select;
  const [pageS, setPageS] = useState(0);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [dataAction, setDataAction] = useState({});
  const [formData, setFormData] = useState({ offset: 1 });
  const [tabsActive, setTabsActive] = useState("");
  const [openConfirmActive, setOpenConfirmActive] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmDisable, setOpenConfirmDisable] = useState(false);
  const [uid, setUid] = useState();


  const datatab = [
    { id: "", title: "Tất cả" },
    { id: 4, title: "Chờ kiểm duyệt" },
    { id: 2, title: "Đang hoạt động" },
    { id: 3, title: "Ngừng hoạt động" },
    { id: 5, title: "Từ chối" },
    { id: 1, title: "Nháp" },
  ];

  const data = [
    {
      title: (
        <div
          onClick={() => {
            if (
              dataAction?.status === 4 ||
              dataAction?.status === 2 ||
              dataAction?.status === 3
            ) {
              setActionDetail("xem");
            } else {
              setActionDetail("");
            }
            getDetail(dataAction?.uid);
          }}
          style={{ cursor: "pointer" }}
        >
          {dataAction?.status === 4 ||
            dataAction?.status === 2 ||
            dataAction?.status === 3
            ? "Xem"
            : "Chỉnh sửa"}
        </div>
      ),
      avatar: (
        <img
          onClick={() => {
            if (
              dataAction?.status === 4 ||
              dataAction?.status === 2 ||
              dataAction?.status === 3
            ) {
              setActionDetail("xem");
            } else {
              setActionDetail("");
            }
            getDetail(dataAction?.uid);
          }}
          src={
            dataAction?.status === 4 ||
              dataAction?.status === 2 ||
              dataAction?.status === 3
              ? Ixem
              : IChinhsua
          }
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => {
            Duplicate(dataAction?.uid);
          }}
          style={{ cursor: "pointer" }}
        >
          Nhân bản
        </div>
      ),
      avatar: (
        <img
          onClick={() => Duplicate(dataAction?.uid)}
          src={Inhanban}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => {
            setOpenConfirmActive(true);
            // message.warning('Chức năng đang xây dựng')
          }}
          style={{ cursor: "pointer" }}
        >
          Hiển thị
        </div>
      ),
      avatar: (
        <img
          onClick={() => {
            setOpenConfirmActive(true);
          }}
          src={Ikichhoat}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => setOpenConfirmDisable(true)}
          style={{ cursor: "pointer" }}
        >
          Ngừng hoạt động
        </div>
      ),
      avatar: (
        <img
          onClick={() => {
            setOpenConfirmDisable(true);
          }}
          src={Ingunghoatdong}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => setOpenConfirmDelete(true)}
          style={{ cursor: "pointer" }}
        >
          Xoá
        </div>
      ),
      avatar: (
        <img
          onClick={() => {
            setOpenConfirmDelete(true);
          }}
          src={Ixoa}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  const Action = (
    <List
      min-width="100%"
      className="header-notifications-dropdown custom"
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item, i) => (
        <List.Item
          style={{
            display:
              (dataAction.status === 1 && i === 3) ||
                (dataAction.status === 2 && i === 2) ||
                (dataAction.status === 2 && i === 4) ||
                (dataAction.status === 2 && i === 3) ||
                (dataAction.status === 3 && i === 4) ||
                (dataAction.status === 3 && i === 3) ||
                (dataAction.status === 4 && i === 2) ||
                (dataAction.status === 4 && i === 4) ||
                (dataAction.status === 4 && i === 3) ||
                (dataAction.status === 5 && i === 1) ||
                (dataAction.status === 5 && i === 2) ||
                (dataAction.status === 5 && i === 3) ||
                (dataAction.status === 5 && i === 4) ||
                (+roleManagement?.edit?.product !== 1 &&
                  i === 0 &&
                  +roleManagement?.manager?.product !== 1 &&
                  i === 0)
                ? "none"
                : "",
          }}
        >
          <List.Item.Meta
            avatar={item.avatar}
            title={<span style={{ fontWeight: 500 }}>{item.title}</span>}
          />
        </List.Item>
      )}
    />
  );

  const dataActionGC = [
    {
      title: (
        <div
          onClick={() => {
            getDetail(dataAction?.uid);
            setActionDetail("xem");
          }}
          style={{ cursor: "pointer" }}
        >
          Xem
        </div>
      ),
      avatar: (
        <img
          onClick={() => getDetail(dataAction?.uid)}
          src={Ixem}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => {
            setOpenConfirmActive(true);
          }}
          style={{ cursor: "pointer" }}
        >
          Kích hoạt
        </div>
      ),
      avatar: (
        <img
          onClick={() => {
            setOpenConfirmActive(true);
          }}
          src={Ikichhoat}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => getDetail(dataAction?.uid)}
          style={{ cursor: "pointer" }}
        >
          Từ chối
        </div>
      ),
      avatar: (
        <img
          onClick={() => getDetail(dataAction?.uid)}
          src={Ingunghoatdong}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => setOpenConfirmDisable(true)}
          style={{ cursor: "pointer" }}
        >
          Ngừng hoạt động
        </div>
      ),
      avatar: (
        <img
          onClick={() => {
            setOpenConfirmDisable(true);
          }}
          src={Ingunghoatdong}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  const ActionGC = (
    <List
      min-width="100%"
      className="header-notifications-dropdown custom"
      itemLayout="horizontal"
      dataSource={dataActionGC}
      renderItem={(item, i) => (
        <List.Item
          style={{
            display:
              (dataAction.status === 1 && i === 1) ||
                (dataAction.status === 1 && i === 2) ||
                (dataAction.status === 1 && i === 3) ||
                (dataAction.status === 2 && i === 1) ||
                (dataAction.status === 2 && i === 2) ||
                (dataAction.status === 3 && i === 2) ||
                (dataAction.status === 3 && i === 3) ||
                (dataAction.status === 4 && i === 3) ||
                (dataAction.status === 5 && i === 1) ||
                (dataAction.status === 5 && i === 2) ||
                (dataAction.status === 5 && i === 3)
                ? "none"
                : "",
          }}
        >
          <List.Item.Meta
            avatar={item.avatar}
            title={<span style={{ fontWeight: 500 }}>{item.title}</span>}
          />
        </List.Item>
      )}
    />
  );

  const renderStatus = (status) => {
    switch (status) {
      case 2:
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "#069D27" }}
          >
            <img src={IDothoanthanh} width={10} style={{ marginRight: 10 }} />
            <div>Đang hoạt động</div>
          </div>
        );
      case 1:
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "#C0C0C0" }}
          >
            <img src={INhap} width={10} style={{ marginRight: 10 }} />
            <div>Nháp</div>
          </div>
        );
      case 3:
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "#191919" }}
          >
            <img src={INgung} width={10} style={{ marginRight: 10 }} />
            <div>Ngừng hoạt động</div>
          </div>
        );
      case 4:
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "#FFA21F" }}
          >
            <img src={Icho} width={10} style={{ marginRight: 10 }} />
            <div>Chờ kiểm duyệt</div>
          </div>
        );
      case 5:
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "#FF003D" }}
          >
            <img src={ITuchoi} width={10} style={{ marginRight: 10 }} />
            <div>Từ chối</div>
          </div>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setIndexPage(0);
    Back(1);
    setActionDetail("");
    getProducts();
  }, []);

  const project = [
    {
      title: "STT",
      render: (item, record, index) => (
        <div style={{ width: "30px", textAlign: "left" }}>
          {index + 1 + (formData.offset - 1) * 20}
        </div>
      ),
    },
    {
      title: "Công ty bảo hiểm",
      hidden: checkAccountGC ? false : true,
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {getTitleById(data?.provider_id)}
        </div>
      ),
    },
    {
      title: "Tên sản phẩm",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {data?.product_by_provider_title}
        </div>
      ),
    },
    {
      title: "Gói sản phẩm",
      render: (_, data) => (
        <div style={{ textTransform: "capitalize", textAlign: "left", minWidth: 100 }}>
          {data?.title}
        </div>
      ),
    },
    {
      title: "Ngày tạo",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {Helper._formatDay(data?.created_date)}
        </div>
      ),
    },
    {
      title: "Ngày cập nhật",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {data?.updated_date ? Helper._formatDay(data?.updated_date) : "-"}
        </div>
      ),
    },
    {
      title: "Trạng thái",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>{renderStatus(data.status)}</div>
      ),
    },
    {
      title: "Nội dung",
      with: "300px",
      render: (_, data) => (
        <div style={{ textAlign: "left", width: 350 }}>
          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            {
              uid !== data?.uid ?
                <Paragraph
                  style={{ margin: 0 }}
                  ellipsis={{
                    symbol: "Xem thêm",
                    rows: 2,
                    expandable: true,
                    onEllipsis: (ellipsis) => {
                      console.log('Ellipsis changed:', ellipsis);
                    },
                    onExpand: () => setUid(data?.uid),
                  }
                  }
                // title={`${article}--William Shakespeare`}
                >
                  {data?.reason_rejection || "-"}
                </Paragraph>
                :
                data?.reason_rejection || "-"
            }
          </div>
        </div>
      ),
    },
    {
      title: "Thao tác",
      fixed: "right",
      align: "center",
      render: (_, data) => (
        <div style={{ width: "50px" }}>
          <Dropdown
            overlay={checkAccountGC ? ActionGC : Action}
            trigger={["click"]}
          >
            <a className="ant-dropdown-link">
              <img
                onClick={() => setDataAction(data)}
                src={ThaotacA}
                style={{ width: 25 }}
              />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  const Active = (uid) => {
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/package/${uid}/active`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Kích hoạt thành công!");
          Getlist(formData);
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const Duplicate = (uid) => {
    setLoading(true);
    Service.apicall(
      "POST",
      `/api/v1/products-hub/health/portal/package/${uid}/duplicate`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Nhân bản thành công!");
          Getlist(formData);
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const Inactive = (uid) => {
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/package/${uid}/inactive`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Huỷ kích hoạt thành công!");
          Getlist(formData);
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const Delete = (uid) => {
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/package/${uid}/delete`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Xoá thành công!");
          Getlist(formData);
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Chuyển trạng thái sang hoạt động tất cả các gói
  const Approve = (uid) => {
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/package/${uid}/approve`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Hiển thị thành công!");
          Getlist(formData);
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Chuyển trạng thái chờ kiểm duyệt
  const waitingApprove = (uid) => {
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/package/${uid}/waiting-approve`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Hiển thị thành công!");
          Getlist(formData);
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getDetail = (uid) => {
    setLoading(true);
    Service.apicall("GET", `/api/v1/products-hub/health/portal/package/${uid}`)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          setDataUpdate({ ...res.data.result, uid: dataAction?.uid });
          setMainBenefits(res.data.result?.benefits);
          setPage(2);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const Getlist = (formData) => {
    setLoading(true);
    const data = new URLSearchParams({
      page: formData?.offset,
      limit: 20,
      search: formData?.search || "",
      status: formData?.status || "",
      provider_id: formData?.provider_id || "",
    });
    Service.apicall(
      "GET",
      `/api/v1/products-hub/health/portal/package?${data.toString()}`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          seDataOrders(res?.data?.result);
          setPageS(res?.data?.result?.pagination?.total);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getTitleById = (id) => {
    const result = provider.filter((item) => item.id === id);
    return result.length > 0 ? result[0].title : null; // Trả về title nếu tìm thấy, ngược lại trả về null
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  // Debounced tìm kiếm
  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      Getlist(formData);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [formData]);

  const handCancel = () => {
    setOpenConfirmActive(false);
    setOpenConfirmDelete(false);
    setOpenConfirmDisable(false);
  };

  const css = {
    input: {
      color: "#3A3A3A",
      zIndex: 1,
      fontSize: 12,
      boxShadow: "none",
      padding: "0px 10px",
      borderRadius: 4,
      width: "100%",
      border: "1px solid #d2d2d2",
    },
    titleInput: { color: "#646D69", marginBottom: 3 },
    FormItemsHolder: {
      width: "100%",
      borderRadius: 4,
      margin: "5px 0px",
      padding: 0,
    },
    button: {
      fontSize: 12,
      background: "#007185",
      color: "#fff",
      borderRadius: 4,
      height: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    activeTabs: {
      padding: "15px 24px",
      borderBottom: "3px solid #3961A0",
      width: "fit-content",
      color: "#3961A0",
      fontWeight: 600,
      cursor: "pointer",
    },
    defaultTabs: {
      padding: "15px 24px",
      width: "fit-content",
      cursor: "pointer",
    },
  };

  //xet chiều cao window
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {openConfirmDisable && (
        <MDConfirmDisable
          loading={loading}
          open={true}
          handleCancel={handCancel}
          inactive={() => Inactive(dataAction?.uid)}
        />
      )}
      {openConfirmActive && (
        <MDConfirmActive
          open={true}
          handleCancel={handCancel}
          active={() =>
            checkAccountGC
              ? Approve(dataAction?.uid)
              : waitingApprove(dataAction?.uid)
          }
          loading={loading}
        />
      )}
      {openConfirmDelete && (
        <MDConfirmDelete
          open={true}
          handleCancel={handCancel}
          delete={() => Delete(dataAction?.uid)}
          loading={loading}
        />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ marginBottom: 10, fontWeight: 600, fontSize: 20 }}>
          Sản phẩm
        </div>
        {(+roleManagement?.create?.product === 1 ||
          +roleManagement?.manager?.product === 1) && (
            <div
              onClick={() => setPage(2)}
              style={{
                padding: "7px 11px",
                background: "#0B7CE4",
                width: "fit-content",
                color: "#fff",
                borderRadius: 5,
                display: checkAccountGC ? "none" : "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src={Itaogoi}
                style={{ width: 18, marginRight: 11, fontWeight: 500 }}
              />
              Tạo gói sản phẩm
            </div>
          )}
      </div>
      <div>
        <Row gutter={[10, 0]}>
          {checkAccountGC && (
            <Col xl={6} md={12} xs={24}>
              <Form.Item
                name="provider_id"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
                style={{ ...css.FormItemsHolder, marginTop: 6 }}
              >
                <Select
                  allowClear={true}
                  className="custom-select"
                  placeholder={
                    <span style={{ color: "#343A40" }}>Công ty bảo hiểm</span>
                  }
                  onChange={(e) => {
                    onChangForm({ data: e, name: "provider_id" });
                  }}
                >
                  {provider.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xl={18} xs={24}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Vui lòng nhập đầy đủ thông tin.",
                },
              ]}
              style={{ ...css.FormItemsHolder, marginBottom: 10 }}
            >
              <Input
                allowClear
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "search" });
                }}
                className="custom-input"
                prefix={
                  <img
                    src={Itimkiem}
                    alt="Global Care icon"
                    width={14}
                    height={14}
                  />
                }
                style={{
                  ...css.input,
                  maxWidth: 375,
                  fontSize: 12,
                }}
                placeholder="Tìm kiếm..."
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div style={{ background: "#fff" }}>
        <Row gutter={[10, 0]}>
          <Col xs={24}>
            <div
              style={{
                borderBottom: "1px solid #DCDCDC",
                display: "flex",
                alignItems: "center",
              }}
            >
              {datatab.map((item) => (
                <div
                  onClick={() => {
                    setTabsActive(item.id);
                    onChangForm({ data: item.id, name: "status" });
                  }}
                  key={item.id}
                  style={
                    tabsActive === item.id ? css.activeTabs : css.defaultTabs
                  }
                >
                  {item.title}
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <div className="tabled2ß">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Table
                loading={loading}
                columns={project}
                dataSource={dataOrders?.data}
                pagination={false}
                // className="ant-border-space baocao"
                className="ant-border-space khuyenmai"
                // rowClassName={() => "rowClassName2"}
                bordered={false}
                scroll={
                  dataOrders?.data?.length > 0 ?
                    {
                      y: windowHeight > 500 ? "calc(100vh - 365px)" : "51vh",
                      x: 'auto'
                    } :
                    {
                      x: 'auto'
                    }
                }
                showHeader={true}
              />
              <div style={{ textAlign: "right", margin: "10px" }}>
                <Pagination
                  className="paginateCustom2"
                  total={pageS || 20}
                  pageSize={20}
                  showTotal={(total, range) => (
                    <span
                      style={{ color: "#3961A0" }}
                    >{`Hiển thị ${range[0]}-${range[1]} trong ${total}`}</span>
                  )}
                  defaultCurrent={1}
                  defaultPageSize={0}
                  // className="paginateCustom"
                  onChange={(e) => {
                    onChangForm({ data: e, name: "offset" });
                  }}
                  size="small"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Danhsachgoi;
