import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix, Row, Col } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import { Animated } from "react-animated-css";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  // const [sidenavType, setSidenavType] = useState("transparent");
  const [sidenavType, setSidenavType] = useState("#ffffff");
  // fixed cho header
  const [fixed, setFixed] = useState(true);
  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  return (
    <Layout
      className={`layout-dashboard ${
        pathname === "profile" ? "layout-profile" : ""
      } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      <Drawer
        title={false}
        placement={placement === "right" ? "left" : "right"}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key={placement === "right" ? "left" : "right"}
        width={250}
        className={`drawer-sidebar ${
          pathname === "rtl" ? "drawer-sidebar-rtl" : ""
        } `}
      >
        <Layout
          className={`layout-dashboard ${
            pathname === "rtl" ? "layout-dashboard-rtl" : ""
          }`}
        >
          <Sider
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${
              sidenavType === "#fff" ? "active-route" : ""
            }`}
            style={{ background: sidenavType }}
          >
            <Sidenav color={sidenavColor} />
          </Sider>
        </Layout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{
          background: sidenavType,
          boxShadow: "5px 5px 10px 0px #E9E8F180",
          borderRadius: 0,
        }}
      >
        <Animated
          animationIn="fadeInLeft"
          animationInDuration={600}
          isVisible={true}
        >
          <Sidenav color={sidenavColor} />
        </Animated>
      </Sider>
      <Layout>
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Animated
                animationIn="fadeInDown"
                animationInDuration={800}
                isVisible={true}
              >
                <Header
                  onPress={openDrawer}
                  name={pathname}
                  subName={pathname}
                  handleSidenavColor={handleSidenavColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                />
              </Animated>
            </AntHeader>
          </Affix>
        ) : (
          <>
            <Row gutter={[20, 0]}>
              <Col xs={0} md={0} lg={24}>
                <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                  <Header
                    onPress={openDrawer}
                    name={pathname}
                    subName={pathname}
                    handleSidenavColor={handleSidenavColor}
                    handleSidenavType={handleSidenavType}
                    handleFixedNavbar={handleFixedNavbar}
                  />
                </AntHeader>
              </Col>
              <Col xs={24} md={24} lg={0}>
                <div
                  style={{
                    background: "#fff",
                    marginBottom: 10,
                    paddingBottom: 10,
                    paddingRight: 10,
                  }}
                >
                  <Header
                    onPress={openDrawer}
                    name={pathname}
                    subName={pathname}
                    handleSidenavColor={handleSidenavColor}
                    handleSidenavType={handleSidenavType}
                    handleFixedNavbar={handleFixedNavbar}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
        <Animated
          animationIn="fadeInRight"
          animationInDuration={500}
          isVisible={true}
        >
          <Content className="content-ant">{children}</Content>
        </Animated>
        {/* <Footer /> */}
      </Layout>
    </Layout>
  );
}

export default Main;
