import React, { useContext } from "react";
import Helper from "./../../extra/helper";
import { AuthContext } from "./../../context/AuthProvider";

function ExportProviderTable(props) {
  const { provider } = useContext(AuthContext);
  const getTitleById = (id) => {
    const result = provider.filter((item) => item.id === id);
    return result.length > 0 ? result[0].title : null; // Trả về title nếu tìm thấy, ngược lại trả về null
  };
  return (
    <div style={{ display: "none" }}>
      <table
        className="collapse-border"
        id={"khuyen-mai"}
        data-cols-width={"10,40, 30, 30, 40, 20, 40, 40, 40,40, 40, 40, 40, 40,40, 40"}
        border={"1px solid red"}
      >
        <thead style={{ fontSize: "13" }}>
          <tr>
            <th
              // data-b-a-c="FFFFFFFF"
              colSpan={16}
              data-f-name={"Times new roman"}
              data-f-sz={"24"}
              data-a-wrap={true}
              data-a-h={"center"}
              // data-b-a-s={"thin"}
              data-f-bold={true}
            >
              CHƯƠNG TRÌNH KHUYẾN MẠI TRÊN WEB SO SÁNH CẦN TRUYỀN THÔNG
            </th>
          </tr>
          <tr>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              rowSpan={2}
              data-a-h={"center"}
            >
              STT
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              rowSpan={2}
              data-a-h={"center"}
            >
              Nhà bảo hiểm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              rowSpan={2}
              data-a-h={"center"}
            >
              Sản phẩm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              rowSpan={2}
              data-a-h={"center"}
            >
              Tên gói
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              rowSpan={2}
              data-a-h={"center"}
            >
              Chân dung khách hàng
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              colSpan={3}
              data-a-h={"center"}
            >
              Đối tượng áp dụng
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              rowSpan={2}
              data-a-h={"center"}
            >
              Loại giảm giá
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              colSpan={2}
              data-a-h={"center"}
            >
              Cho đơn hàng
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              colSpan={2}
              data-a-h={"center"}
            >
              Giới hạn của chương trình
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              colSpan={2}
              data-a-h={"center"}
            >
              Thời gian chạy chương trình
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
              rowSpan={2}
              data-a-h={"center"}
            >
              GHI CHÚ
            </th>
          </tr>

          <tr style={{ fontSize: "13" }}>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-a-h={"center"}
              data-f-bold={true}
            >
              Giới tính
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-a-h={"center"}
              data-f-bold={true}
            >
              Tuổi
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-a-h={"center"}
              data-f-bold={true}
            >
              khu vực
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-a-h={"center"}
              data-f-bold={true}
            >
              Tỷ lệ giảm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-a-h={"center"}
              data-f-bold={true}
            >
              Số tiền tối đa
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-a-h={"center"}
              data-f-bold={true}
            >
              Số lượt tối đa
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-a-h={"center"}
              data-f-bold={true}
            >
              Số tiền tối đa
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-a-h={"center"}
              data-f-bold={true}
            >
              Từ ngày
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-a-h={"center"}
              data-f-bold={true}
            >
              Đến ngày
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "13" }}>
          {props?.data?.map((item, index) => {
            return (
              <tr key={index} style={{ fontSize: "13" }}>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-a-h={"center"}
                >
                  {index + 1}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {getTitleById(item?.provider_id)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.pbp_name}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.package_name}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >

                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-a-h={"center"}
                >
                  {item?.gender === 1 ? "Nam" : item?.gender === 2 ? 'Nữ' : 'Tất cả'}
                </td>
                {
                  item?.gender === 3 &&
                  <td
                    data-f-name={"Times new roman"}
                    data-f-sz={"16"}
                    data-b-a-s={"thin"}
                    data-a-h={"center"}
                  >
                    Tất cả
                  </td>
                }
                {
                  item?.gender !== 3 &&
                  <td
                    data-f-name={"Times new roman"}
                    data-f-sz={"16"}
                    data-b-a-s={"thin"}
                    data-a-h={"center"}
                  >
                    {+item?.under_1_age === 364
                      ? "Dưới 1 tuổi - "
                      : item?.from_age === 0
                        ? "15 ngày tuổi - "
                        : item?.from_age + " - "}
                    {item?.under_1_age_extended === 364 ? 'Dưới 1 tuổi' : item?.under_1_age_extended === 0 ? '15 ngày tuổi' : item?.to_age + ' tuổi'}
                  </td>
                }
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >

                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-a-h={"center"}
                >
                  {item?.type === "tien" ? "Số tiền" : "%"}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-a-h={"center"}
                >
                  {Helper._formatMoney2(item?.value)}
                  {item?.type === "tien" ? " VNĐ" : "%"}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-a-h={"center"}
                >
                  {Helper._formatMoney2(item?.value_limit) + " VNĐ"}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-a-h={"center"}
                >
                  {item?.maximum_usage}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >

                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-a-h={"center"}
                >
                  {Helper._formatDay(item?.begin_date)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-a-h={"center"}
                >
                  {Helper._formatDay(item?.end_date)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.extra_info?.deny_reason}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(ExportProviderTable);
