import React, { useEffect, useState, useContext } from "react";
import { message } from "antd";
import "antd/dist/antd.css";
import Service from "./../../extra/getAPI";
import Danhsachkhuyenmai from "./danh-sach-khuyen-mai";
import Taokhuyenmai from "./tao-khuyen-mai";
import Capnhatkhuyenmai from "./cap-nhat-khuyen-mai";
import { AuthContext } from "./../../context/AuthProvider";
export const GlobalState = React.createContext();

function KhuyenMai() {
  const { setNamePage, indexPage, setIndexPage } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [benefits, setBenefits] = useState([]);
  // Loại sản phẩm
  const [productType, setProductType] = useState([]);
  const [productsByProvider, setProductsByProvider] = useState([]);
  const [packages, setPackages] = useState([]);
  const [benefitsDetail, setBenefitsDetail] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(false);
  const [dataUpdate, setDataUpdate] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setNamePage("Khuyến mãi");
    getProducts();
    setIndexPage(0);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [indexPage === 1]);

  // Lấy danh sách sản phẩm
  const getProducts = () => {
    Service.apicall("GET", `/api/v1/discount/products`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProductType(res.data.result);
        } else {
          setProductType([]);
        }
      })
      .catch((err) => {
        setProductType([]);
      });
  };

  // Lấy danh sách sản phẩm
  const getProductsByProvider = (productTypeId) => {
    Service.apicall(
      "GET",
      `/api/v1/discount/products/${productTypeId}/product-by-provider`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProductsByProvider(res.data.result);
        } else {
          setProductsByProvider([]);
        }
      })
      .catch((err) => {
        setProductsByProvider([]);
      });
  };

  // Lấy danh sách gói sản phẩm
  const getPackages = (productId, id) => {
    Service.apicall(
      "GET",
      `/api/v1/discount/products/${productId}/product-by-provider/${id}/packages`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setPackages(res?.data?.result);
        } else {
          setPackages([]);
        }
      })
      .catch((err) => {
        setPackages([]);
      });
  };

  // Lấy danh sách chi tiết gói chương trình
  const getBenefitsDetail = (product_id, uid) => {
    setLoadingIndex(true);
    Service.apicall(
      "GET",
      `/api/v1/discount/${product_id}/packages/${uid}/benefits/detail`
    )
      .then((res) => {
        setLoadingIndex(false);
        if (res.data.statusCode === 200) {
          setBenefitsDetail([...benefitsDetail, ...res?.data?.result]);
        } else {
          setBenefitsDetail([]);
        }
      })
      .catch((err) => {
        setLoadingIndex(false);
        setBenefitsDetail([]);
      });
  };

  const PageView = () => {
    switch (page) {
      case 1:
        return <Danhsachkhuyenmai />;
      case 2:
        return <Taokhuyenmai />;
      case 3:
        return <Capnhatkhuyenmai />;
    }
  };

  const Back = (page) => {
    setPage(page);
    setBenefits([]);
    setPackages([]);
    setBenefitsDetail([]);
  };

  const handleBenefits = (action, index, newValue) => {
    const updatedItems = [...benefits];
    switch (action) {
      case "add":
        updatedItems.push(newValue);
        break;
      case "edit":
        updatedItems[index] = newValue;
        setTimeout(() => {
          checkAge(updatedItems, index, updatedItems[index]);
        }, 100);
        break;
      case "delete":
        updatedItems.splice(index, 1);
        break;
      default:
        break;
    }
    setBenefits(updatedItems);
  };
  
  // Kiểm tra khoản độ tuổ có hợp lệ hay không
  const checkAge = (detail, index, updatedData) => {
    const newUpadte = { ...updatedData };
    if (
      newUpadte?.gender &&
      newUpadte.from_age !== null &&
      newUpadte.from_age !== undefined &&
      newUpadte.to_age !== null &&
      newUpadte.to_age !== undefined
    ) {
      // if (newUpadte.from_age === 364) {
      //   newUpadte.from_age = 0;
      // }
      // const newDetail = detail.map((item) => {
      //   if (item?.from_age === 364) {
      //     return { ...item, from_age: 0 };
      //   }
      //   return item;
      // });
      const filterGender = detail.filter(
        (item, i) => item?.gender === newUpadte?.gender && i !== index
      );
      // từ tuổi đến tuổi ko được = nhau
      if (
        newUpadte.from_age > newUpadte.to_age &&
        newUpadte.from_age > 0 &&
        newUpadte.to_age > 0
      ) {
        message.error("Phân loại tuổi chưa hợp lệ!");
        handleBenefits("edit", index, {
          gender: newUpadte.gender,
          from_age: null,
          to_age: null,
        });
        return;
      }
      filterGender.map((item) => {
        if (
          (newUpadte.to_age < item.from_age &&
            newUpadte.from_age < item.to_age) ||
          (newUpadte.to_age > item.from_age && newUpadte.from_age > item.to_age)
        ) {
          //console.log('Khoản tuổi đúng')
        } else {
          message.error("Phân loại tuổi chưa hợp lệ!");
          handleBenefits("edit", index, {
            gender: newUpadte.gender,
            from_age: null,
            to_age: null,
          });
        }
      });
    }
  };

  // Thông báo lỗi dủng chung cho các page
  const MessageErr = (content) => {
    message.error(
      {
        content: content,
      },
      15
    );
  };

  // Thông báo lỗi dủng chung cho các page
  const MessageWarning = (content) => {
    message.warning(
      {
        content: content,
      },
      15
    );
  };

  // Thông báo thành công dủng chung cho các page
  const MessageSucc = (content) => {
    message.success(
      {
        content: content,
      },
      15
    );
  };

  return (
    <GlobalState.Provider
      value={{
        setPage,
        page,
        Back,
        handleBenefits,
        benefits,
        setBenefits,
        productType,
        getProductsByProvider,
        productsByProvider,
        getPackages,
        packages,
        getProducts,
        getBenefitsDetail,
        benefitsDetail,
        setBenefitsDetail,
        setPackages,
        loadingIndex,
        dataUpdate,
        setDataUpdate,
        MessageErr,
        MessageWarning,
        MessageSucc,
      }}
    >
      <div>{PageView()}</div>
    </GlobalState.Provider>
  );
}

export default KhuyenMai;
