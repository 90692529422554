import {
  Switch,
  Route,
  Redirect,
  Router,
  BrowserRouter,
} from "react-router-dom";
import { message } from "antd";
import "antd/dist/antd.css";
import React, { useEffect } from "react";
import AuthProvider from "./context/AuthProvider";
import Thongtintaikhoan from "./pages/tai-khoan/thong-tin-tai-khoan";
import Doimatkhau from "./pages/tai-khoan/doi-mat-khau";
import KhuyenMai from "./pages/khuyen-mai/index";
import DSSuckhoevang from "./pages/san-pham/suc-khoe-vang";
import DSDonhang from "./pages/quan-ly-don-hang";
import SignIn from "./pages/dang-nhap/dang-nhap-va-quen-mat-khau";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import History from "./routes/history";

function App() {
  const conect = window.navigator.onLine;
  useEffect(() => {
    const login = localStorage.getItem("login");
    if (!login) {
      History.push("/sign-in");
    }else{
      // History.push("/san-pham/suc-khoe-vang");
    }
  }, []);

  useEffect(() => {
    if(!conect){
      message.error('Không có kết nối Internet. Vui lòng kiểm tra lại!');
      // History.push("/sign-in");
    }
  }, [conect]);

  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Router history={History}>
            <Switch>
              <Route exact path="/sign-in" component={SignIn} />
              <Main>
                <Route exact path="/san-pham/suc-khoe" component={DSSuckhoevang} />
                <Route exact path="/khuyen-mai" component={KhuyenMai} />
                <Route exact path="/thong-tin-tai-khoan" component={Thongtintaikhoan} />
                <Route exact path="/doi-mat-khau" component={Doimatkhau} />
                <Route exact path="/quan-ly-don" component={DSDonhang} />
                {/* <Redirect from="*" to="/sign-in" /> */}
              </Main>
            </Switch>
          </Router>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
