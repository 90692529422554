import React, { useEffect, useState, useContext } from "react";
import { Button, Modal} from "antd";
import { AuthContext } from "./../../../../context/AuthProvider";
import Warning from "./../../../../assets/images/salesManager/Warning.png";

export default function MDConfirmActive(props) {
  const { profile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const Send = () => {
    props.active();
    // props.handleCancel();
  };

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom"
        visible={props?.open}
        centered={true}
        closable={false}
        // onCancel={props?.handleCancel}
        style={{ borderRadius: 10, maxWidth: "370px" }}
      >
        <div style={{ textAlign: "center", fontSize: 15 }}>
          <div>
            <img src={Warning} style={{ width: 60 }} />
          </div>
          <div style={{ margin: "10px 0px", fontSize: 14 }}>
            Sản phẩm sẽ được cung cấp đến người dùng và không thể chỉnh sửa các
            nội dung liên quan đến phí bảo hiểm, nội dung về câu chữ thì có thể
            điều chỉnh. Trường hợp bạn muốn chỉnh sửa sẽ cần khoá sản phẩm/ gói
            này lại để tạo sản phẩm/ gói mới
          </div>
          <div>
            <b>Bạn có chắc chắn muốn hiển thị ngay không?</b>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 15,
          }}
        >
          <>
            <Button
              onClick={() => props?.handleCancel()}
              type="primary"
              style={{
                background: "#fff",
                fontSize: 14,
                border: "1px solid #0B7CE4",
                fontWeight: 600,
                width: 80,
                color: "#0B7CE4",
                marginRight: 10
              }}
            >
              Quay lại
            </Button>
            <Button
            loading={props.loading}
              onClick={() => Send()}
              type="primary"
              style={{
                background: "#0B7CE4",
                fontSize: 14,
                border: "1px solid #0B7CE4",
                fontWeight: 600,
                minWidth: 80,
              }}
            >
              Đồng ý
            </Button>
          </>
        </div>
      </Modal>
    </>
  );
}
