import React, { useState, useContext, useEffect, useRef } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Row, Col, Form, Button, Input, Avatar, message, Select } from "antd";
import Service from "./../../extra/getAPI";
import { AuthContext } from "./../../context/AuthProvider";
import AvatarDefaut from "./../../assets/images/salesManager/avarta-defaut.png";
import History from "./../../routes/history";
import "antd/dist/antd.css";

export default function Taikhoan() {
  const { MesSucess, setProfileGlobal, checkAccountGC } = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { Option } = Select;
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [openNewPass, setOpenNewPass] = useState(false);
  const [levelUser, setLevelUser] = useState(null);
  const [file, setFile] = useState(null);
  const [linkAvatar, setLinkAvatar] = useState();
  const importInputRef = useRef();

  useEffect(() => {
    GetProfile();
  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        UpdateProfile(values);
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  const UpdateProfile = (values) => {
    setLoading(true);
    const data = {
      avatar: linkAvatar || "",
      provider_id: values?.provider_id,
      fullname: values?.fullname,
      phone_number: values?.phone_number,
      email: values?.email,
      extra_info: {
        address: values?.address,
        taxNumber: values?.taxNumber,
        position: values?.position,
        bussinessName: values?.bussinessName
      },
    };
    Service.apicall("PUT", `/api/v1/user/portal`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setLoading(false);
          GetProfile();
          message.success("Cập nhật tài khoản thành công!");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetProfile = () => {
    setLoading(true);
    Service.apicall("GET", `/api/v1/user/portal`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          const result = res.data.result;
          setProfile(result);
          setProfileGlobal(result);
          setLinkAvatar(result?.avatar);
          form.setFieldsValue({
            fullname: result?.fullname,
            phone_number: result?.phone_number,
            email: result?.email,
            taxNumber: result?.extra_info?.taxNumber,
            address: result?.extra_info?.address,
            position: result?.extra_info?.position,
            username: result?.username,
            bussinessName: result?.extra_info?.bussinessName,
            provider_id: result?.provider_id
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setOpenNewPass(false);
  };

  const handleImportFile = (files) => {
    if (files && files[0]) {
      if (files[0]?.size > 307200) {
        message.error("Dung lượng file lớn hơn 300kb!");
        return;
      }
      setFile(URL.createObjectURL(files[0]));
      uploadG2(files[0]);
    }
  };

  const uploadG2 = (file) => {
    setLoading(true);
    const storage = "s3";
    var form = new FormData();
    form.append("storage", storage);
    form.append("file", file);
    form.append("path", `/products/images/`);
    Service.apicall_upload_G2("POST", `/upload`, form)
      .then((res) => {
        setLoading(false);
        const data = res.data;
        if (data.complete) {
          setLinkAvatar(data?.link);
        } else {
          message.error("Có lỗi xảy ra. Vui lòng chụp lại ảnh!");
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("Có lỗi xảy ra. Vui lòng chụp lại ảnh!");
      });
  };

  return (
    <>
      <div
        style={{
          marginBottom: 20,
          fontSize: 20,
          fontWeight: 600,
          color: "#191919",
        }}
      >
        Thông tin tài khoản
      </div>
      <Row
        className="FormThongtin"
        style={{ overflow: "hidden", maxWidth: 900 }}
      >
        <Form
          layout={"vertical"}
          ref={formRef}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 2,
              color: "#4B4B4B",
            }}
          >
            <Row gutter={[60, 0]}>
              <Col xl={24}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Thông tin cơ bản
                </div>
                <div>Logo công ty bảo hiểm</div>
              </Col>
              <Col xl={24}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <Avatar
                      shape="square"
                      size={80}
                      style={{
                        // backgroundColor: "#87d068",
                        width: 80,
                        height: 80
                      }}
                      src={linkAvatar || <img src={AvatarDefaut} width={80} />}
                    />
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <div style={{ fontSize: 11 }}>
                      Dụng lượng file tối đa 300kb
                      <br />
                      Định dạng:.JPEG, .PNG
                      <br />
                      Hình ảnh tỉ lệ 1:1
                      <br />
                    </div>
                    <div
                      onClick={() =>
                      // message.error("Chức năng đang được cập nhật");
                      {
                        importInputRef.current.click();
                      }
                      }
                      style={{
                        fontSize: 11,
                        marginTop: 5,
                        background: "#E3F3FF",
                        width: "fit-content",
                        borderRadius: 5,
                        padding: "7px 9px",
                        color: "#0B7CE4",
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      Chọn ảnh
                    </div>
                  </div>
                </div>
                <hr className="gradient-line" />
              </Col>
              <Col xl={24} xs={24}>
                <Row gutter={[10]}>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Tên doanh nghiệp
                        </span>
                      }
                      name="bussinessName"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input allowClear placeholder="Nhập tên doanh nghiệp" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Tên đăng nhập</span>
                      }
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input allowClear disabled placeholder="Tên đăng nhập" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={<span style={{ fontWeight: 400 }}>Địa chỉ</span>}
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input allowClear placeholder="Địa chỉ" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Mã số thuế</span>
                      }
                      name="taxNumber"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input allowClear placeholder="Nhập mã số thuế" />
                    </Form.Item>
                  </Col>
                  {
                    !checkAccountGC &&
                    <Col xs={24} xl={24}>
                      <Form.Item
                        className="username"
                        name="provider_id"
                        style={{ marginBottom: 10 }}
                        label={
                          <span style={{ fontWeight: 400 }}>
                            Thuộc công ty bảo hiểm
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn thông tin",
                          },
                        ]}
                      >
                        <Select
                          // allowClear={true}
                          disabled
                          className="custom-select2"
                          placeholder={<span>Chọn công ty bảo hiểm</span>}
                        >
                          {/* {/* <Option value={1}>Khuyến mãi</Option> */}
                          {
                            profile?.provider?.map((item, i) => (
                              <Option key={i + 1} value={item?.id}>{item?.title}</Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          </div>
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 2,
              color: "#4B4B4B",
              marginTop: 20,
            }}
          >
            <Row gutter={[60, 0]}>
              <Col xs={24}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Người đại diện
                </div>
              </Col>
              <Col xl={24} xs={24}>
                <Row gutter={[10]}>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={<span style={{ fontWeight: 400 }}>Họ và tên</span>}
                      name="fullname"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input allowClear placeholder="Nhập họ tên" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={<span style={{ fontWeight: 400 }}>Chức vụ</span>}
                      name="position"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input allowClear placeholder="Nhập chức vụ" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Số điện thoại</span>
                      }
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                        {
                          message:
                            "Vui lòng nhập SĐT định dạng số, không bao gồm ký tự đặc biệt.",
                          pattern: /((09|03|07|08|05)+([0-9]{8})\b)/,
                        },
                      ]}
                    >
                      <Input allowClear type="tel" placeholder="Nhập số điện thoại" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={<span style={{ fontWeight: 400 }}>Email</span>}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                        {
                          message: "Email không hợp lệ",
                          pattern: /\S+@\S+\.\S+/,
                        },
                      ]}
                    >
                      <Input allowClear placeholder="Nhập Email" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <Row>
              <Col xs={2} xl={0}></Col>
              <Col xs={20} xl={24} style={{ width: "90%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 20,
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => History.goBack()}
                    style={{
                      background: "#ffffff",
                      color: "#0B7CE4",
                      width: "90px",
                      textAlign: "center",
                      borderRadius: 6,
                      border: "1px solid #0B7CE4",
                      fontWeight: "500",
                      height: 40,
                    }}
                  >
                    <b>Bỏ qua</b>
                  </Button>
                  <Button
                    loading={loading}
                    htmlType="submit"
                    style={{
                      background: "#0B7CE4",
                      color: "#ffffff",
                      textAlign: "center",
                      borderRadius: 6,
                      border: "1px solid #0B7CE4",
                      fontWeight: 500,
                      height: 40,
                    }}
                  >
                    <b>Cập nhật</b>
                  </Button>
                </div>
              </Col>
              <Col xs={2} xl={0}>
                {" "}
              </Col>
            </Row>
          </div>
        </Form>
      </Row>
      <input
        ref={importInputRef}
        //capture="camera"
        type="file"
        onClick={(e) => {
          e.target.value = "";
        }}
        accept="image/png, image/jpeg"
        onChange={(e) => {
          handleImportFile(e.target.files);
        }}
        style={{ display: "none" }}
      />
    </>
  );
}
