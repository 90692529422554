import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Form,
  Input,
  Select,
  DatePicker,
  message,
  List,
  Dropdown,
} from "antd";
import Service from "./../../extra/getAPI";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../../context/AuthProvider";
import { GlobalState } from "./index";
import Helper from "../../extra/helper";
import { exportTableToExcel } from "./../../extra/export";
import ThaotacA from "./../../assets/images/salesManager/thaotacA.png";
import Export from "./../../components/table-excel/excel-quan-ly-don";
import Itimkiem from "./../../assets/images/itimkiem.png";
import Idowload from "./../../assets/images/salesManager/idowload.png";
import moment from "moment";

function Donhang() {
  const { profile, checkAccountGC, setIndexPage, roleManagement, provider } =
    useContext(AuthContext);
  const {
    setPage,
    renderStatus,
    product,
    getProductsByProvider,
    productsByProvider,
    setDetail,
    setProductsByProvider,
  } = useContext(GlobalState);

  const [dataOrders, seDataOrders] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [limit, setLimit] = useState(0);
  const [pageS, setPageS] = useState(0);
  const [listUser, setListUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [formData, setFormData] = useState({
    offset: 1,
    createdFrom: moment(moment().subtract(1, "month"))
      .startOf("month")
      .format("YYYY-MM-DD"),
    createdTo: moment(moment().subtract(0, "month"))
      .startOf("month")
      .format("YYYY-MM-DD"),
  });
  const [tabsActive, setTabsActive] = useState("");
  const [form] = Form.useForm();
  const formRef = React.createRef();
  // const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [dates, setDates] = useState(null);
  const rolesUser = profile?.extra_info?.roles;
  const datatab = checkAccountGC
    ? [
      { id: "", title: "Tất cả" },
      { id: 1, title: "Chưa thanh toán" },
      { id: 8, title: "Chờ thẩm định" },
      { id: 2, title: "Chờ GCN" },
      { id: 3, title: "Hoàn thành" },
      { id: 4, title: "Đơn huỷ" },
    ]
    : [
      { id: "", title: "Tất cả" },
      { id: 8, title: "Chờ thẩm định" },
      { id: 2, title: "Chờ GCN" },
      { id: 3, title: "Hoàn thành" },
      { id: 4, title: "Đơn huỷ" },
    ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setIndexPage(0);
  }, []);

  const project = [
    {
      title: "Mã đơn",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>{data?.code}</div>
      ),
    },
    {
      title: "Công ty bảo hiểm",
      hidden: checkAccountGC ? false : true,
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {getTitleById(data?.provider_id)}
          {/* {data?.product_by_provider_title} */}
        </div>
      ),
    },
    {
      title: "Chủ hợp đồng",
      render: (_, data) => (
        <div style={{ textTransform: "capitalize", textAlign: "left" }}>
          {data?.buyer_name}
        </div>
      ),
    },
    {
      title: "Người được bảo hiểm",
      render: (_, data) => (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "left",
            minWidth: 150,
          }}
        >
          {data?.insured_persons?.insuredName}
        </div>
      ),
    },
    {
      title: "Số điện thoại",
      render: (_, data) => <div>{data?.insured_persons?.insuredPhone}</div>,
    },
    {
      title: "Tên Sản phẩm",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {data?.product_by_provider_title || data?.product_title}
        </div>
      ),
    },
    {
      title: "Gói sản phẩm",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {data?.packages?.[0]?.title || data?.packages?.title}
        </div>
      ),
    },
    {
      title: "Khuyến mãi",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {Helper._formatMoney2(data?.discount_amount)}
        </div>
      ),
    },
    {
      title: "Phí bảo hiểm",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {Helper._formatMoney2(data?.fees)}
        </div>
      ),
    },
    {
      title: "Ngày tạo",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {Helper._formatDay(data.created_date)}
        </div>
      ),
    },
    {
      title: "Mã CTV",
      hidden: checkAccountGC ? false : true,
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {data?.extra_info?.referalCode || "-"}
        </div>
      ),
    },
    {
      title: "Tên CTV",
      hidden: checkAccountGC ? false : true,
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {data?.extra_info?.referalCode ? data?.user_full_name : "-" || "-"}
        </div>
      ),
    },
    {
      title: "Trạng thái",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {renderStatus(data?.status)}
        </div>
      ),
    },
    {
      title: "Chi tiết",
      fixed: "right",
      align: "center",
      render: (_, data) => (
        <div style={{ width: "50px" }}>
          <img
            onClick={() => {
              GetDetail(data.uid);
            }}
            src={ThaotacA}
            style={{ width: 25, cursor: "pointer" }}
          />
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  const GetList = () => {
    setLoading(true);
    const data = new URLSearchParams({
      page: formData?.offset || 1,
      limit: 20,
      createdFrom: formData?.createdFrom
        ? moment(formData?.createdFrom).startOf("month").format("YYYY-MM-DD")
        : "",
      createdTo: formData?.createdTo
        ? moment(formData?.createdTo).endOf("month").format("YYYY-MM-DD")
        : "",
      productId: formData?.productId || "",
      pdpId: formData?.pdpId || "",
      status: formData?.status || "",
      provider_id: formData?.provider_id || "",
      search: formData?.search || "",
    });
    Service.apicall(
      "GET",
      `/api/v1/products-hub/health/portal/order?${data.toString()}`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          seDataOrders(res?.data?.result?.data);
          setLimit(res?.data?.result?.total);
          setPageS(res?.data?.result?.total);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetDataExport = (dataInput, limit) => {
    setLoadingExport(true);
    const data = new URLSearchParams({
      page: dataInput?.offset || 1,
      limit: limit,
      createdFrom: dataInput?.createdFrom
        ? moment(dataInput?.createdFrom).startOf("month").format("YYYY-MM-DD")
        : "",
      createdTo: dataInput?.createdTo
        ? moment(dataInput?.createdTo).endOf("month").format("YYYY-MM-DD")
        : "",
      productId: dataInput?.productId || "",
      pdpId: dataInput?.pdpId || "",
      status: dataInput?.status || "",
    });
    Service.apicall(
      "GET",
      `/api/v1/products-hub/health/portal/order?${data.toString()}`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDataExport(res?.data?.result?.data);
          exportFile();
          message.success("File đang được xử lý. Vui lòng chờ trong giây lát!");
        }
        setLoadingExport(false);
      })
      .catch((err) => {
        setLoadingExport(false);
      });
  };

  const GetDetail = (uid) => {
    setLoading(true);
    Service.apicall("GET", `/api/v1/products-hub/health/portal/order/${uid}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDetail(res?.data?.result);
          setPage(2);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getTitleById = (id) => {
    const result = provider.filter((item) => item.id === id);
    return result.length > 0 ? result[0].title : null; // Trả về title nếu tìm thấy, ngược lại trả về null
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  // Debounced tìm kiếm
  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      GetList(formData);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [formData]);

  const exportFile = async () => {
    setTimeout(() => {
      exportTableToExcel("report", `Quan-ly-đơn.xlsx`);
    }, 1000);
  };

  const css = {
    input: {
      color: "#3A3A3A",
      zIndex: 1,
      fontSize: 12,
      boxShadow: "none",
      padding: "0px 10px",
      borderRadius: 4,
      width: "100%",
      border: "1px solid #d2d2d2",
    },
    titleInput: { color: "#646D69", marginBottom: 3 },
    FormItemsHolder: {
      width: "100%",
      borderRadius: 4,
      margin: "5px 0px",
      padding: 0,
    },
    button: {
      fontSize: 12,
      background: "#007185",
      color: "#fff",
      borderRadius: 4,
      height: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    activeTabs: {
      padding: "15px 24px",
      borderBottom: "3px solid #3961A0",
      width: "fit-content",
      color: "#3961A0",
      fontWeight: 600,
      cursor: "pointer",
    },
    defaultTabs: {
      padding: "15px 24px",
      width: "fit-content",
      cursor: "pointer",
    },
  };

  //xet chiều cao window
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Export data={dataExport} acountGC={checkAccountGC} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: 14,
        }}
      >
        <div style={{ marginBottom: 10, fontWeight: 600, fontSize: 20 }}>
          Quản lý đơn
        </div>
      </div>
      <div>
        <Form
          layout={"vertical"}
          ref={formRef}
          scrollToFirstError
          form={form}
          // onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={[10, 0]}>
            <Col
              xl={24}
              xs={24}
              style={{
                background: "#fff",
                padding: "10px 20px",
                marginBottom: 10,
              }}
            >
              <Row style={{ alignItems: "center" }}>
                <Col xl={20} xs={24}>
                  <b> Chọn khung thời gian: Từ</b>{" "}
                  <DatePicker
                    placeholder="Chọn tháng"
                    format={"MM.YYYY"}
                    style={{ borderRadius: 6 }}
                    defaultValue={moment().subtract(1, "month")}
                    onChange={(e) => {
                      onChangForm({ data: e, name: "createdFrom" });
                    }}
                    picker="month"
                    monthCellRender={(date) => {
                      const month = moment(date).format("MM");
                      return <div className="pickerCustom"> Tháng {month}</div>;
                    }}
                  />{" "}
                  <b>Đến</b>{" "}
                  <DatePicker
                    placeholder="Chọn tháng"
                    format={"MM.YYYY"}
                    style={{ borderRadius: 6 }}
                    defaultValue={moment().subtract(0, "month")}
                    onChange={(e) => {
                      onChangForm({ data: e, name: "createdTo" });
                    }}
                    picker="month"
                    monthCellRender={(date) => {
                      const month = moment(date).format("MM");
                      return <div className="pickerCustom"> Tháng {month}</div>;
                    }}
                  />
                </Col>
                {(+roleManagement?.report?.order === 1 ||
                  +roleManagement?.manager?.order === 1) && (
                    <Col xl={4} xs={0}>
                      <div
                        style={{ justifyContent: "flex-end", display: "flex" }}
                      >
                        <Button
                          onClick={() => GetDataExport(formData, limit)}
                          disabled={loading || loadingExport}
                          loading={loadingExport}
                          style={{
                            padding: "0px 20px",
                            borderRadius: 5,
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            fontWeight: 500,
                            height: 32,
                          }}
                        >
                          <img
                            src={Idowload}
                            style={{
                              width: 13,
                              marginRight: 5,
                              display: loadingExport ? "none" : "",
                            }}
                          />
                          Xuất file
                        </Button>
                      </div>
                    </Col>
                  )}
              </Row>
            </Col>
            <Col xl={5} md={12} xs={24}>
              <Form.Item
                name="productId"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
                style={{ ...css.FormItemsHolder, marginTop: 6 }}
              >
                <Select
                  allowClear={true}
                  className="custom-select"
                  placeholder={
                    <span style={{ color: "#343A40" }}>Loại bảo hiểm</span>
                  }
                  onClear={() => {
                    onChangForm({ data: "", name: "pdpId" });
                    setFormData({ ...formData, pdpId: "", productId: "" });
                  }}
                  onChange={(e) => {
                    form.resetFields(["pdpId"]);
                    if (e) {
                      onChangForm({ data: e, name: "productId" });
                      getProductsByProvider(e);
                    }
                  }}
                >
                  {product.map((item) => (
                    <Option key={item?.id} value={item?.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {checkAccountGC && (
              <Col xl={5} md={12} xs={24}>
                <Form.Item
                  name="provider_id"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                  style={{ ...css.FormItemsHolder, marginTop: 6 }}
                >
                  <Select
                    allowClear={true}
                    className="custom-select"
                    placeholder={
                      <span style={{ color: "#343A40" }}>Công ty bảo hiểm</span>
                    }
                    onChange={(e) => {
                      onChangForm({ data: e, name: "provider_id" });
                    }}
                  >
                    {provider.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xl={5} md={12} xs={24}>
              <Form.Item
                name="pdpId"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
                style={{ ...css.FormItemsHolder, marginTop: 6 }}
              >
                <Select
                  allowClear={true}
                  className="custom-select"
                  placeholder={
                    <span style={{ color: "#343A40" }}>Sản phẩm</span>
                  }
                  onChange={(e) => {
                    onChangForm({ data: e, name: "pdpId" });
                  }}
                >
                  {productsByProvider.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.product_title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={9} md={12} xs={24}>
              <Form.Item
                name="search"
                rules={[
                  {
                    required: false,
                    message: "Vui lòng nhập đầy đủ thông tin.",
                  },
                ]}
                style={{ ...css.FormItemsHolder }}
              >
                <Input
                  allowClear
                  onChange={(e) =>
                    onChangForm({ data: e.target.value, name: "search" })
                  }
                  className="custom-input"
                  prefix={
                    <img
                      src={Itimkiem}
                      alt="Global Care icon"
                      width={14}
                      height={14}
                    />
                  }
                  style={{
                    ...css.input,
                    maxWidth: 375,
                    fontSize: 12,
                  }}
                  placeholder="Tìm kiếm..."
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ background: "#fff", marginTop: 10 }}>
        <Row gutter={[10, 0]}>
          <Col xs={24}>
            <div
              style={{
                borderBottom: "1px solid #DCDCDC",
                display: "flex",
                alignItems: "center",
              }}
            >
              {datatab.map((item) => (
                <div
                  onClick={() => {
                    setTabsActive(item.id);
                    onChangForm({ data: item.id, name: "status" });
                  }}
                  key={item.id}
                  style={
                    tabsActive === item.id
                      ? { ...css.activeTabs }
                      : { ...css.defaultTabs }
                  }
                >
                  {item.title}
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <div className="tabled2">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Table
                loading={loading}
                columns={project}
                dataSource={dataOrders}
                pagination={false}
                className="ant-border-spaces khuyenmai"
                bordered={false}
                scroll={
                  dataOrders?.length > 0?
                  {
                    x: 'auto',
                    y: windowHeight > 500 ? "calc(100vh - 428px)" : "51vh",
                  }:
                  {
                    x: 'auto'
                  }
                }
                showHeader={true}
              />
              <div style={{ textAlign: "right", margin: "10px" }}>
                <Pagination
                  className="paginateCustom2"
                  total={pageS || 20}
                  pageSize={20}
                  showTotal={(total, range) => (
                    <span
                      style={{ color: "#3961A0" }}
                    >{`Hiển thị ${range[0]}-${range[1]} trong ${total}`}</span>
                  )}
                  defaultCurrent={1}
                  defaultPageSize={0}
                  // className="paginateCustom"
                  onChange={(e) => {
                    onChangForm({ data: e, name: "offset" });
                  }}
                  size="small"
                // scroll={{
                //   y: 240,
                // }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Donhang;
