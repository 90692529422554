import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form, Input, message } from "antd";
import Helper from "./../../../../extra/helper"
import { GlobalState } from "./../../../../pages/san-pham/suc-khoe-vang/index";

function MDAddLinkDocument(props) {
  const [loading, setLoading] = useState(false);
  const { documentaion,setdocumentaion } = useContext(GlobalState);
  const [form] = Form.useForm();
  const formRef = React.createRef();

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        setdocumentaion({ ...documentaion, [props?.keyNameFile]: [values.link] });
        props.handleCancel();
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom"
        visible={props?.open}
        centered={true}
        closable={false}
        onCancel={props?.handleCancel}
        style={{ borderRadius: 10, maxWidth: "350px" }}
      >
        <Form
          ref={formRef}
          scrollToFirstError
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            className="username"
            style={{ marginBottom: 10 }}
            label={<span style={{ fontWeight: 400 }}>Dán link URL</span>}
            name="link"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập đầy đủ thông tin.",
              },
            ]}
          >
            <Input
              allowClear
              placeholder="Nhập link tài liệu"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                background: "#3961A0",
                fontSize: 14,
                border: "1px solid #3961A0",
                fontWeight: 600,
                // width: 80,
                borderRadius: 5,
              }}
            >
              {props?.data ? 'Cập nhật' : 'Thêm'}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default MDAddLinkDocument;
