import { useState, useEffect, useContext } from "react";
import { Row, Col, Badge, Dropdown, Button, List, Avatar } from "antd";
import Ithongtin from "../../assets/images/salesManager/ithongtin.png";
import IDoimatkhau from "../../assets/images/salesManager/idoimatkhau.png";
import IDangxuat from "../../assets/images/salesManager/idangxuat.png";

import Idangxuat from "../../assets/images/idangxuat.png";
import Toggler from "../../assets/images/toggler.png";
import DefaultUser from "../../assets/images/defaultUser.svg";
import Iback from "../../assets/images/Iback.png";
import Logo from "../../assets/images/salesManager/logoGc.png";
import Iselect from "../../assets/images/salesManager/iconUser.png";
import Service from "./../../extra/getAPI";
import { AuthContext } from "./../../context/AuthProvider";
import History from "./../../routes/history";

const data = [
  {
    title: (
      <div
        onClick={() => History.push("/thong-tin-tai-khoan")}
        style={{ cursor: "pointer" }}
      >
        Thông tin tài khoản
      </div>
    ),
    avatar: (
      <img
        onClick={() => History.push("/thong-tin-tai-khoan")}
        src={Ithongtin}
        style={{ width: 23, cursor: "pointer" }}
      />
    ),
  },
  {
    title: (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          History.push("/doi-mat-khau");
        }}
      >
        Đổi mật khẩu
      </div>
    ),
    avatar: (
      <img
        onClick={() => History.push("/doi-mat-khau")}
        src={IDoimatkhau}
        style={{ width: 23, cursor: "pointer" }}
      />
    ),
  },
  {
    title: (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          Logout();
        }}
      >
        Đăng xuất
      </div>
    ),
    avatar: (
      <img
        onClick={() => Logout()}
        src={IDangxuat}
        style={{ width: 23, cursor: "pointer" }}
      />
    ),
  },
];

const Logout = () => {
  Service.apicall(
    "GET",
    `/api/v1/user/portal/logout
  `
  )
    .then((res) => {
      if (res.data.statusCode === 200) {
        localStorage.clear();
        History.push("/sign-in");
      }
    })
    .catch((err) => {
      // History.push('/sign-in')
    });
};

const menu = (
  <List
    min-width="100%"
    className="header-notifications-dropdown custom"
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          avatar={item.avatar}
          title={<span style={{ fontWeight: 500 }}>{item.title}</span>}
        />
      </List.Item>
    )}
  />
);

const setting = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // d="M11.4892 3.17094C11.1102 1.60969 8.8898 1.60969 8.51078 3.17094C8.26594 4.17949 7.11045 4.65811 6.22416 4.11809C4.85218 3.28212 3.28212 4.85218 4.11809 6.22416C4.65811 7.11045 4.17949 8.26593 3.17094 8.51078C1.60969 8.8898 1.60969 11.1102 3.17094 11.4892C4.17949 11.7341 4.65811 12.8896 4.11809 13.7758C3.28212 15.1478 4.85218 16.7179 6.22417 15.8819C7.11045 15.3419 8.26594 15.8205 8.51078 16.8291C8.8898 18.3903 11.1102 18.3903 11.4892 16.8291C11.7341 15.8205 12.8896 15.3419 13.7758 15.8819C15.1478 16.7179 16.7179 15.1478 15.8819 13.7758C15.3419 12.8896 15.8205 11.7341 16.8291 11.4892C18.3903 11.1102 18.3903 8.8898 16.8291 8.51078C15.8205 8.26593 15.3419 7.11045 15.8819 6.22416C16.7179 4.85218 15.1478 3.28212 13.7758 4.11809C12.8896 4.65811 11.7341 4.17949 11.4892 3.17094ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
      d="M8 11h3v10h2V11h3l-4-4-4 4zM4 3v2h16V3H4z"
      fill="#111827"
    ></path>
  </svg>,
];

function Header({ onPress }) {
  const { profile, namepage } = useContext(AuthContext);
  useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      {/* <div className="setting-drwer" onClick={() => window.scrollTo(0, 0)}>
        {setting}
      </div> */}
      <Row
        gutter={[60, 0]}
        style={{
          background: "#fff",
          padding: "12px 0px",
          boxShadow: "0px 2px 3px 0px #00000040",
          borderRadius: 2,
          alignItems: "center",
          marginTop: -5,
        }}
      >
        <Col xs={0} lg={6} span={0} md={0} style={{ padding: 0 }}>
          <div style={{ marginLeft: 15 }}>{namepage}</div>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={18}
          className="header-control"
          style={{ justifyContent: "space-between" }}
        >
          <Row>
            <Col xs={0} md={0} lg={24}>
              <Badge size="small">
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a
                    href="#pablo"
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {profile?.avatar ? (
                        <Avatar
                          size={20}
                          style={{
                            backgroundColor: "#87d068",
                            width: 36,
                            height: 36,
                            marginRight: 5,
                          }}
                          // icon={<UserOutlined />}
                          src={profile?.avatar}
                        />
                      ) : (
                        <img src={DefaultUser} style={{ marginRight: 5 }} />
                      )}
                      <div>
                        <div
                          style={{
                            color: "#0B3C8A",
                            fontWeight: 600,
                            textTransform: "uppercase",
                          }}
                        >
                          {profile?.fullname || "Default Admin"}
                        </div>
                        <div style={{ color: "black", marginTop: 5 }}>
                          {profile?.extra_info?.position || "User"}
                        </div>
                      </div>
                      <img src={Iselect} style={{ marginLeft: 5, width: 12 }} />
                    </div>
                  </a>
                </Dropdown>
              </Badge>
            </Col>
            <Col xs={24} md={24} lg={0}>
              {profile?.extra_info?.avatar ? (
                <Avatar
                  size={20}
                  style={{
                    backgroundColor: "#87d068",
                    width: 32,
                    height: 32,
                    marginRight: 10,
                  }}
                  // icon={<UserOutlined />}
                  src={profile?.extra_info?.avatar}
                  onClick={() => History.push("/thong-tin-tai-khoan")}
                />
              ) : (
                <img
                  onClick={() => History.push("/thong-tin-tai-khoan")}
                  src={DefaultUser}
                  style={{ marginRight: 10, width: 32 }}
                />
              )}
            </Col>
          </Row>
          <div>
            <Row>
              <Col xs={24} md={24} lg={0}>
                <img
                  onClick={() => window.location.reload()}
                  src={Logo}
                  style={{ width: 80 }}
                />
              </Col>
            </Row>
          </div>
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            <img src={Toggler} style={{ width: 21 }} />
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Header;
