import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Form,
  Input,
  Select,
  DatePicker,
  message,
  List,
  Dropdown,
} from "antd";
import Service from "./../../extra/getAPI";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../../context/AuthProvider";
import { GlobalState } from "./index";
import Helper from "../../extra/helper";
import ThaotacA from "./../../assets/images/salesManager/thaotacA.png";
import Itimkiem from "./../../assets/images/itimkiem.png";
import Ikichhoat from "./../../assets/images/salesManager/ikichhoat.png";
import Ixoa from "./../../assets/images/salesManager/ixoa.png";
import Itaogoi from "./../../assets/images/salesManager/itaogoi.png";
import MDConfirmActive from "./../../components/modal/khuyen-mai/mdConfirmActive";
import MDConfirmDelete from "./../../components/modal/khuyen-mai/mdConfirmDelete";
import MDConfirmDisable from "./../../components/modal/khuyen-mai/mdConfirmDisable";
import IDothoanthanh from "./../../assets/images/salesManager/dothoanthanh.png";
import INhap from "./../../assets/images/salesManager/dotnhap.png";
import INgung from "./../../assets/images/salesManager/dotngung.png";
import ITuchoi from "./../../assets/images/salesManager/dothuy.png";
import IChinhsua from "./../../assets/images/salesManager/chinh-sua.png";
import Icho from "./../../assets/images/salesManager/dotcho.png";
import Ixem from "./../../assets/images/salesManager/ixem.png";
import Ingunghoatdong from "./../../assets/images/salesManager/ngunghoadong.png";
import Ireject from "./../../assets/images/salesManager/Ireject.png";
import Idowload from "./../../assets/images/salesManager/idowload.png";
import moment from "moment";
import { exportTableToExcel } from "./../../extra/export";
import Export from "./../../components/table-excel/excel-khuyen-mai";


function KhuyenMai() {
  const { provider, checkAccountGC, setIndexPage, roleManagement } =
    useContext(AuthContext);
  const { setPage, productType, setDataUpdate, Back } = useContext(GlobalState);

  const [dataOrders, seDataOrders] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [limit, setLimit] = useState(0);
  const [offset, setOffset] = useState(1);
  const [pageS, setPageS] = useState(0);
  const [listUser, setListUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataAction, setDataAction] = useState({});
  const [formData, setFormData] = useState({});
  const [tabsActive, setTabsActive] = useState("");
  const [openConfirmActive, setOpenConfirmActive] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [disablePacked, setDisablePacked] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const { Option } = Select;
  const datatab = [
    { id: "", title: "Tất cả" },
    { id: 5, title: "Chờ kiểm duyệt" },
    { id: 2, title: "Đang hoạt động" },
    { id: 4, title: "Hết hạn" },
    { id: 3, title: "Từ chối" },
    { id: "0", title: "Ngừng hoạt động" },
    { id: 1, title: "Nháp" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setIndexPage(0);
    Back(1);
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case 2:
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "#069D27" }}
          >
            <img src={IDothoanthanh} width={10} style={{ marginRight: 10 }} />
            <div>Đang hoạt động</div>
          </div>
        );
      case 1:
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "#C0C0C0" }}
          >
            <img src={INhap} width={10} style={{ marginRight: 10 }} />
            <div>Nháp</div>
          </div>
        );
      case 0:
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "#191919" }}
          >
            <img src={INgung} width={10} style={{ marginRight: 10 }} />
            <div>Ngừng hoạt động</div>
          </div>
        );
      case 4:
        return (
          <div style={{ display: "flex", alignItems: "center", color: "red" }}>
            <img src={ITuchoi} width={10} style={{ marginRight: 10 }} />
            <div>Hết hạn</div>
          </div>
        );
      case 5:
        return (
          <div
            style={{ display: "flex", alignItems: "center", color: "#FFA21F" }}
          >
            <img src={Icho} width={10} style={{ marginRight: 10 }} />
            <div>Chờ kiểm duyệt</div>
          </div>
        );
      case 3:
        return (
          <div style={{ display: "flex", alignItems: "center", color: "red" }}>
            <img src={ITuchoi} width={10} style={{ marginRight: 10 }} />
            <div>Từ chối</div>
          </div>
        );
      default:
        break;
    }
  };

  const data = [
    {
      title: (
        <div
          onClick={() => {
            setOpenConfirmActive(true);
            // Wait(dataAction?.uid);
          }}
          style={{ cursor: "pointer" }}
        >
          Hiển thị
        </div>
      ),
      avatar: (
        <img
          onClick={() => {
            setOpenConfirmActive(true);
            // Wait(dataAction?.uid)
          }}
          src={Ikichhoat}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => getDetail(dataAction?.uid, "edit")}
          style={{ cursor: "pointer" }}
        >
          Chỉnh sửa
        </div>
      ),
      avatar: (
        <img
          onClick={() => getDetail(dataAction?.uid, "edit")}
          src={IChinhsua}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => setOpenConfirmDelete(true)}
          style={{ cursor: "pointer" }}
        >
          Xoá
        </div>
      ),
      avatar: (
        <img
          onClick={() => setOpenConfirmDelete(true)}
          src={Ixoa}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => setDisablePacked(true)}
          style={{ cursor: "pointer" }}
        >
          Ngừng hoạt động
        </div>
      ),
      avatar: (
        <img
          onClick={() => setDisablePacked(true)}
          src={Ingunghoatdong}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => getDetail(dataAction?.uid, "view")}
          style={{ cursor: "pointer" }}
        >
          Xem
        </div>
      ),
      avatar: (
        <img
          onClick={() => getDetail(dataAction?.uid, "view")}
          src={Ixem}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  const Action = (
    <List
      min-width="100%"
      className="header-notifications-dropdown custom"
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item, i) => (
        <List.Item
          style={{
            display:
              (dataAction.status === 1 && i === 3) ||
                (dataAction.status === 1 && i === 4) ||
                (dataAction.status === 2 && (i === 0 || i === 1 || i === 2)) ||
                (dataAction.status === 2 && i === 3 && !checkAccountGC) ||
                (dataAction.status === 5 && (i === 0 || i === 1 || i === 2)) ||
                (dataAction.status === 5 && i === 3 && !checkAccountGC) ||
                (dataAction.status === 3 && (i === 2 || i === 3 || i == 4 || i === 0)) ||
                (dataAction.status === 0 &&
                  (i === 0 || i === 1 || i === 2 || i === 3)) ||
                (dataAction.status === 4 &&
                  (i === 0 || i === 1 || i === 2 || i === 3)) ||
                (+roleManagement?.edit?.product !== 1 &&
                  i === 1 &&
                  +roleManagement?.manager?.product !== 1 &&
                  i === 1)
                ? "none"
                : "",
          }}
        >
          <List.Item.Meta
            avatar={item.avatar}
            title={<span style={{ fontWeight: 500 }}>{item.title}</span>}
          />
        </List.Item>
      )}
    />
  );

  const dataActionGC = [
    {
      title: (
        <div
          onClick={() => setDisablePacked(true)}
          style={{ cursor: "pointer" }}
        >
          Ngừng hoạt động
        </div>
      ),
      avatar: (
        <img
          onClick={() => setDisablePacked(true)}
          src={Ingunghoatdong}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => getDetail(dataAction?.uid, "view")}
          style={{ cursor: "pointer" }}
        >
          Xem
        </div>
      ),
      avatar: (
        <img
          onClick={() => getDetail(dataAction?.uid, "view")}
          src={Ixem}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => setOpenConfirmActive(true)}
          style={{ cursor: "pointer" }}
        >
          Kích hoạt
        </div>
      ),
      avatar: (
        <img
          onClick={() => setOpenConfirmActive(true)}
          src={Ikichhoat}
          width={25}
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      title: (
        <div
          onClick={() => getDetail(dataAction?.uid, "view")}
          style={{ cursor: "pointer" }}
        >
          Từ chối
        </div>
      ),
      avatar: (
        <img
          onClick={() => getDetail(dataAction?.uid, "view")}
          src={Ireject}
          width={23}
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ];

  const ActionGC = (
    <List
      min-width="100%"
      className="header-notifications-dropdown custom"
      itemLayout="horizontal"
      dataSource={dataActionGC}
      renderItem={(item, i) => (
        <List.Item
          style={{
            display:
              ((dataAction.status === 0 || dataAction.status === 4) &&
                (i === 0 || i === 2 || i === 3)) ||
                (dataAction.status === 2 && (i === 2 || i === 3)) ||
                (dataAction.status === 1 && (i === 2 || i === 3 || i === 0)) ||
                (dataAction.status === 3 && (i === 0 || i === 2 || i === 3)) ||
                (dataAction.status === 5 && i === 0)
                ? "none"
                : "",
          }}
        >
          <List.Item.Meta
            avatar={item.avatar}
            title={<span style={{ fontWeight: 500 }}>{item.title}</span>}
          />
        </List.Item>
      )}
    />
  );

  const project = [
    {
      title: "STT",
      render: (item, record, index) => (
        <div style={{ width: "30px", textAlign: "left" }}>
          {index + 1 + (offset - 1) * 20}
        </div>
      ),
    },
    {
      title: "Công ty bảo hiểm",
      hidden: checkAccountGC ? false : true,
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {getTitleById(data?.provider_id)}
        </div>
      ),
    },
    {
      title: "Loại sản phẩm",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>
          {data?.product_name}
        </div>
      ),
    },
    {
      title: "Mã giảm giá",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>{data?.code}</div>
      ),
    },
    {
      title: "Tên sản phẩm",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>{data?.pbp_name}</div>
      ),
    },
    {
      title: "Gói sản phẩm",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 100 }}>{data?.package_name}</div>
      ),
    },
    {
      title: "Đối tượng",
      render: (_, data) => (
        <>
          {data?.gender !== 3 && (
            <div style={{ textAlign: "left" }}>
              {data?.gender === 1 ? "Nam" : "Nữ"}/{" "}
              {+data?.under_1_age === 364
                ? "Dưới 1 tuổi - "
                : data?.from_age === 0
                  ? "15 ngày tuổi - "
                  : data?.from_age + " - "}
              {data?.under_1_age_extended === 364 ? 'Dưới 1 tuổi' : data?.under_1_age_extended === 0 ? '15 ngày tuổi' : data?.to_age + ' tuổi'}
            </div>
          )}
          {data?.gender === 3 && (
            <div style={{ textAlign: "left" }}>Tất cả đối tượng</div>
          )}
        </>
      ),
    },
    {
      title: "Giảm giá",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 80 }}>
          {Helper._formatMoney2(data?.value)}
          {data?.type === "tien" ? " VNĐ" : "%"}
        </div>
      ),
    },
    {
      title: "Số lượng",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 80 }}>
          {Helper._formatMoney2(data?.maximum_usage)}
        </div>
      ),
    },
    {
      title: "Đã dùng",
      render: (_, data) => (
        <div style={{ textAlign: "left", minWidth: 80 }}>
          {data?.usage_count}
        </div>
      ),
    },
    {
      title: "Ngày bắt đầu",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {Helper._formatDayNoHour(data?.begin_date)}
        </div>
      ),
    },
    {
      title: "Ngày kết thúc",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {Helper._formatDayNoHour(data?.end_date)}
        </div>
      ),
    },
    {
      title: "Ngày tạo",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>
          {Helper._formatDay(data?.created_date)}
        </div>
      ),
    },
    {
      title: "Trạng thái",
      render: (_, data) => (
        <div style={{ textAlign: "left" }}>{renderStatus(data?.status)}</div>
      ),
    },
    {
      title: "Nội dung",
      render: (_, data) => (
        <div style={{ textAlign: "left", width: 200 }}>
          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            {data?.extra_info?.deny_reason || "-"}
          </div>
        </div>
      ),
    },
    {
      title: "Thao tác",
      fixed: "right",
      align: "center",
      render: (_, data) => (
        <div style={{ width: "50px" }}>
          <Dropdown
            overlay={checkAccountGC ? ActionGC : Action}
            trigger={["click"]}
          >
            <a className="ant-dropdown-link">
              <img
                onClick={() => setDataAction(data)}
                src={ThaotacA}
                style={{ width: 25 }}
              />
            </a>
          </Dropdown>
        </div>
      ),
    },
  ].filter((item) => !item.hidden);

  const Active = (uid) => {
    setLoading(true);
    Service.apicall("PUT", `/api/v1/discount/${uid}/active`)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Kích hoạt thành công!");
          GetList();
        } else {
          message.error("Kích hoạt thất bại!");
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Kích hoạt thất bại!");
      });
  };

  const Wait = (uid) => {
    setLoading(true);
    Service.apicall("PUT", `/api/v1/discount/${uid}/wait`)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Hiển thị thành công!");
          GetList();
        } else {
          message.error("Hiển thị thất bại!");
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Hiển thị thất bại!");
      });
  };

  // const DisablePacked = (uid) => {
  //   setLoading(true);
  //   Service.apicall("PUT", `/api/v1/discount/${uid}/deactivate`)
  //     .then((res) => {
  //       setLoading(false);
  //       if (res.data.statusCode === 200) {
  //         message.success("Ngừng chương trình thành công!");
  //         GetList();
  //       } else {
  //         message.error("Ngừng chương trình thất bại!");
  //       }
  //       handCancel();
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       message.error("Ngừng chương trình thất bại!");
  //     });
  // };

  const DisablePacked = (uid) => {
    setLoading(true);
    Service.apicall("PUT", `/api/v1/discount/${uid}/stop`)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Ngừng chương trình thành công!");
          GetList();
        } else {
          message.error("Ngừng chương trình thất bại!");
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Ngừng chương trình thất bại!");
      });
  };

  const DeletePacked = (uid) => {
    setLoading(true);
    Service.apicall("PUT", `/api/v1/discount/${uid}/delete`)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Xoá chương trình thành công!");
          GetList();
        } else {
          message.error("Xoá chương trình thất bại!");
        }
        handCancel();
      })
      .catch((err) => {
        setLoading(false);
        message.error("Xoá chương trình thất bại!");
      });
  };

  const getDetail = (uid, action) => {
    setLoading(true);
    Service.apicall("GET", `/api/v1/discount/${uid}`)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          setDataUpdate({ ...res.data.result, action: action });
          setPage(3);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getTitleById = (id) => {
    const result = provider.filter((item) => item.id === id);
    return result.length > 0 ? result[0].title : null; // Trả về title nếu tìm thấy, ngược lại trả về null
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  // Debounced tìm kiếm
  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      GetList(formData);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [formData]);

  const handCancel = () => {
    setOpenConfirmActive(false);
    setOpenConfirmDelete(false);
    setDisablePacked(false);
  };

  const css = {
    input: {
      color: "#3A3A3A",
      zIndex: 1,
      fontSize: 12,
      boxShadow: "none",
      padding: "0px 10px",
      borderRadius: 4,
      width: "100%",
      border: "1px solid #d2d2d2",
    },
    titleInput: { color: "#646D69", marginBottom: 3 },
    FormItemsHolder: {
      width: "100%",
      borderRadius: 4,
      margin: "5px 0px",
      padding: 0,
    },
    button: {
      fontSize: 12,
      background: "#007185",
      color: "#fff",
      borderRadius: 4,
      height: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    activeTabs: {
      padding: "15px 24px",
      borderBottom: "3px solid #3961A0",
      width: "fit-content",
      color: "#3961A0",
      fontWeight: 600,
      cursor: "pointer",
    },
    defaultTabs: {
      padding: "15px 24px",
      width: "fit-content",
      cursor: "pointer",
    },
  };

  //xet chiều cao window
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const GetList = () => {
    setLoading(true);
    const data = new URLSearchParams({
      page: formData?.offset || 1,
      limit: 20,
      search: formData?.search || "",
      status: formData?.status || "",
      product_id: formData?.product_id || "",
      provider_id: formData?.provider_id || "",
    });
    Service.apicall("GET", `/api/v1/discount?${data.toString()}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setListUser(res?.data?.result?.data);
          setLimit(res?.data?.result?.count);
          setPageS(res?.data?.result?.count);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetDataExport = (dataInput, limit) => {
    setLoadingExport(true);
    const data = new URLSearchParams({
      page: dataInput?.offset || 1,
      limit: limit,
      // createdFrom: dataInput?.createdFrom
      //   ? moment(dataInput?.createdFrom).startOf("month").format("YYYY-MM-DD")
      //   : "",
      // createdTo: dataInput?.createdTo
      //   ? moment(dataInput?.createdTo).endOf("month").format("YYYY-MM-DD")
      //   : "",
      product_id: dataInput?.product_id || "",
      provider_id: dataInput?.provider_id || "",
      status: dataInput?.status || "",
    });
    Service.apicall(
      "GET",
      `/api/v1/discount?${data.toString()}`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDataExport(res?.data?.result?.data);
          exportFile();
          message.success("File đang được xử lý. Vui lòng chờ trong giây lát!");
        }
        setLoadingExport(false);
      })
      .catch((err) => {
        setLoadingExport(false);
      });
  };

  const exportFile = async () => {
    setTimeout(() => {
      exportTableToExcel("khuyen-mai", `khuyen-mai.xlsx`);
    }, 1000);
  };

  return (
    <>
      <Export data={dataExport} acountGC={checkAccountGC} />
      {openConfirmActive && (
        <MDConfirmActive
          action={() => checkAccountGC ? Active(dataAction?.uid) : Wait(dataAction?.uid)}
          loading={loading}
          open={true}
          handleCancel={handCancel}
        />
      )}
      {disablePacked && (
        <MDConfirmDisable
          action={() => DisablePacked(dataAction?.uid)}
          loading={loading}
          open={true}
          handleCancel={handCancel}
        />
      )}

      {openConfirmDelete && (
        <MDConfirmDelete
          action={() => DeletePacked(dataAction?.uid)}
          loading={loading}
          open={true}
          handleCancel={handCancel}
        />
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: 14,
          marginBottom: 10
        }}
      >
        <div style={{ marginBottom: 10, fontWeight: 600, fontSize: 20 }}>
          Khuyến mãi
        </div>
        {(+roleManagement?.create?.discount === 1 ||
          +roleManagement?.manager?.discount === 1) && (
            <div
              onClick={() => setPage(2)}
              style={{
                padding: "7px 11px",
                background: "#0B7CE4",
                width: "fit-content",
                color: "#fff",
                borderRadius: 5,
                display: checkAccountGC ? "none" : "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img src={Itaogoi} style={{ width: 18, marginRight: 11 }} />
              Tạo khuyến mãi
            </div>
          )}
      </div>
      <div>
        <Row gutter={[10, 0]}>
          <Col xl={5} md={12} xs={24}>
            <Form.Item
              name="product_id"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
              style={{ ...css.FormItemsHolder, marginTop: 6 }}
            >
              <Select
                allowClear={true}
                className="custom-select"
                placeholder={<span style={{ color: "#343A40" }}>Sản phẩm</span>}
                onChange={(e) => {
                  onChangForm({ data: e, name: "product_id" });
                }}
              >
                {productType.map((item) => (
                  <Option key={item?.id} value={item?.product_id}>
                    {item?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {checkAccountGC && (
            <Col xl={5} md={12} xs={24}>
              <Form.Item
                name="provider_id"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
                style={{ ...css.FormItemsHolder, marginTop: 6 }}
              >
                <Select
                  allowClear={true}
                  className="custom-select"
                  placeholder={
                    <span style={{ color: "#343A40" }}>Công ty bảo hiểm</span>
                  }
                  onChange={(e) => {
                    onChangForm({ data: e, name: "provider_id" });
                  }}
                >
                  {provider.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col xl={checkAccountGC ? 14 : 19} md={12} xs={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Vui lòng nhập đầy đủ thông tin.",
                },
              ]}
              style={{ ...css.FormItemsHolder, maxWidth: 375, marginRight: 10 }}
            >
              <Input
                allowClear
                onChange={(e) =>
                  onChangForm({ data: e.target.value, name: "search" })
                }
                className="custom-input"
                prefix={
                  <img
                    src={Itimkiem}
                    alt="Global Care icon"
                    width={14}
                    height={14}
                  />
                }
                style={{
                  ...css.input,
                  maxWidth: 375,
                  fontSize: 12,
                }}
                placeholder="Tìm kiếm mã giảm..."
              />
            </Form.Item>
            <Button
              onClick={() => GetDataExport(formData, limit)}
              disabled={loading || loadingExport}
              loading={loadingExport}
              style={{
                padding: "0px 20px",
                borderRadius: 5,
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                fontWeight: 500,
                height: 35,
              }}
            >
              <img
                src={Idowload}
                style={{
                  width: 13,
                  marginRight: 5,
                  display: loadingExport ? "none" : "",
                }}
              />
              Xuất file
            </Button>
          </Col>
          {/* {(+roleManagement?.report?.order === 1 ||
            +roleManagement?.manager?.order === 1) && (
              <Col xl={4} xs={0}>
                <div
                  style={{ justifyContent: "flex-end", display: "flex" }}
                >
                  <Button
                    onClick={() => GetDataExport(formData, limit)}
                    disabled={loading || loadingExport}
                    loading={loadingExport}
                    style={{
                      padding: "0px 20px",
                      borderRadius: 5,
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                      height: 32,
                    }}
                  >
                    <img
                      src={Idowload}
                      style={{
                        width: 13,
                        marginRight: 5,
                        display: loadingExport ? "none" : "",
                      }}
                    />
                    Xuất file
                  </Button>
                </div>
              </Col>
            )} */}
        </Row>
      </div>
      <div style={{ background: "#fff", marginTop: 10 }}>
        <Row gutter={[10, 0]}>
          <Col xs={24}>
            <div
              style={{
                borderBottom: "1px solid #DCDCDC",
                display: "flex",
                alignItems: "center",
              }}
            >
              {datatab.map((item) => (
                <div
                  onClick={() => {
                    setTabsActive(item.id);
                    onChangForm({ data: item.id, name: "status" });
                  }}
                  key={item.id}
                  style={
                    tabsActive === item.id ? css.activeTabs : css.defaultTabs
                  }
                >
                  {item.title}
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <div className="tabled2ß">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Table
                loading={loading}
                columns={project}
                dataSource={listUser}
                pagination={false}
                className="ant-border-space khuyenmai scroll-content"
                bordered={false}
                scroll={
                  listUser?.length > 0 ?
                    {
                      y: windowHeight > 550 ? "calc(100vh - 366px)" : "51vh",
                      x: 'auto'
                    } :
                    {
                      x: 'auto'
                    }
                }
                showHeader={true}
              />
              <div style={{ textAlign: "right", margin: "10px" }}>
                <Pagination
                  className="paginateCustom2"
                  total={pageS || 20}
                  pageSize={20}
                  showTotal={(total, range) => (
                    <span
                      style={{ color: "#3961A0" }}
                    >{`Hiển thị ${range[0]}-${range[1]} trong ${total}`}</span>
                  )}
                  defaultCurrent={1}
                  defaultPageSize={0}
                  // className="paginateCustom"
                  onChange={(e) => {
                    onChangForm({ data: e, name: "offset" });
                  }}
                  size="small"
                // scroll={{
                //   y: 240,
                // }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default KhuyenMai;
