import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Input, message } from "antd";
import Service from "./../../extra/getAPI";
import "antd/dist/antd.css";
import History from "./../../routes/history";

export default function Doimatkhau() {
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        if (values.matkhaumoi !== values.nhaplaimk) {
          message.error("Mật khẩu mới chưa khớp. Vui lòng nhập lại!");
          return;
        }
        ChanPass(values);
      })
      .catch((err) => {
 
      });
  };

  const ChanPass = (values) => {
    setLoading(true);
    const data = {
      oldPassword: values.matkhaucu,
      newPassword:values.matkhaumoi,
    };
    Service.apicall("PUT", `/api/v1/user/portal/change-password`, data)
      .then((res) => {
        // console.log(res);
        if (res.data.statusCode === 200) {
          setLoading(false);
          message.success("Đổi mật khẩu thành công!");
          SignOut();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const SignOut = () => {
    localStorage.clear();
    History.push("/sign-in");
  };


  return (
    <>
      <div
        style={{
          marginBottom: 20,
          fontSize: 20,
          fontWeight: 600,
          color: "#191919",
        }}
      >
        Thay đổi mật khẩu
      </div>
      <Row
        className="FormThongtin"
        style={{ overflow: "hidden", maxWidth: 900 }}
      >
        <Form
          style={{ width: "100%" }}
          ref={formRef}
          form={form}
          layout={"vertical"}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 2,
              color: "#4B4B4B",
            }}
          >
            <Row gutter={[60, 0]}>
              <Col xl={24}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Thông tin cơ bản
                </div>
              </Col>

              <Col xl={24} xs={24}>
                <Row gutter={[10]}>
                  <Col xs={24} xl={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Mật khẩu cũ</span>
                      }
                      name="matkhaucu"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input.Password
                        style={{ padding: "0px 10px", borderRadius: 5 }}
                        className="customPass"
                        placeholder="Nhập mật khẩu cũ"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Mật khẩu mới</span>
                      }
                      name="matkhaumoi"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input.Password
                        style={{ padding: "0px 10px", borderRadius: 5 }}
                        className="customPass"
                        placeholder="Nhập mật khẩu mới"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Nhập lại mật khẩu mới
                        </span>
                      }
                      name="nhaplaimk"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input.Password
                        style={{ padding: "0px 10px", borderRadius: 5 }}
                        className="customPass"
                        placeholder="Nhập lại mật khẩu mới"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <Row>
              <Col xs={2} xl={0}></Col>
              <Col xs={20} xl={24} style={{ width: "90%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: 20,
                    width: "100%",
                  }}
                >
                  <Button
                    loading={loading}
                    htmlType="submit"
                    style={{
                      background: "#0B7CE4",
                      color: "#ffffff",
                      textAlign: "center",
                      borderRadius: 6,
                      border: "1px solid #0B7CE4",
                      fontWeight: 500,
                      height: 40,
                    }}
                  >
                    <b>Cập nhật</b>
                  </Button>
                </div>
              </Col>
              <Col xs={2} xl={0}>
                {" "}
              </Col>
            </Row>
          </div>
        </Form>
      </Row>
    </>
  );
}
